import './style.css';

import React, { Component, Fragment } from 'react';
import { EnumStatusAsset } from '../../models/Asset';
import { AccessTime, Loop, StarBorder, Check, Flag } from '@material-ui/icons';

export default class StatusAsset extends Component {
  render() {
    const { status } = this.props;

    const isStatusInQueue = status === EnumStatusAsset.IN_QUEUE;
    const isStatusProcessing = status === EnumStatusAsset.PROCESSING;
    const isStatusReview = status === EnumStatusAsset.REVIEW;
    const isStatusApproved = status === EnumStatusAsset.APPROVED;
    const isStatusFlagged = status === EnumStatusAsset.FLAGGED;

    const color = isStatusInQueue ?
      '#EFC100' : isStatusProcessing ?
      '#F2994A' : isStatusReview ?
      '#2F80ED' : isStatusApproved ?
      '#219653' : isStatusFlagged ?
      '#E0182D' : undefined;

    return (
      <div className='StatusAsset'
           style={{
             color
           }}>
        { isStatusInQueue ?
          <Fragment>
            <AccessTime fontSize='small' />
            In Queue
          </Fragment>
        : null }
        { isStatusProcessing ?
          <Fragment>
            <Loop fontSize='small' />
            Processing
          </Fragment>
        : null }
        { isStatusReview ?
          <Fragment>
            <StarBorder fontSize='small' />
            Please Review
          </Fragment>
        : null }
        { isStatusApproved ?
          <Fragment>
            <Check fontSize='small' />
            Approved
          </Fragment>
        : null }
        { isStatusFlagged ?
          <Fragment>
            <Flag fontSize='small' />
            Flagged
          </Fragment>
        : null }
      </div>
    );
  }
}


export const StatusAssetInfo = {
  [EnumStatusAsset.IN_QUEUE]: {
    text: 'In Queue',
    icon: 'clock outline',
    color: 'grey'
  },
  [EnumStatusAsset.PROCESSING]: {
    text: 'Processing',
    icon: 'magic',
    color: 'grey'
  },
  [EnumStatusAsset.FLAGGED]: {
    text: 'Flagged',
    icon: 'info circle',
    color: 'red',
    bold: true
  },
  [EnumStatusAsset.REVIEW]: {
    text: 'Please Review',
    icon: 'eye',
    color: 'orange',
    bold: true
  },
  [EnumStatusAsset.APPROVED]: {
    text: 'Approved',
    icon: 'check',
    color: 'green',
    bold: true
  }
};