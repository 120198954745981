import './sortable-header.css';

import React, { Component, Fragment } from 'react';
import { Icon } from 'semantic-ui-react';

export default class ListSortableHeader extends Component {
  onChangeOrder = () => {
    this.props.onChangeOrder(this.props.fieldName);
  }

  render() {
    const { text, orderBy, fieldName } = this.props;
    return (
      <Fragment>
        <div  className="ListSortableHeader"
              onClick={this.onChangeOrder}>
          <span className={'ListSortableHeader__Text' +
                            ((fieldName === orderBy.fieldName)
                              ? ' ListSortableHeader__Text--bold'
                              : '')}>{text}</span>
          <Icon name={(orderBy.order === 'asc' && (fieldName === orderBy.fieldName)) ? 'caret down' : 'caret up'}
                disabled={fieldName !== orderBy.fieldName}/>
        </div>
      </Fragment>
    );
  }
}
