import clipboardCopy from 'clipboard-copy';
import gql from 'graphql-tag';
import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { generateFileUrl } from '../../models/Asset';
import ModalAssetSynchronizeToYoutube from '../../components/ModalAssetSynchronizeToYoutube';

class AssetSynchronizeToFacebookContainer extends Component {
  state = {};

  async componentDidMount() {
    const { asset } = this.props;
    const { company, name, type, title, description } = asset;

    clipboardCopy(description);

    this.setState({
      title,
      description: '',
      videoUrl: generateFileUrl({
        company,
        name,
        type: type.value
      })
    });
  }

  onPublishFacebook = () => {
    this.setState({
      isLoadingPublishFacebook: true
    });

    const { asset } = this.props;
    const { company, name } = asset;
    const { title, description, token, id } = this.state;

    this.props.publishFacebookAssetMutation({
      variables: {
        name,
        company,
        title,
        description,
        token,
        id
      }
    })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.code && graphQLErrors[0].extensions.code === 1001) {
          this.setState({
            isLoadingPublishFacebook: false
          });
        }
      })
      .then((resp) => {
        this.props.onClose(true);
      })
  }

  onCallbackFacebookLogin = (resp = {}) => {
    const { accessToken, id } = resp;

    this.setState({ token: accessToken, id }, () => this.onPublishFacebook());
    // this.props.updateGoogleAccount({
    //   variables: {
    //     name: localStorage.getItem('company'),
    //     account: JSON.stringify(resp)
    //   }
    // }).then((resp) => {
    //   this.onPublishFacebook();
    // });
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value
    });
  }

  render() {
    const { open, onClose } = this.props;
    const { isLoadingPublishFacebook, title, description, isLoadingDescription, videoUrl } = this.state;

    return (
      <ModalAssetSynchronizeToYoutube open={open}
                                      onClose={onClose}
                                      onCallbackFacebookLogin={this.onCallbackFacebookLogin}
                                      isLoadingPublishFacebook={isLoadingPublishFacebook}
                                      onChange={this.handleChange}
                                      title={title}
                                      description={description}
                                      videoUrl={videoUrl} />
      );
  }
}

const PublishFacebookAssetMutation = gql`
  mutation publishFacebookAsset($name: String!, $company: String!, $title: String!, $description: String!, $token: String!, $id: String!){
    publishFacebookAsset(name: $name, company: $company, title: $title, description: $description, token: $token, id: $id) {
      name
      title
      company
      preview
      description
      thumbnail
      status
      type
      filePath
      location
      jsonLocation
      assets
      youtubeId
      products {
        url
      }
      createdAt
    }
  }
`;


const assetGqls = compose(
  graphql(PublishFacebookAssetMutation, {
    name: 'publishFacebookAssetMutation'
  })
)

export default assetGqls(AssetSynchronizeToFacebookContainer);