import gql from 'graphql-tag';
import React, { Component, Fragment } from 'react';
import { compose, graphql } from 'react-apollo';
import _debounce from 'lodash/debounce';
import _get from 'lodash/get';

import ProductList from '../../components/ProductList';
import ProductsImport from '../../components/ProductsImport';

class ProductListContainer extends Component {
  state = {
    open: false,
    file: null,
    orderBy: {
      fieldName: 'title',
      order: 'asc'
    },
  }

  componentDidMount() {
    this.props.setActiveModuleTitle('Products');
  }

  componentWillReceiveProps(props) {
    if (!props.productsQuery.loading) {
      const isDifferent = JSON.stringify(this.props.productsQuery.products) !== JSON.stringify(props.productsQuery.products);

      if (isDifferent) {
        this.setState({
          products: props.productsQuery.products
        });
      }
    }
  }

  onChangeOrder = (fieldName) => {
    let orderBy = Object.assign({}, this.state.orderBy);
    if (this.state.orderBy.fieldName !== fieldName) {
      orderBy = {
        fieldName,
        order: 'asc'
      };
    } else {
      orderBy.order = orderBy.order === 'asc' ? 'desc' : 'asc';
    }

    this.state.products
      .sort((a, b) => {
        if (a[orderBy.fieldName] > b[orderBy.fieldName]) {
          return orderBy.order === 'asc' ? 1 : -1;
        } else if (_get(a, fieldName) < _get(b, fieldName)) {
          return orderBy.order === 'asc' ? -1 : 1;
        }

        return 0;
      });

    this.setState({ orderBy });
  }

  handleSearchProduct = _debounce((event, data) => {
    const { productsQuery } = this.props;

    productsQuery.refetch({
      query: data.value
    });
  }, 300)

  importProducts = () => {
    this.setState({
      open: true
    });
  }

  onClose = () => {
    this.setState({
      open: false
    });
  }

  onSave = () => {
    const { productsCSVMutation, productsQuery } = this.props;
    const { file } = this.state;
    
    productsCSVMutation({
      variables: {
        company: localStorage.getItem('company'),
        file
      }
    })
    .then(() => {
      this.setState({
        open: false,
        file: null
      });
      productsQuery.refetch();
    })
  }
  
  onChangeFile = (file) => {
    this.setState({ file });
  }

  render() {
    const { open, file, orderBy, products } = this.state;

    return (
      <Fragment>
        <ProductsImport
          open={open}
          file={file}
          onSave={this.onSave}
          onChangeFile={this.onChangeFile}
          onClose={this.onClose}
          loadingProgress={undefined}
          title={'Import products'}
        />
        <ProductList
          products={products}
          onClick={this.importProducts}
          onChangeSearch={this.handleSearchProduct}
          orderBy={orderBy}
          onChangeOrder={this.onChangeOrder}
        />
      </Fragment>
    );
  }
}

const ProductsQuery = gql`
  query products($company: String!, $query: String){
    products(company: $company, query: $query)  {
      id
      title
      productId
      description
      images
      price
      sku
    }
  }
`;

const UploadCSVQuery = gql`
  mutation productsCSV($company: String, $file: Upload!) {
    productsCSV(
      company: $company
      file: $file
    )
  }
`;

const assetGqls = compose(
  graphql(UploadCSVQuery, {
    name: 'productsCSVMutation'
  }),
  graphql(ProductsQuery, {
    name: 'productsQuery',
    options: () => {
      return ({
        variables: {
          company: localStorage.getItem('company')
        },
        fetchPolicy: 'network-only'
      });
    }
  })
)

export default assetGqls(ProductListContainer);