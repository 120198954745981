// import ApolloClient from "apollo-boost";

// const client = new ApolloClient({
//   uri: `${process.env.REACT_APP_API_URL}/graphql`,
//   request: (operation) => {
//     const token = localStorage.getItem('token');

//     operation.setContext({
//       headers: {
//         authorization: token ? `Bearer ${token}` : "",
//       }
//     });
//   }
// });



// const request = async (operation) => {
//   const token = localStorage.getItem('token');

//   operation.setContext({
//     headers: {
//       authorization: token ? `Bearer ${token}` : "",
//     }
//   });
// };

// export default client;








import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { from, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';

import CompanyService from './company';

const httpLink = createUploadLink({ uri: `${process.env.REACT_APP_API_URL}/graphql` });
const wsLink = new WebSocketLink({
  uri: `${process.env.REACT_APP_WS_URL}`,
  options: {
    reconnect: true
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    let makeLogout = false;

    graphQLErrors.map(({ extensions, message, locations, path }) => {
      if (message === 'invalid token' || message === `You can't manage assets to this company.`) {
        makeLogout = true;

      } else if (extensions && extensions.code === 'UNAUTHENTICATED') {
        makeLogout = true;
      }

      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    });

    if (makeLogout) {
      localStorage.clear();
      CompanyService.clear();
    }
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  } 
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  from([authLink, onErrorLink, httpLink])
);

export default new ApolloClient({
  link,
  cache: new InMemoryCache()
});