import './style.css';

import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import { Card, Button, Input, Checkbox, Popup, Image, Icon as SemanticIcon, Dropdown } from 'semantic-ui-react';
import { Add, Code, DeleteOutline, Videocam, Panorama, Collections, PlaylistPlay, Tv } from '@material-ui/icons';
import StatusAsset from '../StatusAsset';
import TypeAsset from '../TypeAsset';
import Moment from 'react-moment';
import { EnumAssetType, EnumStatusAsset } from '../../models/Asset';
import { mdiLinkVariant, mdiShareVariant,  mdiYoutube, mdiChartBar } from '@mdi/js'
import Icon from '@mdi/react'
import AssetCreate, { EnumAssetCreateFrom } from '../../containers/AssetCreate';
import ModalConfirm from '../ModalConfirm';
import ListSortableHeader from '../List/sortable-header';
import ModalCreateSlideshowPlaylist from '../ModalCreateSlideshowPlaylist';
import { ASSETLIST_TABS } from '../../containers/AssetList';

export default class AssetList extends Component {
  state = {}

  onLoadAssetGroupThumbnail = () => {
    this.setState({
      isImageGroupThumbnailLoaded: true
    });
  }

  handleOpenCreateAsset = ({ type, from, assets }) => {
    this.setState({
      isCreateAssetOpened: true,
      createType: type,
      createFrom: from,
      assetsToGroup: assets
    });
  }

  handleCloseCreateAsset = (options) => {
    this.setState({
      isCreateAssetOpened: false
    });

    if (options && options.savedPlaylistOrSlideshow) {
      this.props.afterCreatePlaylistOrSlideshow();
    }
  }

  onClickOpenConfirmDelete = () => {
    this.setState({ isDeleteConfirmOpened: true });
  }

  onClickCloseConfirmDelete = () => {
    this.setState({ isDeleteConfirmOpened: false });
  }

  deleteCheckedAssets = () => {
    this.props.deleteCheckedAssets();
    this.setState({ isDeleteConfirmOpened: false });
  }

  handleOpenCreateSlideshowPlaylist = ({ type }) => {
    this.setState({
      isCreateSlideshowPlaylist: true,
      createSlideshowPlaylistType: type
    });
  }

  handleCloseCreateSlideshowPlaylist = () => {
    this.setState({
      isCreateSlideshowPlaylist: false,
      createSlideshowPlaylistType: undefined
    });
  }

  render () {

    const { assets, redirectTo, onClickDetails, onClickAnalytics, onCheckAllChecked, onSelectAsset, onClickGetLink, isLinkCopied, onClickGetEmbedCode, onChangeSearch, orderBy, onChangeOrder, activeTab, onClickSetActiveTab } = this.props;
    const { isImageGroupThumbnailLoaded, isCreateAssetOpened, createType, createFrom, isDeleteConfirmOpened, isCreateSlideshowPlaylist, createSlideshowPlaylistType, assetsToGroup } = this.state;

    let hasSelectedItem = false;
    let hasUnselectedItem = false;

    if (redirectTo) {
      return <Redirect to={{pathname: redirectTo}}
                       push={true} />
    }


    const deleteModalConfirm = (
      <ModalConfirm text='Are you sure you want to delete these assets? This action can not be undone.'
                    title='Delete assets'
                    yesText='Yes, delete the assets'
                    noText={`No, keep the assets`}
                    onClickNo={this.onClickCloseConfirmDelete}
                    onClickYes={this.deleteCheckedAssets}
                    open={isDeleteConfirmOpened}/>
    );

    const assetList = assets.map(asset => {
      if (!hasSelectedItem && asset.checked) {
        hasSelectedItem = true;
      }

      if (!hasUnselectedItem && !asset.checked) {
        hasUnselectedItem = true;
      }

      const isVideo = asset.type && (asset.type.value === EnumAssetType.VIDEO || asset.type.value === EnumAssetType.YOUTUBE_VIDEO);
      const isPlaylist = asset.type && (asset.type.value === EnumAssetType.PLAYLIST);
      const isSlideshow = asset.type && (asset.type.value === EnumAssetType.SLIDESHOW);
      const isImage = asset.type && (asset.type.value === EnumAssetType.IMAGE || asset.type.value === EnumAssetType.IMAGE_PNG);
      const isApproved = asset.status && (asset.status.value === EnumStatusAsset.APPROVED)

      return (
        <Fragment key={`${asset.company}${asset.name}`}>
          <div className='AssetList__Content__Asset'>
            <div className='AssetList__Header__Column AssetList__Header__ColumnChecked'>
              <Checkbox label=''
                        checked={asset.checked}
                        onChange={onSelectAsset(asset)}/>
            </div>
            <div className='AssetList__Header__Column AssetList__Header__ColumnThumbnail overline-bold'>
              <div  className={`AssetList__Content__Asset__ColumnThumbnail
                              ${ (isPlaylist || isSlideshow) && asset.thumbnail ? ' AssetList__Content__Asset__ColumnThumbnail--group' : ''}`}
                    onClick={(isPlaylist || isSlideshow) ?
                                undefined :
                                undefined}>
                                {/* this.onClickOpenAssetFileViewer(asset)}> */}
                { asset.thumbnail && (isPlaylist || isSlideshow)
                  ? <Popup trigger={
                      <div className='AssetList__Content__Asset__ColumnThumbnail--group__ThumbnailWrapper'>
                        <Image src={asset.thumbnail} />
                        <Image  src={asset.thumbnail}
                                onLoad={this.onLoadAssetGroupThumbnail} />
                      </div>
                      }
                          content={isPlaylist ? 'Playlist' : 'Slideshow'}
                          inverted={true}
                          position='top right' />
                  : (asset.thumbnail || ( (asset.filePath || asset.location) && asset.type && (asset.type.value === EnumAssetType.IMAGE || asset.type.value === EnumAssetType.IMAGE_PNG)))
                    ? <Image  src={asset.thumbnail || asset.filePath || asset.location} />
                      : null }
              </div>
            </div>
            <div className='AssetList__Header__Column AssetList__Header__ColumnName body'>
              { asset.title }
            </div>
            <div className='AssetList__Header__Column AssetList__Header__ColumnType caption'>
              <TypeAsset type={asset.type ? asset.type.value : undefined} />
            </div>
            <div className='AssetList__Header__Column AssetList__Header__ColumnCreatedAt caption'>
              <Moment format="MMM DD, YYYY hh:mma">{ asset.createdAt }</Moment>
            </div>
            <div className='AssetList__Header__Column AssetList__Header__ColumnStatus'>
              <StatusAsset status={asset.status ? asset.status.value : undefined} />
            </div>
            <div className='AssetList__Header__Column AssetList__Header__ColumnActions overline-bold'>
              <Button onClick={onClickDetails(asset)}
                      className='AssetList__Header__ColumnActions__Detail' >
                <div className='body-bold'>Details</div>
              </Button>
              { isApproved ?
                <div className='AssetList__Header__ColumnActions__SubActions'>
                  <Dropdown simple trigger={
                                      <Button className='AssetList__Header__ColumnActions__LinkDropdown__Embed'>
                                        <Icon path={mdiLinkVariant}
                                              size={1}
                                              className='AssetList__Header__ColumnActions__LinkDropdown__Embed__Icon cgrey2' />
                                      </Button>
                                    }
                            className='AssetListWrapper__Header__LinkDropdown'>
                    <Dropdown.Menu className='AssetListWrapper__Header__LinkDropdown__Menu'>
                      <Dropdown.Item onClick={( e ) => onClickGetLink(e, asset)}>
                        <div className='caption cgrey ' >
                          <Icon path={mdiLinkVariant}
                          size={1}
                          className='AssetList__Header__ColumnActions__LinkDropdown__Icon cgrey' />
                          Get Link
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      { isVideo || isImage || isPlaylist ?
                        <Dropdown.Item onClick={( e ) => onClickGetEmbedCode( e, asset)}>
                          <div className=' caption cgrey' >
                            <Code fontSize='small' className='AssetList__Header__ColumnActions__LinkDropdown__Icon cgrey' />
                            Embed
                          </div>
                        </Dropdown.Item>
                        :
                        null
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button onClick={onClickAnalytics(asset)}
                          className='AssetList__Header__ColumnActions__Analytics' >
                    <Icon path={mdiChartBar}
                          size={1}
                          className='AssetList__Header__ColumnActions__Analytics__Icon cgrey2' />
                  </Button>
                </div>
                :
                null
              }
            </div>
          </div>
          <div className='HorizontalDivider' />
        </Fragment>
      );
    });

    const checkedAssets = assets.filter((asset) => asset.checked);

    const checkedImageAssets = checkedAssets.filter((asset) => asset.type && (asset.type.value === EnumAssetType.IMAGE || asset.type.value === EnumAssetType.IMAGE_PNG));
    const checkedApprovedImageAssets = checkedImageAssets.filter((asset) => asset.status && asset.status.value === EnumStatusAsset.APPROVED);
    const checkedNonApprovedImageAssets = checkedImageAssets.filter((asset) => asset.status && asset.status.value !== EnumStatusAsset.APPROVED);
    const hasApprovedCheckedImage = checkedApprovedImageAssets.length === 1;
    const hasApprovedCheckedImages = checkedApprovedImageAssets.length > 1;
    const hasNonApprovedImage = checkedNonApprovedImageAssets.length >= 1;

    const checkedVideoAssets = checkedAssets.filter((asset) => asset.type && asset.type.value === EnumAssetType.VIDEO);
    const checkedApprovedVideoAssets = checkedVideoAssets.filter((asset) => asset.status && asset.status.value === EnumStatusAsset.APPROVED);
    const checkedNonApprovedVideoAssets = checkedVideoAssets.filter((asset) => asset.status && asset.status.value !== EnumStatusAsset.APPROVED);
    const hasApprovedSelectedVideo = checkedApprovedVideoAssets.length === 1;
    const hasApprovedSelectedVideos = checkedApprovedVideoAssets.length > 1;
    const hasNonApprovedVideo = checkedNonApprovedVideoAssets.length >= 1;

    const canGroupSelectedsAsSlideshow = !hasNonApprovedImage && hasApprovedCheckedImages && !hasApprovedSelectedVideo;
    const canGroupSelectedsAsPlaylist = !hasNonApprovedVideo && hasApprovedSelectedVideos && !hasApprovedCheckedImage;

    return (
      <div className='AssetListPage'>
        { isCreateAssetOpened ?
          <AssetCreate  onClose={this.handleCloseCreateAsset}
                        open={isCreateAssetOpened}
                        type={createType}
                        from={createFrom}
                        assets={assetsToGroup} />
        : null }
        { deleteModalConfirm }
        { isCreateSlideshowPlaylist ?
          <ModalCreateSlideshowPlaylist onClose={this.handleCloseCreateSlideshowPlaylist}
                                        open={isCreateSlideshowPlaylist}
                                        type={createSlideshowPlaylistType} />
        : null }
        <div className='cred heading-3'>
          Assets
        </div>
        <Card>
          <Card.Content>
            <div className='AssetListWrapper__Header'>

              <div className='AssetListWrapper__Header__CreateAssetWrapper'>
                {canGroupSelectedsAsSlideshow || canGroupSelectedsAsPlaylist ? (
                  <Button color='red'
                          className='IconButton'
                          disabled={true}>
                    <Add fontSize='small' />
                    <div className='body-bold'>Create Asset</div>
                  </Button>
                ) : (
                <Dropdown simple trigger={
                                    <Button color='red'
                                            className='IconButton'>
                                      <Add fontSize='small' />
                                      <div className='body-bold'>Create Asset</div>
                                    </Button>
                                  }
                          className='AssetListWrapper__Header__CreateAssetDropdown' >
                  <Dropdown.Menu className='AssetListWrapper__Header__CreateAssetDropdown__Menu'>
                    <Dropdown.Item>
                      <SemanticIcon name='dropdown' />
                      <span className='caption cgrey text'>
                        <Videocam fontSize='small'
                                  className='AssetListWrapper__Header__CreateAssetDropdown__Menu__Icon cgrey2' />
                        Video
                      </span>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => this.handleOpenCreateAsset({ type: EnumAssetType.VIDEO, from: EnumAssetCreateFrom.FILE }) }>
                          <span className='caption cgrey'>
                            <Tv fontSize='small'
                                className='AssetListWrapper__Header__CreateAssetDropdown__Menu__Icon cgrey2' />
                            Select from File
                          </span>
                        </Dropdown.Item>
                        {/* <Dropdown.Item onClick={() => this.handleOpenCreateAsset({ type: EnumAssetType.VIDEO, from: EnumAssetCreateFrom.YOUTUBE }) }>
                          <span className='caption cgrey'>
                            <Icon path={mdiYoutube}
                                  size={1}
                                  className='AssetListWrapper__Header__CreateAssetDropdown__Menu__Icon cgrey2' />
                            Import from Youtube
                          </span>
                        </Dropdown.Item> */}
                        <Dropdown.Item onClick={() => this.handleOpenCreateAsset({ type: EnumAssetType.VIDEO, from: EnumAssetCreateFrom.URL }) }>
                          <span className='caption cgrey'>
                            <Icon path={mdiLinkVariant}
                                  size={1}
                                  className='AssetListWrapper__Header__CreateAssetDropdown__Menu__Icon cgrey2' />
                            Import from URL
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <SemanticIcon name='dropdown' />
                      <span className='caption cgrey'>
                        <Panorama fontSize='small'
                                  className='AssetListWrapper__Header__CreateAssetDropdown__Menu__Icon cgrey2' />
                        Image
                      </span>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => this.handleOpenCreateAsset({ type: EnumAssetType.IMAGE, from: EnumAssetCreateFrom.FILE }) }>
                          <span className='caption cgrey'>
                            <Tv fontSize='small'
                                className='AssetListWrapper__Header__CreateAssetDropdown__Menu__Icon cgrey2' />
                            Select from File
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => this.handleOpenCreateAsset({ type: EnumAssetType.IMAGE, from: EnumAssetCreateFrom.URL }) }>
                          <span className='caption cgrey'>
                            <Icon path={mdiLinkVariant}
                                  size={1}
                                  className='AssetListWrapper__Header__CreateAssetDropdown__Menu__Icon cgrey2' />
                            Import from URL
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => this.handleOpenCreateSlideshowPlaylist({ type: EnumAssetType.PLAYLIST })}>
                      <span className='caption cgrey'>
                        <PlaylistPlay fontSize='small'
                                      className='AssetListWrapper__Header__CreateAssetDropdown__Menu__Icon cgrey2' />
                        Playlist
                      </span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.handleOpenCreateSlideshowPlaylist({ type: EnumAssetType.SLIDESHOW })}>
                      <span className='caption cgrey'>
                        <Collections fontSize='small'
                                      className='AssetListWrapper__Header__CreateAssetDropdown__Menu__Icon cgrey2' />
                        Slideshow
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                ) }
                { canGroupSelectedsAsSlideshow && !canGroupSelectedsAsPlaylist ?
                  <Button color='red'
                          className='IconButton'
                          onClick={() => this.handleOpenCreateAsset({ type: EnumAssetType.SLIDESHOW, assets: checkedApprovedImageAssets })}>
                    <Add fontSize='small' />
                    <div className='body-bold'>Create Slideshow</div>
                  </Button>
                : null }
                { canGroupSelectedsAsPlaylist && !canGroupSelectedsAsSlideshow ?
                  <Button color='red'
                          className='IconButton'
                          onClick={() => this.handleOpenCreateAsset({ type: EnumAssetType.PLAYLIST, assets: checkedApprovedVideoAssets })}>
                    <Add fontSize='small' />
                    <div className='body-bold'>Create Playlist</div>
                  </Button>
                : null }
              </div>
              <div className='AssetListWrapper__Header__RightActions'>
                <Popup  trigger={<Button  disabled={!hasSelectedItem}
                                          onClick={this.onClickOpenConfirmDelete} >
                                  <DeleteOutline fontSize='small' />
                                 </Button> }
                content='Delete'
                hideOnScroll/>
                <Input  placeholder='Search'
                        name='search'
                        icon='search'
                        onChange={onChangeSearch} />
              </div>
            </div>
            <div className='AssetListWrapper__Tabs'>
              <div  className={`AssetListWrapper__Tabs__Tab heading-4
                              ${activeTab === ASSETLIST_TABS.ALL ? 'AssetListWrapper__Tabs__Tab--active' : ''}`}
                    onClick={() => onClickSetActiveTab(ASSETLIST_TABS.ALL)}>
                All
              </div>
              <div className={`AssetListWrapper__Tabs__Tab heading-4
                              ${activeTab === ASSETLIST_TABS.PLAYLIST ? 'AssetListWrapper__Tabs__Tab--active' : ''}`}
                              onClick={() => onClickSetActiveTab(ASSETLIST_TABS.PLAYLIST)}>
                Playlists
              </div>
              <div className={`AssetListWrapper__Tabs__Tab heading-4
                              ${activeTab === ASSETLIST_TABS.SLIDESHOW ? 'AssetListWrapper__Tabs__Tab--active' : ''}`}
                              onClick={() => onClickSetActiveTab(ASSETLIST_TABS.SLIDESHOW)}>
                Slideshows
              </div>
            </div>
            <div className='AssetList'>
              <div className='AssetList__Header'>
                <div className='AssetList__Header__Column AssetList__Header__ColumnChecked'>
                  <Checkbox label=''
                            checked={assets.length ? !hasUnselectedItem : false}
                            onChange={onCheckAllChecked}/>
                </div>
                <div className='AssetList__Header__Column AssetList__Header__ColumnThumbnail overline-bold'>
                  ASSET
                </div>
                <div className='AssetList__Header__Column AssetList__Header__ColumnName overline-bold'>
                  <ListSortableHeader text='NAME'
                                      fieldName='title'
                                      orderBy={orderBy}
                                      onChangeOrder={onChangeOrder}/>
                </div>
                <div className='AssetList__Header__Column AssetList__Header__ColumnType overline-bold'>
                  <ListSortableHeader text='TYPE'
                                      fieldName='type'
                                      orderBy={orderBy}
                                      onChangeOrder={onChangeOrder}/>
                </div>
                <div className='AssetList__Header__Column AssetList__Header__ColumnCreatedAt overline-bold'>
                  <ListSortableHeader text='CREATED AT'
                                      fieldName='createdAt'
                                      orderBy={orderBy}
                                      onChangeOrder={onChangeOrder}/>
                </div>
                <div className='AssetList__Header__Column AssetList__Header__ColumnStatus overline-bold'>
                  <ListSortableHeader text='STATUS'
                                      fieldName='status'
                                      orderBy={orderBy}
                                      onChangeOrder={onChangeOrder}/>
                </div>
                <div className='AssetList__Header__Column AssetList__Header__ColumnActions overline-bold'>
                  &nbsp;
                </div>
              </div>
              <div className='AssetList__Content'>
                {assetList}
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    );
  }
}
