import gql from 'graphql-tag';
import _get from 'lodash/get';

import React, { Component, Fragment } from 'react';
import { compose, graphql } from 'react-apollo';

import GroupList from '../../components/GroupList';
import EmbedAsset from '../../components/EmbedAsset';
import { EnumAssetType } from '../../models/Asset';
import {VERTICAL_ID} from "../../tools/vertical";
import CompanyService from "../../tools/company";

export const ASSETLIST_TABS = {
  ALL: 'all',
  UPLOAD: 'upload',
  YOUTUBE: 'youtube',
  SLIDESHOW: 'slideshow',
  PLAYLIST: 'playlist'
};

class AssetListContainer extends Component {
  state = {
    groups: [],
    orderBy: {
      fieldName: 'createdAt',
      order: 'desc'
    }
  }

  componentDidMount() {
    this.props.setActiveModuleTitle('Groups');

    if (this.props.location.state && this.props.location.state.redirectTo) {
      this.setState({ redirectTo: this.props.location.state.redirectTo });
    }
  }

  componentWillReceiveProps(props) {
    if (props.groupList && !props.groupList.loading && props.groupList.groups) {
      this.setState({ groups: props.groupList.groups });
      this.onChangeOrder('createdAt')
    }
  }

  handleClickDetails = (asset) => () => {
    this.setState({
      redirectTo: `/verticals/${asset.vertical}/groups/${asset.id}`
    })
  }

  onChangeOrder = (fieldName) => {
    let orderBy = Object.assign({}, this.state.orderBy);
    if (this.state.orderBy.fieldName !== fieldName) {
      orderBy = {
        fieldName,
        order: 'asc'
      };
    } else {
      orderBy.order = orderBy.order === 'asc' ? 'desc' : 'asc';
    }

    const isEnumValue = fieldName === 'status' || fieldName === 'type';

    this.state.groups
      .sort((a, b) => {
        if ((isEnumValue && _get(a, `${orderBy.fieldName}.name`) && _get(b, `${orderBy.fieldName}.name`) && _get(a, `${orderBy.fieldName}.name`) > _get(b, `${orderBy.fieldName}.name`)) ||
            (!isEnumValue && a[orderBy.fieldName] > b[orderBy.fieldName])) {
          return orderBy.order === 'asc' ? 1 : -1;
        } else if ((isEnumValue && _get(a, `${orderBy.fieldName}.name`) && _get(b, `${orderBy.fieldName}.name`) && _get(a, `${orderBy.fieldName}.name`) < _get(b, `${orderBy.fieldName}.name`)) ||
            (!isEnumValue && _get(a, fieldName) < _get(b, fieldName))) {
          return orderBy.order === 'asc' ? -1 : 1;
        }

        return 0;
      });

    this.setState({ orderBy });
  }

  handleClickSetActiveTab = (activeTab) => {
    this.setState({ activeTab });
  }

  getUploadedVideos = () => {
    return this.state.assets.filter((asset) => asset.type && asset.type.value === EnumAssetType.VIDEO && !asset.youtubeId);
  }

  getYoutubeVideos = () => {
    return this.state.assets.filter((asset) => asset.type && asset.type.value === EnumAssetType.VIDEO && asset.youtubeId);
  }

  getSlideshows = () => {
    return this.state.assets.filter((asset) => asset.type && asset.type.value === EnumAssetType.SLIDESHOW);
  }

  getPlaylists = () => {
    return this.state.assets.filter((asset) => asset.type && asset.type.value === EnumAssetType.PLAYLIST);
  }

  filterBySearch = (search, groups) => {
    if (!search) return groups;

    return groups.filter((asset) => (
      (asset.products && asset.products.find(({ title }) => title.toUpperCase().indexOf(search.toUpperCase()) !== -1)) ||
      (asset.title.toUpperCase().indexOf(search.toUpperCase()) !== -1)
    ));
  }

  render () {
    const { orderBy, asset, groups, connection, openEmbedAsset, redirectTo, search } = this.state;

    const filteredByTabAssets = [...groups];

    const filteredBySearchAssets = this.filterBySearch(search, filteredByTabAssets);

    return (
      <Fragment>
        {connection && openEmbedAsset && (
          <EmbedAsset
            open={openEmbedAsset}
            source={connection.source}
            hash={connection.hash}
            onClose={this.handleCloseEmbedAsset}
            type={asset.type.value}
            name={asset.name}
            company={asset.company}
          />
        )}
        <GroupList  verticalId={this.props.match.params.verticalId}
                    groups={groups}
                    assets={filteredBySearchAssets}
                    redirectTo={redirectTo}
                    onClickDetails={this.handleClickDetails}
                    onCheckAllChecked={this.handleCheckAllChecked}
                    onSelectAsset={this.handleSelectAsset}
                    deleteCheckedAssets={this.deleteCheckedAssets}
                    onChangeSearch={this.handleChangeSearch}
                    orderBy={orderBy || {}}
                    onChangeOrder={this.onChangeOrder}
                    onClickSetActiveTab={this.handleClickSetActiveTab}
                    onRefetchGroup={this.props.groupList.refetch}
        />
      </Fragment>
    );
  }
}

const GroupListQuery = gql`
  query groups($vertical: String!) {
    groups(vertical: $vertical) {
      id,
      title,
      vertical,
      company,
      createdAt
    }
  }
`;

const assetGqls = compose(
  graphql(GroupListQuery, {
    name: 'groupList',
    options: ({ match }) => {
      return ({
        variables: {
          vertical: match.params.verticalId,
        },
        fetchPolicy: 'network-only'
      })
    }
  }),
);

export default assetGqls(AssetListContainer);
