import React, { Component } from 'react';
import { DateInput } from 'semantic-ui-calendar-react';
import { Card } from 'semantic-ui-react';
import ReportContainer from '../../containers/Report';

import './style.css';

const formatDateValue = (date) => {
  const completeDate = (day) => String(day).length === 1 ? `0${day}` : day;
  const month = completeDate(date.getMonth() + 1);
  const day = completeDate(date.getDate());
  const year = completeDate(date.getFullYear());

  return [month, day, year].join('/');
};

export default class AssetAnalytics extends Component {
  state = {
    startDate: '',
    endDate: ''
  }

  constructor(props) {
    super(props);
    this.handleStartChange = this.handleStartChange.bind(this);
    this.handleEndChange = this.handleEndChange.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }
  
  componentWillReceiveProps (props) {
    if (props.asset && props.asset.createdAt) {
      const startDate = new Date(props.asset.createdAt);
      const endDate = new Date();

      if (startDate.getDate() === endDate.getDate()) {
        startDate.setDate(startDate.getDate() - 2);
      }

      this.setState({
        startDate: formatDateValue(startDate),
        endDate: formatDateValue(endDate)
      })
    }
  }

  formatDate = (date) => {
    const month = date.substring(0,2);
    const day = date.substring(3,5);
    const year = date.substring(6,10);

    return [year, month, day].join('-');
  }

  handleStartChange = (event, { value }) => {
    this.setState({ startDate: value });
  }

  handleEndChange = (event, { value }) => {
    this.setState({ endDate: value });
  }

  render() {
    const { title, name, company, onClickTreeAssets } = this.props;

    return (
      <div className='AssetAnalyticsPage'>
        <div className='HeaderWrapper'>
          <div  className='HeaderWrapper__Item HeaderWrapper__Item--link cred heading-3'
                onClick={ onClickTreeAssets }>
            Assets
          </div>
          <span className='HeaderWrapper__Divider'>></span>
          <div className='HeaderWrapper__Item cred heading-3'>Analytics: {' ' + title}</div>
        </div>
        <Card>
          <Card.Content>
          <div className='AssetAnalyticsPage__Content__Title'>
            <div className='heading-4 cblack'>
              Track the performance of your asset.
            </div>
            <div className='AssetAnalyticsPage__Content__DatePickers'>
              <DateInput
                className='AssetAnalyticsPage__Content__DatePickers__Start'
                closable
                dateFormat="MM/DD/YYYY"
                name="startDate"
                placeholder="Start Date"
                value={this.state.startDate}
                iconPosition="left"
                onChange={this.handleStartChange}
                minDate={'12/14/2018'}
                maxDate={new Date()}
              />
              <DateInput
                className='AssetAnalyticsPage__Content__DatePickers__End'
                closable
                dateFormat="MM/DD/YYYY"
                name="endDate"
                placeholder="End Date"
                value={this.state.endDate}
                iconPosition="left"
                onChange={this.handleEndChange}
                minDate={this.state.startDate || '12/14/2018'}
                maxDate={new Date()}
              />
            </div>
          </div>
          <div className='HorizontalDivider'>&nbsp;</div>

          { this.state.startDate && this.state.endDate ?
              <ReportContainer company = { company }
                      title = { title }
                      name = { name }
                      startDate = { this.formatDate(this.state.startDate) }
                      endDate = { this.formatDate(this.state.endDate) }/>
              :
              <div className="AssetAnalyticsPage__Content__Loader cgrey">
                <h2>Enter a start and end date</h2>
              </div>
            }
          </Card.Content>
        </Card>
    </div>

    );
  }
}
