import React, { Component, Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import SignContainer from './containers/Sign';
import AuthedRoute from './containers/AuthedRoute';
import AssetAnalyticsContainer from './containers/AssetAnalytics';
import AssetListContainer from './containers/AssetList';
import AssetDetailContainer from './containers/AssetDetail';
import AssetVideoEditContainer from './containers/AssetVideoEdit';
import ProductListContainer from './containers/ProductList';
import QuoteListContainer from './containers/QuoteList';
import PageWrapper from './containers/PageWrapper';
import SettingsContainer from './containers/Settings';
import CompanyPlayerContainer from './containers/CompanyPlayer';
import GroupListContainer from './containers/GroupList';
import GroupDetailContainer from './containers/GroupDetail';
import VerticalListContainer from './containers/VerticalList';

const redirectToHome = () => {
  return (
    <Redirect to={{pathname: '/login'}}/>
  );
}

const redirectToAssets = () => {
  return (
    <Redirect to={{pathname: '/assets'}}/>
  );
}

const redirectToVerticals = () => {
  return (
    <Redirect to={{pathname: '/verticals'}}/>
  );
}

const redirectToQuotes = () => {
  return (
    <Redirect to={{pathname: '/quotes'}}/>
  );
}

const wrapper = (ComponentInstance, props) => (
  <PageWrapper>
    <ComponentInstance  {...props} />
  </PageWrapper>
);

export default class App extends Component {
  state = {
    description: 'Making Videos Shoppable',
    image: 'https://s3.amazonaws.com/react.acuity.video/acuity.jpg',
    title: 'Acuity.AI'
  };

  componentDidMount() {
    document.body.style.setProperty('--secondary', '#B7072E');
  }

  render() {
    const { title, description, image } = this.state;

    return (
      <Fragment>
        <Helmet>
          <title>{title}</title>
          <meta name='description'
                content={description} />
          {image ? (
            <link rel='image_src'
                  href={image} />
          ) : null}
          {image ? (
            <meta itemProp='image'
                  content={image} />
          ) : null}
          <meta property='og:title'
                content={title} />
          {description ? (
            <meta property='og:description'
                  content={description}
            />
          ) : null}
          {image ? (
            <meta property='og:image'
                  content={image} />
          ) : null}

          {/* change type of twitter if there is no image? */}
          <meta name='twitter:card'
                content='summary_large_image'
          />
          <meta name='twitter:title'
                content={title} />
          {description ? (
            <meta name='twitter:description'
                  content={description}
          />
          ) : null}
          {image ? (
            <meta name='twitter:image'
                  content={image} />
          ) : null}
        </Helmet>
        <Router>
          <Switch>
            <Route  path='/login'
                    component={SignContainer}/>
            <AuthedRoute  path='/assets/:name/video'
                          render={(props) => wrapper(AssetVideoEditContainer, props) }/>
            <AuthedRoute  path='/assets/:name/details/:company'
                          render={(props) => wrapper(AssetDetailContainer, props) }/>
            <AuthedRoute  path='/assets/:name/details'
                          render={(props) => wrapper(AssetDetailContainer, props) }/>
            <AuthedRoute  path='/assets/:name/analytics'
                          render={(props) => wrapper(AssetAnalyticsContainer, props) }/>
            <AuthedRoute  path='/assets'
                          render={(props) => wrapper(AssetListContainer, props) }/>
            <AuthedRoute  path='/products'
                          render={(props) => wrapper(ProductListContainer, props) }/>
            <AuthedRoute  path='/quotes'
                          render={(props) => wrapper(QuoteListContainer, props) }/>
            <AuthedRoute  path='/settings'
                          render={(props) => wrapper(SettingsContainer, props) }/>
            <AuthedRoute  path='/player'
                          render={(props) => wrapper(CompanyPlayerContainer, props) }/>
            <AuthedRoute  path='/verticals/:verticalId/groups/:id'
                          render={(props) => wrapper(GroupDetailContainer, props) }/>
            <AuthedRoute  path='/verticals/:verticalId'
                          render={(props) => wrapper(GroupListContainer, props) }/>
            <AuthedRoute  path='/verticals'
                          render={(props) => wrapper(VerticalListContainer, props) }/>
            <AuthedRoute  path='/'
                          exact
                          component={redirectToVerticals}/>
            <Route  path='*'
                    component={redirectToHome}/>
          </Switch>
        </Router>
      </Fragment>
    );
  }
}
