import './style.css';

import React, {Component} from 'react';
import {Card, Image, Dimmer, Loader, Button} from 'semantic-ui-react';
import Moment from 'react-moment';
import {
  mdiPlus,
} from '@mdi/js'
import Icon from '@mdi/react'
import AssetVideoEditProductContainer from '../../containers/AssetVideoEditProduct';
import {DeleteOutline, ArrowLeftOutlined, ArrowRightOutlined, EditOutlined} from "@material-ui/icons";
import CompanyService from '../../tools/company';
import clipboardCopy from 'clipboard-copy';
import ModalCreateGroup from '../ModalCreateGroup';

export default class GroupDetail extends Component {
  state = {
    isAssetDescriptionOpened: false,
    isAssetEditOpened: false,
    isEmbedMiniClipOpened: false,
    copied: false
  };
  
  handleOpenAssetGeneralInformationEdit = () => {
    this.setState({
      isAssetGeneralInformationEditOpened: true
    });
  }

  handleCloseAssetGeneralInformationEdit = (values) => {
    this.setState({
      isAssetGeneralInformationEditOpened: false
    });
    this.props.afterCloseAssetEdit();
  }

  onClickEditImageProducts = () => {
    this.setState({isAssetEditImageProductsOpened: true});
  }

  onCloseEditImageProducts = (hasChanged) => {
    this.setState({isAssetEditImageProductsOpened: false});

    if (hasChanged) {
      this.props.afterCloseAssetEditShoppableImage();
    }
  }

  handleClickOpenAddVideoProduct = () => {
    const {lastProductTime} = this.props;

    this.setState({
      isOpenProductVideoPopup: true,
      editProductVideo: undefined,
      initialTimeProduct: lastProductTime
    });
  }

  handleClickCloseEditVideoProduct = ({product, time, addOther}) => {
    this.setState({
      isOpenProductVideoPopup: false
    }, () => {
      if (addOther) this.handleClickOpenAddVideoProduct();
    });

    if (product && this.state.editProductVideo) return this.props.onEditProduct({
      newProduct: product,
      time,
      previousProduct: this.state.editProductVideo
    });

    if (product) this.props.onAddProduct({product, time});
  }

  render() {
    const {
      asset,
      products,
      onClickTreeAssets,
      isLoadingProducts,
      onClickDeleteProduct,
      onChangeProductPosition,
    } = this.props;
    const {id, title, vertical, createdAt} = asset;
    const {
      isOpenProductVideoPopup,
      editProductVideo,
      initialTimeProduct,
      isAssetGeneralInformationEditOpened,
      copied
    } = this.state;
    const company = CompanyService.get();


    const mountProductList = () => products && products.length ?
      products
        .map((product, i) => {
          return (
            <div className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper'
                 key={i}>
              <div className="AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Actions">
                <div
                    className="AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Actions__Action"
                    style={{ borderLeft: 0, borderRight: `1px solid #E0E0E0` }}
                    onClick={() => onClickDeleteProduct(product, i)}>
                  <DeleteOutline fontSize='small' />
                </div>
                <div className="AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Actions__Arrow">
                  <div  className="AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Actions__Action"
                        onClick={() => onChangeProductPosition(i, i - 1)}>
                    <ArrowLeftOutlined fontSize='small' />
                  </div>
                  <div
                    className="AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Actions__Action"
                    style={{ borderRight: 0 }}
                    onClick={() => onChangeProductPosition(i, i + 1)}>
                    <ArrowRightOutlined fontSize='small' />
                  </div>
                </div>
              </div>
              <div className={`AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information`}>
                <div className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information__ImageWrapper'>
                  <Image src={product.images[0]}/>
                </div>
                <div
                  className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information__Title overline-bold'>
                  {product.title}
                </div>
              </div>
            </div>
          );
        })
      : null;
    
      const handleCopy = async () => {
        this.setState({
          copied: true
        });
        await clipboardCopy(JSON.stringify({
          company: company.name,
          productVertical: vertical,
          productGroup: id
        }, null, ' '))
        setTimeout(() => {
          this.setState({
            copied: false
          });
        }, 3000);
      }

    return (
      <div className='AssetDetailPage'>
        {isAssetGeneralInformationEditOpened ?
          <ModalCreateGroup open={isAssetGeneralInformationEditOpened}
                                                onClose={this.handleCloseAssetGeneralInformationEdit}
                                                group={asset}/>
          : null}
        {isOpenProductVideoPopup ? (
          <AssetVideoEditProductContainer product={editProductVideo}
                                          open={isOpenProductVideoPopup}
                                          asset={asset}
                                          onClose={this.handleClickCloseEditVideoProduct}
                                          assetJSON={asset}
                                          initialTimeProduct={initialTimeProduct}/>
        ) : null}
        <div className='HeaderWrapper'>
          <div className='HeaderWrapper__Item HeaderWrapper__Item--link cred heading-3'
               onClick={onClickTreeAssets}>
            Groups
          </div>
          <span className='HeaderWrapper__Divider'>></span>
          <div className='HeaderWrapper__Item cred heading-3'>Details</div>
        </div>
        <div className='AssetDetailPage__Content'>
          <div className='AssetDetailPage__Content__Left'>
            <Card>
              <Card.Content>
                <div className='AssetDetailPage__GeneralContent__Header'>
                  <div className='heading-4 cblack'>
                    General Information
                  </div>
                  <div  className='AssetDetailPage__GeneralContent__Header__EditWrapper caption cgrey'
                        onClick={this.handleOpenAssetGeneralInformationEdit}>
                    <EditOutlined fontSize='small'
                                  className='AssetDetailPage__GeneralContent__Header__EditWrapper__Icon' />
                    Edit
                  </div>
                </div>
                <div className='HorizontalDivider'>&nbsp;</div>
                <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Title</div>
                <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>{title}</div>
                <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Created At</div>
                <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>{createdAt
                  ? <Moment format="MMM DD, YYYY | hh:mma">
                    {createdAt}
                  </Moment>
                  : ' '}
                </div>
                <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Company</div>
                <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>{company.name}</div>
                <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Vertical</div>
                <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>{vertical}</div>
                <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Group</div>
                <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>{id}</div>
                <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Config</div>
                <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>
                  <Button
                    onClick={handleCopy}>
                    {copied ? 'Copied to clipboard' : 'Copy config'}
                  </Button>
                </div>
              </Card.Content>
            </Card>
          </div>
          <div className='AssetDetailPage__Content__Right'>
            <Card>
              <Card.Content>
                <div className='AssetDetailPage__ProductsWrapper__Header'>
                  {isLoadingProducts ?
                    <Dimmer active
                            inverted>
                      <Loader/>
                    </Dimmer>
                    : null}
                  <div className='heading-4 cblack AssetDetailPage__ProductsWrapper__Header__Title'>
                    Products in this asset
                  </div>
                  <div className='AssetDetailPage__ProductsWrapper__Header__EditWrapper__ActionWrapper'
                       onClick={this.handleClickOpenAddVideoProduct}>
                    <Icon path={mdiPlus}
                          size={0.8}
                          className='AssetDetailPage__ProductsWrapper__Header__EditWrapper__Icon'/>
                    Add Product
                  </div>
                </div>
                <div className='HorizontalDivider'>&nbsp;</div>
                {products && products.length ? (
                  <div className='AssetDetailPage__ProductsWrapper__Products'>
                    {mountProductList()}
                  </div>
                ) : !isLoadingProducts ? (
                  <div className='AssetDetailPage__ProductsWrapper__AddProducts'>
                    <div className='AssetDetailPage__ProductsWrapper__AddProducts__Text cblack'>
                      You don't have products in this asset
                    </div>
                  </div>
                ) : null}
              </Card.Content>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
