import * as d3 from 'd3';
import React, { Component } from 'react';


export default class ProductChart extends Component {
	state = {
		chart: null
	}

	componentDidUpdate(prevProps) {
	  if (this.props.data !== prevProps.data) {
	  	const svg = this.chart;
	  	while (svg.hasChildNodes()) {
		    svg.removeChild(svg.lastChild);
		}
    this.renderChart();
	  }
	}

	componentDidMount() {
		this.renderChart();
	}

	renderChart() {
		const svg = d3.select(this.chart);
		const chartData = this.props.data;
		const margin = {top: 30, right: 50, bottom: 30, left: 50};
		const width = 600 - margin.left - margin.right;
 		const height = 200 - margin.top - margin.bottom;
		const cred = d3.rgb("#B7072E");


    const g = svg.append('g')
		.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

		const x = d3.scaleBand()
			.domain(chartData.map(d => d.label))
			.rangeRound([0, width])
			.padding(0.1);

		const y = d3.scaleLinear()
			.domain([0, Math.ceil((chartData[0].value+1)/5)*5])
			.rangeRound([height, 0]);

		const make_x_gridlines = () => d3.axisBottom(x).ticks(2);
		const make_y_gridlines = () => d3.axisLeft(y).ticks(5);

		g.append("g")
	    .attr("class", "grid")
	    .attr("transform", "translate(0," + height + ")")
	    .call(make_x_gridlines()
	        .tickSize(-height)
	        .tickFormat("")
	  )

	  g.append("g")
	    .attr("class", "grid")
	    .call(make_y_gridlines()
	        .tickSize(-width)
	        .tickFormat("")
		  )


    g.append('g')
		.attr('transform', 'translate(0,' + height + ')')
		.call(d3.axisBottom(x))
		.selectAll(".tick text")
      .call(this.wrap, x.bandwidth());

		g.append("g")
      .attr("class", "y axis")
      .call(d3.axisLeft(y));

		g.append('text')
			.attr('fill', '#000')
			.attr('transform', 'rotate(-90)')
			.attr('y', -40)
			.attr('dy', "0.71em")
			.attr("text-anchor", "end")
			.text(this.props.label);


		g.selectAll(".bar")
			.data(chartData)
			.enter().append("rect")
			.attr("class", "barRect")
			.attr("x", (d) => {
				return x(d.label) || null;
			})
			.attr("y", (d) => {
				return y(d.value);
			})
			.attr("width", x.bandwidth())
			.attr("height", (d) => {
				return height - y(d.value);
			})
			.attr("fill", cred);

		g.selectAll(".bar")
			.data(chartData)
			.enter().append("text")
			.attr("class", "value")
			.attr("text-anchor", "middle")
			.attr("x", 	(d) => {
				const a = x(d.label) || null;
				if (a) {
					return (a*2+x.bandwidth())/2;
				}
				else {
					return null;
				}
			})
			.attr("y", (d) => {
				return y(d.value) - 5;
			})
			.text((d) => {
				return d.value;
			});
	}

	wrap(text, width) {
	  text.each(function() {
	    var text = d3.select(this),
	        words = text.text().split(/\s+/).reverse(),
	        word,
	        line = [],
	        lineNumber = 0,
	        lineHeight = 1.1,
	        y = text.attr("y"),
	        dy = parseFloat(text.attr("dy")),
	        tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
	    while (word = words.pop()) {
	      line.push(word);
	      tspan.text(line.join(" "));
	      if (tspan.node().getComputedTextLength() > width) {
	        line.pop();
	        tspan.text(line.join(" "));
	        line = [word];
	        tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
	      }
	    }
	  });
	}

	render() {
		return (
			<svg
				ref={(ref: SVGSVGElement) => this.chart = ref}
				width={600}
				height={200}
			/>
		)
	}
}
