function parseJwt (token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const isUserMaster = () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return false;
  }

  const userInfo = parseJwt(token);

  return userInfo.permission.name === 'Master';
}