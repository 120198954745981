const {
  REACT_APP_ACUITY_INJECTOR_URL
} = process.env;

export const scriptTag = ({ name }) => [
  `    <script>`,
  `       (function (d, w, c) {`,
  `         var $s = d.createElement('script');`,
  `         $s.src = '${REACT_APP_ACUITY_INJECTOR_URL}';`,
  `         $s.onload = function () { w.acuityai.start({ company: c }); };`,
  `         d.body.appendChild($s);`,
  `       })(document, window, '${name}');`,
  `    </script>`,
].join('\n');

export default ({ name }) => [
  `<!DOCTYPE html>`,
  `<html lang="en">`,
  `<head>`,
  `    <meta charset="UTF-8">`,
  `    <meta name="viewport" content="width=device-width, initial-scale=1.0">`,
  `    <meta http-equiv="X-UA-Compatible" content="ie=edge">`,
  `    <title>AcuityAI Shoppable</title>`,
  `</head>`,
  `<body>`,
  scriptTag({ name }),
  `</body>`,
  `</html>`
].join('\n');