const events = {};

class EventLite {
  on (event, callback) {
    (events[event] = events[event] || []).push(callback);
  }

  emit (event, data) {
    window.parent.postMessage(JSON.stringify({ event, data }), '*');
    (events[event] = events[event] || []).forEach((callback) => {
      if (callback) {
        callback(data);
      }
    });
  }

  off (event, f) {
    const _events = (events[event] = events[event] || []);
    events[event] = _events.filter((i) => i !== f);
  }
}

const eventEmitter = new EventLite();

function bindEvent (element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, eventHandler);
  }
}

bindEvent(window, 'message', (data) => {
  try {
    const eventObject = JSON.parse(data.data);
    eventEmitter.emit(eventObject.msg, eventObject.data);
  } catch(e) {
    // console.log(e);
  }
});

export default eventEmitter;