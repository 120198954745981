import gql from 'graphql-tag';
import React, { Component, Fragment } from 'react';
import { compose, graphql } from 'react-apollo';
import ModalAssetUpload from '../../components/ModalAssetUpload';
import Axios from 'axios';
import _get from 'lodash/get';

class ChangeLookMainImageContainer extends Component {
  state = {}

  handleClickSave = () => {
    const { asset } = this.props;
    const { file } = this.state;

    const { name } = asset;
    const data = new FormData();
    const variables = {
      name,
      file: null,
      company: localStorage.getItem('company'),
    };
    const params = {
      operationName: 'uploadLookMainImage',
      variables,
      query: 'mutation uploadLookMainImage($name: String!, $company: String!, $file: Upload!) {\n  uploadLookMainImage(name: $name, company: $company, file: $file) \n}'
    }

    if (file) {
      data.append('operations', JSON.stringify(params));
      data.append('map', JSON.stringify({
        0: ['variables.file']
      }));
      data.append('0', file);

    } else {
      throw new Error('must to send file or fileUrl');
    }

    this.setState({
      loadingSave: true
    });
    this.setState(Object.assign({}, this.state, { loadingProgress: 0 }));

    Axios
      .post(`${process.env.REACT_APP_API_URL}/graphql`, file ? data : params, {
        headers: {
          'authorization': 'Bearer ' + localStorage.getItem('token')
        },
        onUploadProgress: (progressEvent) => {
          this.setState(Object.assign({}, this.state, { loadingProgress: Math.round((progressEvent.loaded * 100) / progressEvent.total) }));
        }
      })
      .then((response) => {
        this.setState(Object.assign({}, this.state, { loadingProgress: undefined, loadingSave: false }));
        this.props.onClose(_get(response, 'data.data.uploadLookMainImage', null));
      })
      .catch((error) => {
        this.setState(Object.assign({}, this.state, { loadingProgress: undefined, loadingSave: false }));
      });
  }

  handleSelectFile = ({ target: { files } }) => {
    if (files && files[0]) {
      const splittedFileName = files[0].name.split('.');
      const fileName = splittedFileName.slice(0, splittedFileName.length - 1).join('');
      this.setState(Object.assign({}, this.state, {
        title: fileName,
        file: files[0],
        urlFile: URL.createObjectURL(files[0])
      }));
    }
  }

  render() {
    const { onClose, open } = this.props;
    const { file, loadingSave, urlFile } = this.state;

    const acceptedFileTypes = 'image/x-png,image/gif,image/jpeg';

    return (
      <ModalAssetUpload onClose={onClose}
                        open={open}
                        onSelectFile={this.handleSelectFile}
                        onClickUpload={this.handleClickSave}
                        file={file}
                        imageUrlFile={urlFile}
                        acceptedFileTypes={acceptedFileTypes}
                        loadingSave={loadingSave} />
    );
  }
}

const AssetCreateMutation = gql`
  mutation saveAsset($title: String!, $company: String!, $youtubeId: String, $thumbnail: String, $description: String, $assets: [ISubAsset], $assetsType: Int, $closeLocation: String, $videoCreator: String, $disablePlaylist: Boolean, $zoomIn: Boolean){
    asset(title: $title, company: $company, youtubeId: $youtubeId, thumbnail: $thumbnail, description: $description, assets: $assets, assetsType: $assetsType, closeLocation: $closeLocation, videoCreator: $videoCreator, disablePlaylist: $disablePlaylist, zoomIn: $zoomIn) {
      name
      title
      youtubeId
      assets {
        set
        name
      }
    }
  }
`;

const assetGqls = compose(
  graphql(AssetCreateMutation, {
    name: 'assetCreate'
  })
)

export default assetGqls(ChangeLookMainImageContainer);