import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import ShopifyIcon from '../../../Icons/shopify';
import ShopifyLogo from '../../../Icons/shopify-logo';
import _get from 'lodash/get';
import './style.css';

export default ({
  company,
  onClickConnectShopify,
  onClickCopyConnectShopify,
  onClickDisconnect
}) => {
  const [isCopied, setCopied] = useState(false);
  const [isCoping, setCoping] = useState(false);
  return (
    <div
      className='ShopifySetup'>
      {_get(company, 'store.config.storefrontAccessToken') ? (
        <div>
          <ShopifyLogo
            className='Shopify__Logo'
          />
          <span>
            Connected to  {_get(company, 'store.config.host')}. <a onClick={onClickDisconnect}>disconnect</a>
          </span>
        </div>
      ) : (
        <div
          className='ShopifySetupToolbar'>
          <Button
            className='ShopifyButton'
            onClick={onClickConnectShopify}>
            <ShopifyIcon
              className='ShopifyButton__Icon'/>
            Connect shopify
          </Button>
          <Button
            disabled={isCoping}
            className='ShopifyButton ShopifyButton--Inverse'
            onClick={async () => {
              setCopied(true);
              setCoping(true);
              await onClickCopyConnectShopify();
              setTimeout(() => setCopied(false), 3000);
              setCoping(false);
            }}>
            <ShopifyIcon
              className='ShopifyButton__Icon'/>
            {isCopied ? 'Link copied to clipboard!' : 'Copy authorization link'}
          </Button>
        </div>
      )}
    </div>
  )
}