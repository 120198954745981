import clipboardCopy from 'clipboard-copy';
import { sha256 } from 'js-sha256';
import React, { Component } from 'react';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';

import GroupDetail from '../../components/GroupDetail';
import {
  generateFileUrl,
  generateMiniClipUrl,
  generatePreviewUrl,
  assetExtensions,
} from '../../models/Asset';
import { isUserMaster } from '../../models/User';
import CompanyService from '../../tools/company';
import {VERTICAL_ID} from "../../tools/vertical";
import ModalConfirm from "../../components/ModalConfirm";

const TIME_WINDOW = 15;

class GroupDetailContainer extends Component {
  componentDidMount() {
    this.props.setActiveModuleTitle('Groups');
  }

  componentWillReceiveProps(props) {
    if (!props.groupQuery.loading && props.groupQuery.group) {
      this.setState({
        group: props.groupQuery.group
      });
    }
  }

  afterCloseAssetEdit = () => {
    this.props.groupQuery.refetch();
  }

  afterCloseAssetEditShoppableImage = () => {
    const { asset } = this.state;

    const { company, name, type } = asset;
    const previewUrl = generatePreviewUrl({ company, name, type: type.value });
    this.setState({
      previewUrl: `${previewUrl}?v=${Date.now()}`
    });

    this.props.assetQuery.refetch();
  }

  handleLoadedMiniClip = ({ id }) => {
    return () => {
      const products = this.state.products.map((product) => {
        if (product.id === id) {
          return { ...product, hasMiniClip: true }
        }

        return product;
      });

      this.setState({ products });
    }
  }

  handleClickProductLink = (product) => {
    return () => {
      window.open(product.link, '_blank');
    };
  }

  handleClickGetEmbbedCode = () => {
    const { asset } = this.state;
    const { assetConnection } = this.props;
    const hash = sha256(`${asset.name}:${asset.company}:videotag`);

    assetConnection({
      variables: {
        name: asset.name,
        company: asset.company,
        connectionHash: hash,
        webLocation: hash
      }
    });

    const source = generateFileUrl({
      company: asset.company,
      name: asset.name,
      type: asset.type.value
    });

    this.setState({
      openEmbedAsset: true,
      connection: {
        source,
        hash
      }
    });
  }

  handleCloseEmbedAsset = () => {
    this.setState({
      openEmbedAsset: false,
      connection: null
    })
  }

  handleClickAssetDetails = (asset) => {
    return () => {
      this.setState({
        redirectTo: `/assets/${asset.name}/details${isUserMaster() ? `/${asset.company}` : ''}`
      })
    };
  }

  handleClickTreeAssets = () => {
    this.setState({
      redirectTo: `/assets`,
    })
  }

  handleAfterEditGeneralInformation = () => {
    this.props.assetQuery.refetch();
  }

  handleClickGetLink = () => {
    const { webpage } = CompanyService.get();
    const { asset } = this.state;
    let previewUrl;

    if (webpage) {
      previewUrl = `${webpage}?asset=${asset.name}&type=${assetExtensions[asset.type.value]}`;
    } else {
      previewUrl = this.state.previewUrl;
    }

    clipboardCopy(previewUrl);
    this.setState({
      isLinkCopied: true
    });

    setTimeout(() => this.afterLinkCopied(), 2000);
  }

  afterLinkCopied = () => {
    this.setState({
      isLinkCopied: false
    });
  }

  handleClickApprove = () => {
    const { asset } = this.state;

    this.setState({
      isLoadingApproveOrDecline: true
    });

    this.props.approveAssetMutation({
      variables: {
        name: asset.name,
        company: asset.company
      }
    }).then((resp) => {
      this.setState({ isLoadingApproveOrDecline: false });
      this.props.assetQuery.refetch();
    })
    .catch((e) => {
      this.setState({ isLoadingApproveOrDecline: false });
    });
  }

  handleClickDecline = () => {
    const { asset } = this.state;

    this.setState({
      isLoadingApproveOrDecline: true
    });

    this.props.rejectAssetMutation({
      variables: {
        name: asset.name,
        company: asset.company
      }
    }).then((resp) => {
      const _asset = Object.assign({}, asset, { status: resp.data.rejectAsset.status });
      this.setState({ isLoadingApproveOrDecline: false, asset: _asset });
    })
    .catch((e) => {
      this.setState({ isLoadingApproveOrDecline: false });
    });
  }

  handleAfterPublishYoutubeVideo = () => {
    this.props.assetQuery.refetch();
  }

  handleAfterChooseOrder = () => {
    this.props.assetQuery.refetch();
    this.props.assetsFromAsset.refetch();
  }

  handleChangeJSONFile = (files) => {
    console.log('Needs to be implemented!');
  }

  handleClickGenerateMiniClips = () => {
    const { asset } = this.state;
    const { company, name } = asset;

    this.props.createMiniClip({
      variables: { company, name }
    });
  }

  handleClickRemoveMiniClip = (productId) => {
    const { asset } = this.state;
    const { company, name } = asset;

    this.props.removeMiniClip({
      variables: { company, name, productId }
    });
  }

  handleAfterPublishFacebookVideo = () => {
    this.props.assetQuery.refetch();
  }

  handleClickDeleteProduct = (product, productIndex) => {
    this.setState({
      productIndex,
      productToRemove: product,
      isConfirmDeleteProductOpened: true
    })
  }

  handleClickNoConfirmDeleteProduct = () => {
    this.setState({
      isConfirmDeleteProductOpened: false
    });
  }
  
  onDeleteProduct = () => {
    this.setState({
      isConfirmDeleteProductOpened: false
    });
    this._removeProduct(this.state.productToRemove, this.state.productIndex);
  }

  getProductIndexs = (product) => {
    const { asset } = this.state;
    const { media: { framerate = 0 } } = asset;

    if (asset) {
      const keyFrames = Object.keys(asset.frames);
      const indexKeyFrame = keyFrames.findIndex(key => key === product.frame);
      let lastKeyFrame = Math.round(Number(TIME_WINDOW * framerate));

      if (lastKeyFrame !== -1) {
        lastKeyFrame = indexKeyFrame + lastKeyFrame;
      }

      return [indexKeyFrame, lastKeyFrame];
    }

    return [];
  }

  _removeProduct = (product, productIndex) => {
    const { group } = this.state;

    if (group) {
      const products = group.products.filter((_, index) => productIndex !== index);
      this.setState({ group: { ...group, products } }, () => {
        this.saveProducts();
      });
    }
  }

  getProducts(data) {
    const {
      frames = {},
      items = {},
      media
    } = data;

    if (!frames || !items) return [];

    Object.keys(frames).forEach((key) => {
      frames[key] = (frames[key] || []);
    });

    return Object.keys(frames).reduce((reduce, frame) => {
        const currentTime = Number(frame) / Number(media.framerate);

        frames[frame].forEach((products) => {
        if (Array.isArray(products)) {
          reduce.push(products.map(({ productId, main, mainLookImage }) => ({
            ...items[productId],
            productId,
            main,
            mainLookImage,
            frame,
            currentTime
          })));
        } else {
          const { productId } = products;
          const last = Array.from(reduce).reverse().find((r) => r.productId === productId);

          if (!last || (currentTime - last.currentTime) >= TIME_WINDOW) {
              reduce.push({
                  ...items[productId],
                  productId,
                  frame,
                  currentTime
              });
          }
        }
      });

      return reduce;
    }, []);
  }

  saveProducts = () => {
    const { group } = this.state;
    const { updateGroupMutation } = this.props;

    this.setState({
      isLoadingProducts: true
    });

    updateGroupMutation({
      variables: {
        id: group.id,
        vertical: group.vertical,
        products: group.products.map(product => ({
          description: product.description,
          images: product.images,
          id: product.productId || product.id,
          title: product.title,
          sku: product.sku,
          price: product.price,
        })),
      },
    }).then(() => {
      this.props.groupQuery.refetch();
      this.setState({
        isLoadingProducts: false
      });
    }).catch((e) => {
      this.setState({
        isLoadingProducts: false
      });
    });
  }

  setProductsFromJSON = async (asset) => {
    const products = this.getProducts(asset);
    const { company, name } = asset;

    this.setState({
      isLoadingProducts: false,
      products: products.map((product) => {
        if (Array.isArray(product)) {
          return product.map(p => ({
            miniClipUrl: generateMiniClipUrl({ company, name, productId: p.id }),
            ...p
          }));
        } else {
          return {
            miniClipUrl: generateMiniClipUrl({ company, name, productId: product.id }),
            ...product
          };
        }
      })
    });
  }

  handleAddProduct = ({ product, time }) => {
    this._addProduct({ product, time });

    this.saveProducts();
  }

  _addProduct = ({ product }) => {
    const { group } = this.state;

    group.products = group.products || [];
    group.products = [...group.products, product];

    this.setState({
      group,
    });
  }

  handleEditProductVideo = ({ newProduct, time, previousProduct }) => {
    this._removeProduct(previousProduct);

    this._addProduct({ product: newProduct, time });

    this.saveProducts();
  }

  handleAddProducts = ({ look, time }) => {
    this._addLook({ look, time });

    this.saveProducts();
  }

  handleEditProducts = ({ newLook, time, previousLook, mainLookImage}) => {
    if (previousLook.length > 1) {
      this._removeLook(previousLook);
    } else {
      this._removeProduct(previousLook[0]);
    }

    this._addLook({ look: newLook, time, mainLookImage });

    this.saveProducts();
  }

  _addLook = ({ look, time, mainLookImage }) => {
    const { asset } = this.state;

    const { media: { framerate = 0 } } = asset;
    const frameKey = Math.round(framerate * time);

    if (!asset.items) {
      asset.items = {};
    }

    if (!asset.frames) {
      asset.frames = {};
    }

    if (!asset.frames[frameKey]) {
      asset.frames[frameKey] = [];
    }

    if (look.length > 1) {
      look.forEach((product) => {
        asset.items[product.productId] = {
          description: product.description,
          id: product.productId,
          images: product.images,
          title: product.title,
          price: product.price,
          sku: product.sku
        };
      });
  
      asset.frames[frameKey].push(look.map(product => ({
        productId: product.productId,
        main: product.main,
        mainLookImage: product.main ? mainLookImage : undefined
      })));
    } else {
      asset.items[look[0].productId] = {
        description: look[0].description,
        id: look[0].productId,
        images: look[0].images,
        title: look[0].title,
        price: look[0].price,
        sku: look[0].sku
      }

      asset.frames[frameKey].push({
        productId: look[0].productId
      });
    }

    this.setState({
      asset,
      products: this.getProducts(asset),
      hasChanged: true
    });
  }

  handleClickDeleteLook = (products) => {
    this.setState({
      lookToRemove: products,
      isConfirmDeleteLookOpened: true
    })
  }

  handleClickNoConfirmDeleteLook = () => {
    this.setState({
      isConfirmDeleteLookOpened: false
    });
  }
  
  onDeleteLook = () => {
    this.setState({
      isConfirmDeleteLookOpened: false
    });
    this._removeLook(this.state.lookToRemove);
    this.saveProducts();
  }

  _removeLook = (products) => {
    const { asset } = this.state;

    if (asset) {
      const frame = products[0].frame;

      asset.frames[frame] = asset.frames[frame].filter((product) => {
        const isLook = Array.isArray(product);
        if (!isLook) return true;
        if (product.length !== products.length) return true;

        return (product.map(({ productId }) => productId).join() !== products.map(({ productId }) => productId).join());
      });

      if (!asset.frames[frame].length) {
        delete asset.frames[frame];
      }

      this.setProductsFromJSON(asset);
    }
  }

  getLastProductTime = () => {
    const { products, asset } = this.state;
    const { media } = asset;

    if (!this.state.products || !this.state.products.length) return;

    const lastProduct = products[products.length - 1];
    const frame = Array.isArray(lastProduct) ? lastProduct[0].frame : lastProduct.frame;

    return frame / media.framerate;
  }

  handleChangeProductPosition = (actualPosition, newPosition) => {
    const { group } = this.state;
    const { products } = group;

    const max = products.length;
    const newProducts = array_move(products, actualPosition, ((newPosition % max) + max) % max);

    this.setState({ group: { ...group, products: newProducts } }, () => {
      this.saveProducts();
    });

    // modify
    function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    };
  }

  render() {
    if (!this.state || !this.state.group) {
      return null;
    }

    const {
      group,
      isLoadingProducts,
      isLinkCopied,
      assets,
      previewUrl,
      previewUrlDashboard,
      companyDomain,
      isLoadingApproveOrDecline,
      isConfirmDeleteProductOpened,
    } = this.state;

    const deleteModalConfirm = (
      <ModalConfirm text='Are you sure you want to delete product from this asset? This action can not be undone.'
                    title='Delete product from asset'
                    yesText='Yes, delete it'
                    noText={`No, keep it`}
                    onClickNo={this.handleClickNoConfirmDeleteProduct}
                    onClickYes={this.onDeleteProduct}
                    open={isConfirmDeleteProductOpened}/>
    );

    return (
      <React.Fragment>
        {deleteModalConfirm}
        <GroupDetail 
          asset={group}
          products={group.products || []}
          isLoadingProducts={isLoadingProducts}
          onClickCopyLink={this.handleClickCopyLink}
          isLinkCopied={isLinkCopied}
          afterCloseAssetEdit={this.afterCloseAssetEdit}
          onLoadedMiniClip={this.handleLoadedMiniClip}
          afterCloseAssetPreview={this.afterCloseAssetPreview}
          onClickProductLink={this.handleClickProductLink}
          onClickGetEmbbedCode={this.handleClickGetEmbbedCode}
          assets={assets}
          afterCloseAssetEditShoppableImage={this.afterCloseAssetEditShoppableImage}
          previewUrl={previewUrl}
          previewUrlDashboard={previewUrlDashboard}
          onClickAssetDetails={this.handleClickAssetDetails}
          companyDomain={companyDomain}
          onClickTreeAssets={this.handleClickTreeAssets}
          onAfterEditGeneralInformation={this.handleAfterEditGeneralInformation}
          onClickGetLink={this.handleClickGetLink}
          onClickApprove={this.handleClickApprove}
          onClickDecline={this.handleClickDecline}
          isLoadingApproveOrDecline={isLoadingApproveOrDecline}
          afterPublishYoutubeVideo={this.handleAfterPublishYoutubeVideo}
          onAfterChooseOrder={this.handleAfterChooseOrder}
          onChangeJSONFile={this.handleChangeJSONFile}
          onClickGenerateMiniClips={this.handleClickGenerateMiniClips}
          onClickRemoveMiniclip={this.handleClickRemoveMiniClip}
          afterPublishFacebookVideo={this.handleAfterPublishFacebookVideo}
          onClickDeleteProduct={this.handleClickDeleteProduct}
          onEditProduct={this.handleEditProductVideo}
          onAddProduct={this.handleAddProduct}
          onAddProducts={this.handleAddProducts}
          onClickDeleteLook={this.handleClickDeleteLook}
          onEditProducts={this.handleEditProducts}
          onChangeProductPosition={this.handleChangeProductPosition}
        />
      </React.Fragment>
    );
  }
}

const GroupQuery = gql`
  query group($id: String!, $vertical: String!) {
    group(id: $id, vertical: $vertical) {
      id,
      title,
      vertical,
      company,
      createdAt,
      products {
        id,
        title,
        url,
        images
      }
    }
  }
`;

const UpdateGroupMutation = gql`
 mutation updateGroup($id: String!, $vertical: String!, $products: [IGroupProductConfig]!) {
    updateGroup(id: $id, vertical: $vertical, products: $products) {
      id
    }
 }
`;

const assetGqls = compose(
  graphql(GroupQuery, {
    name: 'groupQuery',
    options: ({ match }) => {
      return ({
        variables: {
          vertical: match.params.verticalId,
          id: match.params.id
        },
        fetchPolicy: 'network-only'
      })
    },
  }),
  graphql(UpdateGroupMutation, {
    name: 'updateGroupMutation'
  })
)

export default assetGqls(GroupDetailContainer);
