import gql from 'graphql-tag';
import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { generateFileUrl } from '../../models/Asset';
import ModalAssetSynchronizeToYoutube from '../../components/ModalAssetSynchronizeToYoutube';

class AssetSynchronizeToYoutubeContainer extends Component {
  state = {};

  async componentDidMount() {
    const { asset } = this.props;
    const { company, name, type, title, description } = asset;

    this.setState({
      isLoadingDescription: true
    });

    this.setState({
      title,
      description: description,
      isLoadingDescription: false,
      videoUrl: generateFileUrl({
        company,
        name,
        type: type.value
      })
    });
  }

  onPublishYoutube = () => {
    this.setState({
      isLoadingPublishYoutube: true
    });

    const { asset } = this.props;
    const { company, name, youtubeId } = asset;
    const { title, description } = this.state;

    this.props.publishYoutubeAssetMutation({
      variables: {
        name,
        company,
        title,
        description,
        youtubeId
      }
    })
      .then((resp) => {
        this.props.onClose(true);
      })
      .catch(({ graphQLErrors }) => {
        if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.code && graphQLErrors[0].extensions.code === 1001) {
          this.setState({
            askGoogleCredentials: true
          });
        }

        this.setState({
          isLoadingPublishYoutube: false
        });
      })
  }

  onErrorGoogleAccountInfo = ({ error }) => {
    if (error === 'popup_blocked_by_user') {
      // to-do feedback to allow browser to show popups on this site
    }
  }

  onSuccessGoogleAccountInfo = (googleResp = {}) => {
    this.props.updateGoogleAccount({
      variables: {
        name: localStorage.getItem('company'),
        account: googleResp.code ? undefined : JSON.stringify(googleResp),
        code: googleResp.code
      }
    }).then((resp) => {
      this.onPublishYoutube();
    });
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value
    });
  }

  render() {
    const { open, onClose, asset } = this.props;
    const { isLoadingPublishYoutube, title, description, isLoadingDescription, videoUrl, askGoogleCredentials } = this.state;

    const { youtubeId } = asset;

    return (
      <ModalAssetSynchronizeToYoutube open={open}
                                      onClose={onClose}
                                      onErrorGoogleAccountInfo={this.onErrorGoogleAccountInfo}
                                      onSuccessGoogleAccountInfo={this.onSuccessGoogleAccountInfo}
                                      isLoadingPublishYoutube={isLoadingPublishYoutube}
                                      onChange={this.handleChange}
                                      title={title}
                                      description={description}
                                      isLoadingGeneral={isLoadingDescription}
                                      videoUrl={videoUrl}
                                      hideTitle={Boolean(youtubeId)}
                                      onClickPublishYoutube={youtubeId ? undefined : this.onPublishYoutube}
                                      onUpdateYoutube={youtubeId ? this.onPublishYoutube : undefined}
                                      askGoogleCredentials={askGoogleCredentials} />
      );
  }
}

const PublishYoutubeAssetMutation = gql`
  mutation publishYoutubeAsset($name: String!, $company: String!, $title: String!, $description: String!, $youtubeId: String){
    publishYoutubeAsset(name: $name, company: $company, title: $title, description: $description, youtubeId: $youtubeId) {
      name
      title
      company
      preview
      description
      thumbnail
      status
      type
      filePath
      location
      jsonLocation
      assets
      youtubeId
      products {
        url
      }
      createdAt
    }
  }
`;

const UpdateGoogleAccount = gql`
  mutation updateGoogleAccount ($name: String!, $account: String, $code: String){
    updateGoogleAccount(name: $name, account: $account, code: $code) {
      name
    }
  }
`;

const assetGqls = compose(
  graphql(PublishYoutubeAssetMutation, {
    name: 'publishYoutubeAssetMutation'
  }),
  graphql(UpdateGoogleAccount, {
    name: 'updateGoogleAccount'
  }),
)

export default assetGqls(AssetSynchronizeToYoutubeContainer);