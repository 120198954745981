import gql from 'graphql-tag';
import React, { Component } from 'react';
import { graphql } from 'react-apollo';
import { Redirect } from 'react-router';

import CompanyService from '../../tools/company';

class CompanyLoadContainer extends Component {
  render() {
    if (this.props.data && this.props.data.loading) {
      return null;
    }

    const { redirectTo } = this.props;

    if (this.props.data && this.props.data.companies && this.props.data.companies.length) {
      const company = this.props.data.companies[0];
      localStorage.setItem('company', company.name);

      CompanyService.set(company);

      return (
        <Redirect to={redirectTo} />
      );

    } else {
      return (
        <Redirect to='/login' />
      );
    }
  }
}

const companyQuery = gql`
  query companies {
    companies {
      name
      domain
      webpage
      store {
        type
      }
      users
      scopes {
        disableProcessOnUplod,
        disableAnalytics
      }
    }
  }
`;

const withCompany = graphql(companyQuery, {
  options: {
    fetchPolicy: 'network-only'
  }
});

export default withCompany(CompanyLoadContainer);