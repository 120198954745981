import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import clipboardCopy from 'clipboard-copy';
import React, { Component, Fragment } from 'react';
import CompanyService from '../../tools/company';
import Settings from '../../components/Settings';
import ModalDisconnect from '../../components/ModalDisconnect';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import urljoin from 'url-join';

export const SETTINGS_TABS = {
  GENERAL: 'general',
  THEME: 'theme',
  SECUTIRY: 'secutiry'
};

const {
  REACT_APP_API_URL
} = process.env;

const plarforms = [{
  text: 'Quote (default version)',
  value: 'quote'
}, {
  text: 'Shopify',
  value: 'shopifyweb'
}, {
  text: 'Others',
  value: 'database'
}];


class ProfileContainer extends Component {
  state = {
    activeTab: SETTINGS_TABS.GENERAL,
    saved: false,
    loading: false,
    company: {}
  };

  handleClickConnectToShopify = () => {
    const { domain, name } = this.state.company;
    const shop = domain.replace(/https?:\/\//, '').split('/')[0];
    window.location.href = `${REACT_APP_API_URL}/connectors/shopifyweb/auth?shop=${shop}&company=${name}&redirectUrl=${encodeURIComponent(window.location.href)}`;
  }

  handleClickCopyConnectShopify = async () => {
    const { name } = this.state.company;

    await this.handleSave();

    await clipboardCopy(urljoin(REACT_APP_API_URL, '/shopifyapp/authorize/', name));
  }

  handleChange = (event, { name, value }) => {
    this.setState({
      company: {
        ...this.state.company,
        [name]: value
      }
    });
  }

  handleSave = () => {
    const { updateCompanyMutation, companyQuery } = this.props;
    const { company } = this.state;
    const name = company.name;
    const _company = Object.assign({}, this.state.company);

    delete _company.name;
    delete _company.store;
    delete _company.__typename;

    this.setState({ loading: true });

    return updateCompanyMutation({
      variables: {
        name,
        company: _company
      }
    })
    .then(() => {
      this.setState({
        saved: true,
        loading: false
      });
      companyQuery.refetch();
      this.timeoutSaved = setTimeout(() => {
        this.setState({ saved: false });
      }, 2000);
    });
  }

  handleClickDisconnect = () => {
    this.setState({
      openDisconnectConfirm: true
    });
  }

  handleCloseConfirm = () => {
    this.setState({
      openDisconnectConfirm: false
    });
  }

  handleConfirmDisconnect = () => {
    const state = {
      openDisconnectConfirm: false,
      company: {
        ...this.state.company,
        ecommercePlatform: 'quote'
      }
    };
    this.setState(state);
  }

  handleClickSetActiveTab = (activeTab) => {
    this.setState({ activeTab });
  }

  componentWillReceiveProps = (props) => {
    const { companyQuery } = this.props;

    if (!_isEqual(companyQuery.company, props.companyQuery.company)) {
      const _company = { ...props.companyQuery.company };

      if (!_company.theme) {
        Object.assign(_company, {
          theme: this.generateColorsFromMainColor({ r: 183, g: 7, b: 46, a: 1 })
        });
      }

      this.setState({
        company: _company
      });
    }
  }

  componentDidMount () {
    const { companyQuery } = this.props;

    if (companyQuery.company) {
      this.setState({
        company: companyQuery.company
      });
    }
  }

  componentWillUnmount () {
    clearTimeout(this.timeoutSaved);
  }

  generateColorsFromMainColor = (mainColor) => {
    const { r, g, b, a } = mainColor;

    const isLight = this.isLightColor(mainColor);

    return Object.assign({
      '--main': `rgba(${r},${g},${b},${a})`,
      '--primary': `rgba(${r},${g},${b},0.00)`,
      '--secondary': `rgba(${r},${g},${b},0.80)`,
      '--track-border': `rgba(${r},${g},${b},${a})`,
      '--track-color': `rgba(${r},${g},${b},${a})`,
      '--product-primary': `rgba(${r},${g},${b},0.60)`,
      '--product-secondary': `rgba(${r},${g},${b},0.20)`,
      '--track-border-active': isLight ? `rgba(0, 0, 0, 1)` : `rgba(255, 255, 255, 1)`,
      '--track-color-active': `rgba(${r},${g},${b},${a})`
    }, (this.isDarkLoader() ? this.generateDarkLoaderColors() : this.generateLightLoaderColors(mainColor)));
  }

  isLightColor = (mainColor) => {
    if (!mainColor) return;

    let _mainColor;

    if (typeof mainColor === 'string') {
      const rgbaColors = mainColor.match(/^rgba\((.+)\)/)[1];

      const [r, g, b, a] = rgbaColors.split(',');
      _mainColor = { r, g, b, a };
    } else {
      _mainColor = mainColor;
    }
    const { r, g, b, a } = _mainColor;

    const isLight = ((r*0.299 + g*0.587 + b*0.114) > 186);
    
    return isLight;
  }

  isDarkLoader = () => {
    return this.state.company && this.state.company.theme && this.state.company.theme['--loader-color'] !== this.state.company.theme['--main'];
  }

  generateLightLoaderColors = (mainColor) => {
    let loaderColor;
    if (mainColor) {
      const { r, g, b, a } = mainColor;
      loaderColor = `rgba(${r},${g},${b},${a})`
    } else {
      loaderColor = this.state.company.theme['--main'];
    }

    return {
      '--loader-background': 'rgba(255, 255, 255, 1)',
      '--loader-color': loaderColor
    }
  }

  generateDarkLoaderColors = () => {
    return {
      '--loader-color': 'rgba(255, 255, 255, 1',
      '--loader-background': `rgba(0, 0, 0, 1)`
    }
  }

  handleChangeTheme = (mainColor) => {
    const { company } = this.state;

    this.setState({
      company: {
        ...company,
        theme: this.generateColorsFromMainColor(mainColor.rgb)
      }
    })
  }

  handleChangeLoader = (style) => {
    const { company } = this.state;
    const { theme } = company;

    this.setState({
      company: {
        ...company,
        theme: {
          ...theme,
          ...(style === 'dark' ? this.generateDarkLoaderColors() : this.generateLightLoaderColors())
        }
      }
    });
  }

  render() {
    const { companyQuery } = this.props;
    const {
      saved,
      loading,
      company,
      activeTab,
      openDisconnectConfirm
    } = this.state;

    return (
      <Fragment>
        <ModalDisconnect
          open={openDisconnectConfirm}
          onConfirm={this.handleConfirmDisconnect}
          onClose={this.handleCloseConfirm}
        />
        <Settings
          activeTab={activeTab}
          onClickSetActiveTab={this.handleClickSetActiveTab}
          company={company}
          saved={saved}
          loading={loading || companyQuery.loading}
          onChange={this.handleChange}
          platforms={plarforms}
          onClickSave={this.handleSave}
          onClickDisconnect={this.handleClickDisconnect}
          onClickConnectShopify={this.handleClickConnectToShopify}
          onClickCopyConnectShopify={this.handleClickCopyConnectShopify}
          onChangeTheme={this.handleChangeTheme}
          onChangeLoader={this.handleChangeLoader}
          isDarkLoader={this.isDarkLoader()}
          isLightColor={this.isLightColor(_get(company, 'theme.--main'))}
        />
      </Fragment>
    )
  }
}

const CompanyQuery = gql`
  query company($name: String!){
    company(name: $name) {
      name
      webpage
      domain
      ecommercePlatform
      bitlyApiToken
      logoWhite
      logoBlack
      store {
        type
        config
      }
      theme
    }
  }
`;

const CompanyUpdateMutation = gql`
  mutation updateCompany($name: String!, $company: ICompany) {
    updateCompany(name: $name, company: $company) {
      name
      webpage
      domain
      ecommercePlatform
      bitlyApiToken
      logoWhite
      logoBlack
      theme
    }
  }
`;

const assetGqls = compose(
  graphql(CompanyQuery, {
    name: 'companyQuery',
    options: () => {
      const { name } = CompanyService.get();
      return ({
        variables: { name },
        fetchPolicy: 'network-only'
      });
    }
  }),
  graphql(CompanyUpdateMutation, {
    name: 'updateCompanyMutation'
  })
)

export default assetGqls(ProfileContainer);
