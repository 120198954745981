import gql from 'graphql-tag';
import React, { Component, Fragment } from 'react';
import { compose, graphql } from 'react-apollo';

import QuoteModal from '../../components/QuoteModal';
import QuoteList from '../../components/QuoteList';

class QuoteListContainer extends Component {
  state = {
    quote: null
  }

  componentDidMount() {
    this.props.setActiveModuleTitle('Quotes');
  }

  handleClickQuote = (quote) => {
    this.setState({ quote });
  }

  onCheck = (quote) => {
    this.props.quotesCheckedMutation({
      variables: {
        id: quote.id,
        company: localStorage.getItem('company')
      }
    })
    .then(() => {
      this.setState({ quote: null });
      this.props.quoteQuery.refetch();
    });
  }

  onClose = () => {
    this.setState({
      quote: null
    });
  }

  render() {
    const { quoteQuery } = this.props;
    const { quote } = this.state;

    return (
      <Fragment>
        <QuoteModal
          open={!!quote}
          onCheck={this.onCheck}
          onClose={this.onClose}
          quote={quote}
        />
        <QuoteList
          onClickQuote={this.handleClickQuote}
          quotes={(quoteQuery.quotes) || []}
        />
      </Fragment>
    )
  }
}

const QuoteQuery = gql`
  query quotes($company: String!){
    quotes(company: $company)  {
      id
      data
      cart {
        quantity
        product {
          title
          sku
          id
        }
      }
      check
      createdAt
    }
  }
`;
const QuotesCheckedMutation = gql`
  mutation quoteCheck($id: String!, $company: String!) {
    quoteCheck(
      id: $id
      company: $company
    ) {
      id
      data
      cart {
        quantity
        product {
          title
          sku
          id
        }
      }
      check
      createdAt
    }
  }
`;



const quoteGqls = compose(
  graphql(QuotesCheckedMutation, {
    name: 'quotesCheckedMutation'
  }),
  graphql(QuoteQuery, {
    name: 'quoteQuery',
    options: () => {
      return ({
        variables: {
          company: localStorage.getItem('company')
        },
        fetchPolicy: 'network-only'
      });
    }
  })
)

export default quoteGqls(QuoteListContainer);
