import './style.css';

import React, {Component, Fragment} from 'react';
import {Redirect} from 'react-router';
import {Card, Button, Input, Popup, } from 'semantic-ui-react';
import {Add, DeleteOutline } from '@material-ui/icons';
import Moment from 'react-moment';
import AssetCreate from '../../containers/AssetCreate';
import ModalConfirm from '../ModalConfirm';
import ListSortableHeader from '../List/sortable-header';
import ModalCreateGroup from "../ModalCreateGroup";

export default class AssetList extends Component {
  state = {}

  handleCloseCreateAsset = (options) => {
    this.setState({
      isCreateAssetOpened: false
    });

    if (options && options.savedPlaylistOrSlideshow) {
      this.props.afterCreatePlaylistOrSlideshow();
    }
  }

  onClickOpenConfirmDelete = () => {
    this.setState({isDeleteConfirmOpened: true});
  }

  onClickCloseConfirmDelete = () => {
    this.setState({isDeleteConfirmOpened: false});
  }

  deleteCheckedAssets = () => {
    this.props.deleteCheckedAssets();
    this.setState({isDeleteConfirmOpened: false});
  }

  handleOpenCreateSlideshowPlaylist = ({type}) => {
    this.setState({
      isCreateSlideshowPlaylist: true,
      createSlideshowPlaylistType: type
    });
  }

  handleCreateGroup = () => {
    this.setState({isCreateGroup: true});
  }

  handleCloseCreateGroup = () => {
    this.setState({isCreateGroup: false});
    this.props.onRefetchGroup();
  }

  handleCloseCreateSlideshowPlaylist = () => {
    this.setState({
      isCreateSlideshowPlaylist: false,
      createSlideshowPlaylistType: undefined
    });
  }

  render() {
    const {
      groups,
      redirectTo,
      onClickDetails,
      orderBy,
      onChangeOrder,
    } = this.props;
    const {
      isCreateAssetOpened,
      createType,
      createFrom,
      isDeleteConfirmOpened,
      createSlideshowPlaylistType,
      assetsToGroup,
      isCreateGroup
    } = this.state;

    if (redirectTo) {
      return <Redirect to={{pathname: redirectTo}} push={true}/>
    }

    const deleteModalConfirm = (
      <ModalConfirm text='Are you sure you want to delete these assets? This action can not be undone.'
                    title='Delete assets'
                    yesText='Yes, delete the assets'
                    noText={`No, keep the assets`}
                    onClickNo={this.onClickCloseConfirmDelete}
                    onClickYes={this.deleteCheckedAssets}
                    open={isDeleteConfirmOpened}/>
    );

    const assetList = groups.map(group => {
      return (
        <Fragment key={`${group.company}-${group.createdAt}`}>
          <div className='AssetList__Content__Asset'>
            <div className='AssetList__Header__Column AssetList__Header__ColumnName body'>
              {group.title}
            </div>
            <div className='AssetList__Header__Column AssetList__Header__ColumnCreatedAt caption'>
              <Moment format="MMM DD, YYYY hh:mma">{group.createdAt}</Moment>
            </div>
            <div className='AssetList__Header__Column AssetList__Header__ColumnActions overline-bold'>
              <Button onClick={onClickDetails(group)}
                      className='AssetList__Header__ColumnActions__Detail'>
                <div className='body-bold'>Details</div>
              </Button>
            </div>
          </div>
          <div className='HorizontalDivider'/>
        </Fragment>
      );
    });

    return (
      <div className='AssetListPage'>
        {isCreateAssetOpened ?
          <AssetCreate onClose={this.handleCloseCreateAsset}
                       open={isCreateAssetOpened}
                       type={createType}
                       from={createFrom}
                       assets={assetsToGroup}/>
          : null}
        {deleteModalConfirm}
        {isCreateGroup ?
          <ModalCreateGroup
            verticalId={this.props.verticalId}
            onClose={this.handleCloseCreateGroup}
            open={isCreateGroup}
            type={createSlideshowPlaylistType}
          />
          : null}
        <div className='cred heading-3'>
          Groups
        </div>
        <Card>
          <Card.Content>
            <div className='AssetListWrapper__Header'>

              <div className='AssetListWrapper__Header__CreateAssetWrapper'>
                <Button color='red'
                        className='IconButton'
                        onClick={this.handleCreateGroup}
                >
                  <Add fontSize='small'/>
                  <div className='body-bold'>Create Group</div>
                </Button>
              </div>
              <div className='AssetListWrapper__Header__RightActions'>
                {/* <Popup trigger={<Button disabled={!hasSelectedItem}
                                        onClick={this.onClickOpenConfirmDelete}>
                  <DeleteOutline fontSize='small'/>
                </Button>}
                       content='Delete'
                       hideOnScroll/>
                <Input placeholder='Search'
                       name='search'
                       icon='search'
                       onChange={onChangeSearch}/> */}
              </div>
            </div>
            <div className='AssetList'>
              <div className='AssetList__Header'>
                <div className='AssetList__Header__Column AssetList__Header__ColumnName overline-bold'>
                  <ListSortableHeader text='NAME'
                                      fieldName='title'
                                      orderBy={orderBy}
                                      onChangeOrder={onChangeOrder}/>
                </div>
                <div className='AssetList__Header__Column AssetList__Header__ColumnCreatedAt overline-bold'>
                  <ListSortableHeader text='CREATED AT'
                                      fieldName='createdAt'
                                      orderBy={orderBy}
                                      onChangeOrder={onChangeOrder}/>
                </div>
                <div className='AssetList__Header__Column AssetList__Header__ColumnActions overline-bold'>
                  &nbsp;
                </div>
              </div>
              <div className='AssetList__Content'>
                {assetList}
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    );
  }
}
