import React, { Component, Fragment } from 'react';


export default class TextInfo extends Component {

	render(){
		return (
			<Fragment>
				<p className='cgrey body-bold'>{this.props.title} : {this.props.value}</p>
			</Fragment>
		);
	}
}
