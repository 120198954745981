import React, { Component } from 'react';
import { generateFileUrl } from '../../models/Asset';
import ModalAssetSynchronizeToYoutube from '../../components/ModalAssetSynchronizeToYoutube';
import clipboardCopy from 'clipboard-copy';

class AssetDescriptionContainer extends Component {
  state = {};

  async componentDidMount() {
    const { asset } = this.props;
    const { company, name, type, description } = asset;

    this.setState({
      isLoadingDescription: true
    });

    this.setState({
      description: description,
      isLoadingDescription: false,
      videoUrl: generateFileUrl({
        company,
        name,
        type: type.value
      })
    });
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value
    });
  }

  handleCopy = () => {
    clipboardCopy(this.state.description);
    this.setState({
      isCopied: true
    });

    setTimeout(() => {
      this.setState({
        isCopied: false
      });
    }, 2000);
  }

  render() {
    const { open, onClose } = this.props;
    const { description, isLoadingDescription, videoUrl, isCopied } = this.state;

    return (
      <ModalAssetSynchronizeToYoutube open={open}
                                      onClose={onClose}
                                      onChange={this.handleChange}
                                      description={description}
                                      isLoadingGeneral={isLoadingDescription}
                                      videoUrl={videoUrl}
                                      onClickCopy={this.handleCopy}
                                      isCopied={isCopied}
                                      hideTitle={true} />
      );
  }
}

export default AssetDescriptionContainer;
