import './style.css';

import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';

export default class ModalConfirm extends Component {
  render() {
    const { onClickNo, onClickYes, open, text, title, yesText, noText } = this.props;

    return (
      <Modal  size='tiny'
              open={open}
              onClose={onClickNo}
              className='ModalConfirm'
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='heading-3 cblack ModalConfirm__ConfirmActionsTitle'>{title}</div>
          <div className='body cblack'>{text}</div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClickNo} >
              <div className='body-bold'>{noText}</div>
          </Button>
          <Button color='red'
                  onClick={() => onClickYes()} >
              <div className='body-bold'>{yesText}</div>
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}