import './style.css';

import _get from 'lodash/get';
import React, {Component, Fragment} from 'react';
import Moment from 'react-moment';
import { Card } from 'semantic-ui-react';



export default class QuoteList extends Component {
  onClickQuote = ( quote ) => {
    this.props.onClickQuote( quote );
  }

  render() {
    const { quotes } = this.props;

    const quoteList = quotes.map(quote => (
      <Fragment key={quote.id}>
        <div  className='QuoteList__Content__Quote'
              onClick={() => this.onClickQuote(quote)}>
          <div className={`QuoteList__Header__Column QuoteList__Header__ColumnQuantity
                            ${quote.check ? 'body' : 'body-bold'}`}>
            { _get(quote, 'cart.length', 0) }
          </div>
          <div className={`QuoteList__Header__Column QuoteList__Header__ColumnEmail
                            ${quote.check ? 'body' : 'body-bold'}`}>
            { _get(quote, 'data.email') }
          </div>
          <div className={`QuoteList__Header__Column QuoteList__Header__ColumnRequested
                            ${quote.check ? 'body' : 'body-bold'}`}>
            { <Moment date={Number(quote.createdAt)} format="MMM DD, YYYY | hh:mma" /> }
          </div>
        </div>
        <div className='HorizontalDivider'>&nbsp;</div>
      </Fragment>
    ));

    return (
      <div className='QuoteListPage'>
        <div className="QuoteListPage__Header cred heading-3">
          Quotes
        </div>
        <Card>
          <Card.Content>
            <div className='QuoteList'>
              <div className='QuoteList__Header'>
                <div className='QuoteList__Header__Column QuoteList__Header__ColumnQuantity overline-bold'>
                  ITEMS IN QUOTE
                </div>
                <div className='QuoteList__Header__Column QuoteList__Header__ColumnEmail overline-bold'>
                  CONSUMER EMAIL
                </div>
                <div className='QuoteList__Header__Column QuoteList__Header__ColumnRequested overline-bold'>
                  REQUESTED AT
                </div>
              </div>
              <div className='QuoteList__Content'>
                {quoteList}
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    );
  }
}
