import './style.css';

import React, { Component } from 'react';
import { mdiLogoutVariant, mdiSettings } from '@mdi/js'
import Icon from '@mdi/react'

import { isUserMaster } from '../../models/User';
import logoAcuity from '../../resources/acuity-bred-cwhite.png';

class AppMenu extends Component {
  render() {
    const {
      modules,
      activeModuleTitle,
      onClickModule,
      onClickRedirectToHome,
      onClickLogout,
      onClickSettings
    } = this.props;

    const modulesList = modules
      .filter(mod => !mod.master || isUserMaster())
      .filter(mod => mod.show)
      .map(mod => 
        <div className={`AppMenu__Modules__Module
                        ${mod.title === activeModuleTitle ? 'AppMenu__Modules__Module--active' : ''}`}
            key={mod.title}
            onClick={onClickModule(mod)}>
          { mod.icon() }
          <span className='AppMenu__Modules__Module__Title'>{mod.title}</span>
        </div>
      );

    return (
      <div className='AppMenu'>
        <div className='AppMenu__TopPart'>
          <div className='AppMenu__LogoWrapper'>
            <img  src={logoAcuity}
                  alt='AcuityAI'
                  onClick={onClickRedirectToHome}/>
          </div>
          <div className='AppMenu__Modules'>
            {modulesList}
          </div>
        </div>
        <div className='AppMenu__BottomPart'>
          <div className='HorizontalDivider' />
          <div
            className='AppMenu__BottomPart__Settings overline-bold'
            onClick={onClickSettings}>
            <Icon path={mdiSettings}
                  size={1}
                  horizontal
                  className='cgrey2' />
            Settings
          </div>
          <div  className='AppMenu__BottomPart__Logout overline-bold'
                onClick={onClickLogout}>
            <Icon path={mdiLogoutVariant}
                  size={1}
                  horizontal
                  className='cgrey2' />
            Logout
          </div>
        </div>
      </div>
    );
  }
}

export default AppMenu;