import './style.css';

import React, { Component, Fragment } from 'react';
import { Button, Modal } from 'semantic-ui-react';

export default class QuoteModal extends Component {
  constructor(props) {
    super(props);

    this.fileRef = React.createRef();
  }

  onCheck = (quote) => {
    this.props.onCheck(quote);
  }

  render() {
    const { open, quote, onClose } = this.props;

    return (
      <Modal open={open}
             size='small'
             className='QuotePreview'
             onClose={onClose}
             closeIcon
             closeOnEscape={false}
             closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='heading-3 cblack'>Quote Preview</div>
          <div className='QuotePreview__CustomerInfo'>
            {quote ? Object.keys(quote.data).map(key => (
              <div  key={key}
                    className='QuotePreview__CustomerInfo__Info body'>
                {key[0].toUpperCase() + key.slice(1)}:
                <div className='body-bold cgrey'>&nbsp;{quote.data[key]}</div>
              </div>
            )) :
            null
          }
          </div>
          <div className='QuotePreview__ProductList'>
            <div className='QuotePreview__ProductList__Header'>
              <div className='QuotePreview__ProductList__Header__Column overline-bold'>
                PRODUCT
              </div>
              <div className='QuotePreview__ProductList__Header__Column overline-bold'>
                SKU
              </div>
              <div className='QuotePreview__ProductList__Header__Column overline-bold'>
                QUANTITY
              </div>
            </div>
            <div className='QuotePreview__ProductList__Content'>
              { quote ?
                (quote.cart || []).map(cartItem => (
                <Fragment key={cartItem.product.id}>
                  <div className='QuotePreview__ProductList__Content__Product'>
                    <div className='QuotePreview__ProductList__Header__Column body'>
                      { cartItem.product.title }
                    </div>
                    <div className='QuotePreview__ProductList__Header__Column body'>
                      { cartItem.product.sku }
                    </div>
                    <div className='QuotePreview__ProductList__Header__Column body'>
                      { cartItem.quantity }
                    </div>
                  </div>
                  <div className='HorizontalDivider'>&nbsp;</div>
                </Fragment>
              )):
                null
              }
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red'
                  onClick={() => this.onCheck(quote)} >
            <div className='body-bold'>Tag as checked</div>
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
