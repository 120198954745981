import './style.css';

import React, { Component, Fragment } from 'react';
import { EnumAssetType } from '../../models/Asset';
import { Videocam, Panorama, PlaylistPlay, Collections } from '@material-ui/icons';

export default class StatusAsset extends Component {
  render() {
    const { type } = this.props;

    const isVideo = type === EnumAssetType.VIDEO;
    const isYoutubeVideo = type === EnumAssetType.YOUTUBE_VIDEO;
    const isSlideshow = type === EnumAssetType.SLIDESHOW;
    const isPlaylist = type === EnumAssetType.PLAYLIST;
    const isImage = type === EnumAssetType.IMAGE;
    const isImagePng = type === EnumAssetType.IMAGE_PNG;

    return (
      <div className='TypeAsset' >
        { isVideo || isYoutubeVideo ?
          <Fragment>
            <Videocam fontSize='small'
                      className='AssetDetailPage__GeneralContent__Type__Icon' /> 
            Video
          </Fragment>
        : null }
        { isImage || isImagePng ?
          <Fragment>
            <Panorama fontSize='small'
                      className='AssetDetailPage__GeneralContent__Type__Icon' /> 
            Image
          </Fragment>
        : null }
        { isPlaylist ?
          <Fragment>
            <PlaylistPlay fontSize='small'
                      className='AssetDetailPage__GeneralContent__Type__Icon' /> 
            Playlist
          </Fragment>
        : null}
        { isSlideshow ?
          <Fragment>
            <Collections fontSize='small'
                      className='AssetDetailPage__GeneralContent__Type__Icon' /> 
            Slideshow
          </Fragment>
        : null}
      </div>
    );
  }
}
