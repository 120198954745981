import './style.css';

import React, { Component } from 'react';
import { Modal, Button, Image } from 'semantic-ui-react';

export default class ModalAssetUpload extends Component {
  fileRef;

  handleClickChooseFile = () => {
    this.fileRef.click();
  }

  render() {
    const { file, open, onClose, onSelectFile, onClickNext, imageUrlFile, videoUrlFile, acceptedFileTypes, onClickUpload, loadingSave } = this.props;

    return (
      <Modal  size='small'
              open={open}
              onClose={() => onClose()}
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='heading-3 cblack'>Select from File</div>
          <div className='ModalAssetUpload__DropSpace cgrey body'>
            <input  type='file'
                    name='file'
                    onChange={onSelectFile}
                    className='ModalAssetUpload__DropSpace__FileInput'
                    ref={(ref) => this.fileRef = ref}
                    accept={acceptedFileTypes} />
            { imageUrlFile ?
              <Image  src={imageUrlFile}
                      className='ModalAssetUpload__DropSpace__Image' />
            : null }
            { videoUrlFile ?
              <video  controls
                      className='ModalAssetUpload__DropSpace__Video'>
                <source src={videoUrlFile}
                        type="video/mp4"/>
              </video>
            : null }
            { !videoUrlFile && !imageUrlFile ?
              <div>Drop a file from your computer here</div>
            : null }
          </div>
          <div className='ModalAssetUpload__ChooseFileWrapper'>
            <div className='cgrey body'>
              or select a file to upload
            </div>
            <Button className='ModalAssetUpload__ChooseFileWrapper__ButtonChooseFile'>
              <div  className='caption'
                    onClick={this.handleClickChooseFile}>Choose file</div>
            </Button>
          </div>
        </Modal.Content>
        <Modal.Actions>
          { onClickNext ? (
            <Button color='red'
                    onClick={onClickNext}
                    disabled={!Boolean(file)} >
                <div className='body-bold'>Next</div>
            </Button>
          ) : null }
          { onClickUpload ? (
            <Button color='red'
                    onClick={onClickUpload}
                    disabled={!Boolean(file)}
                    loading={loadingSave} >
                <div className='body-bold'>Upload</div>
            </Button>
          ) : null }
        </Modal.Actions>
      </Modal>
    )
  }
}