import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import Report from '../../components/Report';
import React, { Component, Fragment } from 'react';

class ReportContainer extends Component {
  render(){
    const { reportQuery, company, title, startDate, endDate } = this.props;

    return (
      <Report reportQuery = { reportQuery }
              company = { company }
              title = { title }
              startDate = { startDate }
              endDate = { endDate } />
    );
  }
}

const ReportQuery = gql`
  query report($company: String!, $name: String!, $startDate: String!, $endDate: String!) {
    report(company: $company, name: $name, startDate: $startDate, endDate: $endDate) {
      assetView
      assetViewByDay {
      	date
      	value
      }
      revenue
      revenueByDay {
      	date
      	value
      }
			kitData {
				label
				value
			}
			giftData {
				label
				value
			}
			supplyData {
				label
				value
			}
      checkoutByDay {
      	date
      	value
      }
      avgViewTime
      funnelData {
      	label
      	value
      }
      productLevel {
        hasData
      	maxClicks
      	minClicks
      	maxClicksImg
      	minClicksImg
        topFive {
          id
          label
          value
        }
      }
      emptyData
    }
  }
`;

const reportGqls = compose(
	graphql(ReportQuery, {
		name:'reportQuery',
		options: (props) => ({
	      variables: {
	      	company: props.company,
	      	name: props.name,
	        startDate: props.startDate,
	        endDate: props.endDate
	      },
	    })
	})
)

export default reportGqls(ReportContainer);
