import React from 'react';
import { ApolloProvider } from "react-apollo";
import { render } from "react-dom";
import './semantic/dist/semantic.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import client from './tools/graphql';

const ApolloApp = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

render(<ApolloApp />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();