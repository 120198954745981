import './style.css';

import React, { Component } from 'react';
import {
  Button, Divider, Form, Header, Message, Segment
} from 'semantic-ui-react';
import CompanyLoadContainer from '../../containers/CompanyLoad';
import logoAcuity from '../../resources/acuity-logo.svg';

export default class Sign extends Component {
  render() {
    // const title = this.props.in ? 'Be welcome' : 'Create account';
    const subtitle = `${this.props.in ? 'Sign in with your personal account' : 'Create your account'}`;

    const invalidCredentialsMessage = this.props.errorMessage ? 
      (<Message color='red'>{this.props.errorMessage}</Message>) :
      '';

    const redirectToHome = this.props.successLogin ? 
      (<CompanyLoadContainer redirectTo='/' />) :
      '';

    return (
      <div className='SignWrapper'>
        {redirectToHome}
        <div className='SignWrapper__Sign'>
          <div className='SignWrapper__Sign__Header'>
            <img  height={40}
                  src={logoAcuity}
                  alt='logo acuityai.video' />
          </div>
          <Segment  stacked
                    className='SignWrapper__Sign__Content'>
            <Header as='h4'
                    className='bold SignWrapper__Sign__Content__Text'>
              {subtitle}
            </Header>
            <Form className='SignWrapper__Sign__Content__Form'>
              { !this.props.in ? (
                <Form.Input fluid 
                            icon='building outline'
                            iconPosition='left'
                            placeholder='Company name'
                            name='companyName'
                            onChange={this.props.onChange} />
              ) : null}
              { !this.props.in ? (
                <Form.Input fluid 
                            icon='linkify'
                            iconPosition='left'
                            placeholder='Ecommerce web address'
                            name='domain'
                            onChange={this.props.onChange} />
              ) : null}
              <Form.Input fluid 
                          icon='user'
                          iconPosition='left'
                          placeholder='E-mail address'
                          name='email'
                          onChange={this.props.onChange} />
              <Form.Input fluid
                          icon='lock'
                          iconPosition='left'
                          placeholder='Password'
                          type='password'
                          name='password'
                          onChange={this.props.onChange} />
              {invalidCredentialsMessage}
              <Button color='red'
                      fluid
                      size='large'
                      onClick={this.props.onSubmit} >
                Proceed
              </Button>
            </Form>
            { this.props.in ? (
            <React.Fragment>
              <div className='SignWrapper__Sign__Content__DividerWrapper'>
                <div className='SignWrapper__Sign__Content__DividerWrapper__LeftDivider'>
                  <Divider/>
                </div>
                <div className='SignWrapper__Sign__Content__DividerWrapper__Text cgrey'>
                  OR
                </div>
                <div className='SignWrapper__Sign__Content__DividerWrapper__RightDivider'>
                  <Divider/>
                </div>
              </div>
              <Button color='red'
                      inverted
                      size='large'
                      onClick={this.props.onClickCreateAccount} >
                Create a new account
              </Button>
            </React.Fragment>
            ) : null}
          </Segment>
        </div>
      </div>
    );
  }
}