import gql from 'graphql-tag';
import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';

import ModalChooseOrder from '../../components/ModalChooseOrder';

class AssetGroupEditContainer extends Component {
  state = {}

  componentDidMount() {
    this.setState({
      assets: this.props.assets,
      assetsGrouped: this.props.assetsGrouped
    });
  }

  handleSave = () => {
    const { assetsGrouped } = this.state;
    const assets = assetsGrouped.reduce((prev, { set, items }) => [...prev, ...items.map(name => ({ set, name }))], []);
    const { asset } = this.props;
    const { type } = asset;

    this.setState({ isLoadingSave: true });

    this.props
      .assetEditGroupMutation({
        variables: {
          name: asset.name,
          company: asset.company,
          title: asset.title,
          assets,
          assetsType: type.value
        }
      })
      .then(() => this.props.onClose(true))
      .catch(() => this.setState({ isLoadingSave: false }));
  }

  handleSortEnd = ({ items, set }) => {
    const { assetsGrouped } = this.state;

    const _assetsGrouped = assetsGrouped.map((assetGrouped) => {
      if (set === assetGrouped.set) {
        assetGrouped.items = items;
      }

      return assetGrouped;
    });

    this.setState({
      assetsGrouped: _assetsGrouped
    });
  }

  handleRemove = ({ name, set }) => {
    const { assetsGrouped } = this.state;

    const _assetsGrouped = assetsGrouped.map((assetGrouped) => {
      if (set === assetGrouped.set) {
        const indexToRemove = assetGrouped.items.findIndex(assetName => assetName === name);
        assetGrouped.items.splice(indexToRemove, 1);
      }

      return assetGrouped;
    });

    this.setState({
      assetsGrouped: _assetsGrouped
    });
  }

  handleChangeSet = ({ value, groupI }) => {
    const _assetsGrouped = [...this.state.assetsGrouped];

    _assetsGrouped[groupI].set = value;

    this.setState({
      assetsGrouped: _assetsGrouped,
      showInputGroupI: undefined
    });
  }

  handleClickAddSet = () => {
    const _assetsGrouped = [...this.state.assetsGrouped];

    _assetsGrouped.push({
      set: `Set ${_assetsGrouped.length + 1}`,
      items: []
    });

    this.setState({
      assetsGrouped: _assetsGrouped
    })
  }

  handleClickShowInputGroup = (groupI) => {
    this.setState({
      showInputGroupI: groupI
    });
  }

  handleRemoveSet = (set) => {
    this.setState({
      assetsGrouped: [...this.state.assetsGrouped].filter((group) => group.set !== set)
    })
  }

  render() {
    const { onClose, open, asset } = this.props;
    const { type } = asset;
    const { assets, assetsGrouped, showInputGroupI, isLoadingSave } = this.state;

    return (
      <ModalChooseOrder onClose={onClose}
                        open={open}
                        type={type.value}
                        assets={assets}
                        assetsGrouped={assetsGrouped}
                        onClickSave={this.handleSave}
                        onSortEnd={this.handleSortEnd}
                        onRemove={this.handleRemove}
                        onChangeSet={this.handleChangeSet}
                        onClickAddSet={this.handleClickAddSet}
                        showInputGroupI={showInputGroupI}
                        onClickShowInputGroup={this.handleClickShowInputGroup}
                        isLoadingSave={isLoadingSave}
                        onRemoveSet={this.handleRemoveSet} />
    );
  }
}

const AssetEditGroupMutation = gql`
  mutation saveAsset($name: String, $title: String!, $company: String!, $assets: [ISubAsset], $assetsType: Int){
    asset(name: $name, title: $title, company: $company, assets: $assets, assetsType: $assetsType) {
      name
      title
      company
      preview
      description
      thumbnail
      status
      type
      filePath
      location
      jsonLocation
      assets {
        set
        name
      }
      createdAt
    }
  }
`;

const componentGql = compose(
  graphql(AssetEditGroupMutation, {
    name: 'assetEditGroupMutation',
    options: {
      fetchPolicy: 'no-cache'
    }
  })
);

export default componentGql(AssetGroupEditContainer);