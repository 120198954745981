import React, { Component } from 'react';
import { Loader, Image } from 'semantic-ui-react';

import { Funnel, ProductChart, TextInfo, TimeSeries, TimeSeries2 } from './Assets/index';

import './style.css';

export default class Report extends Component {
	formatDate(date) {
    const year = date.substring(0,4);
    const month = date.substring(5,7);
    const day = date.substring(8,10);

		return [month, day, year].join('/');
	}

	render() {
		const { reportQuery, startDate, endDate, company, title } = this.props;
		const allZero = arr => arr.every( v => v.value === 0 );
		const notProactiv = company !== 'proactiv';

		return (
			<div className="AssetAnalyticsPage__Report">
				<div className="AssetAnalticsPage__Report__Title">
					<p className="AssetAnalticsPage__Report__Title__Text heading-3 cgrey">Asset: { title }</p>
					<p className="AssetAnalticsPage__Report__Title__Text heading-3 cgrey">{this.formatDate(startDate)} <span>&#8594;</span> {this.formatDate(endDate)}</p>
				</div>
        {!reportQuery.loading ? (
          (!reportQuery.report || reportQuery.report.emptyData) ? (
            <div className="AssetAnalyticsPage__Report__EmptyData">
              <h2>You have no data for this asset</h2>
            </div>
          ) : (
            <div className="AssetAnalyticsPage__Report__Body ">
              <div className="AssetAnalyticsPage__Report__Description">
                <TextInfo title="Total Revenue" value={'$' + String(reportQuery.report.revenue.toFixed(2))}/>
                <TextInfo title="Total Views" value={reportQuery.report.assetView}/>
                {/*<TextInfo title="Avg. View Time (secs)" value={reportQuery.report.avgViewTime.toFixed(2)}/>*/}
              </div>
              <div className="Report__Diagrams">
                <div className="Report__Funnel">
                  <Funnel data={reportQuery.report.funnelData}/>
                </div>
                <div className={"Report__Proactiv "+ (company === 'proactiv' ? 'show' : 'hidden')}>
                  <div className={"Report__Proactiv__Kit"}>
                    <ProductChart
                      data={reportQuery.report.kitData}
                      label="Kit"
                    />
                  </div>
                  <div className={"Report__Proactiv__Gift"}>
                    <ProductChart
                      data={reportQuery.report.giftData}
                      label="Gift"
                    />
                  </div>
                  <div className={"Report__Proactiv__Supply"}>
                    <ProductChart
                      data={reportQuery.report.supplyData}
                      label="Supply"
                    />
                  </div>
                </div>
                <div className="Report__Info">
                  <div className="Report__Charts">
                    <ProductChart
                      data={ reportQuery.report.productLevel.topFive }
                      label="Top Items by Clicks"
                    />
                    {allZero(reportQuery.report.revenueByDay) ?
                      null:
                      <div className="Report__Charts__Revenue">
                        <TimeSeries
                          data={reportQuery.report.revenueByDay}
                          label="Revenue ($)"
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                    }
                    {allZero(reportQuery.report.checkoutByDay) ?
                      null
                      :
                      <div className="Report__Charts__Checkout">
                        <TimeSeries
                          data={reportQuery.report.checkoutByDay}
                          label="Conversion"
                          startDate={startDate}
                          endDate={endDate}
                        />
                      </div>
                    }
                    <div className="Report__Charts__AssetView">
                      <TimeSeries
                        data={reportQuery.report.assetViewByDay}
                        label="Asset Views"
                        startDate={startDate}
                        endDate={endDate}
                      />
                    </div>
                  </div>

                  <div className={"Report__Text__Info "+ ( (notProactiv) && (reportQuery.report.productLevel.hasData ) ? ' ' : 'hidden' ) }>
                    <TextInfo title="Most Clicked" value={ reportQuery.report.productLevel.maxClicks } textSize="1em" titleSize="1.5em" />
                    <Image
                      id="mostClicked"
                      src={reportQuery.report.productLevel.maxClicksImg}
                      style={{
                        maxHeight: 300,
                        maxWidth: 300
                      }}
                    />
                    <br />
                    <br />
                    <TextInfo title="Least Clicked" value={ reportQuery.report.productLevel.minClicks } textSize="1em" titleSize="1.5em" />
                    <Image
                      id="leastClicked"
                      src={ reportQuery.report.productLevel.minClicksImg }
                      style={{
                        maxHeight: 300,
                        maxWidth: 300
                      }}
                    />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="AssetAnalyticsPage__Report__Loading">
            <Loader active inline='centered' />
          </div>
        )}
			</div>
		);
	}
}
