import './style.css';

import React, { Component, Fragment } from 'react';
import { Form } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import Slider from 'rc-slider';

import { getPrettyTime } from '../../tools/format';

const TINY_ADJUST_TIME_SECONDS = 0.15;

export default class VideoTimeSelector extends Component {
  state = {};

  componentDidMount() {
    this.startCheckPlusMinusBoxPosition();
  }

  componentWillUnmount() {
    clearInterval(this.checkPlusMinusBoxPosition);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.productTime !== this.props.productTime) {
      this.setState({
        productTime: nextProps.productTime
      });
    }
  }

  startCheckPlusMinusBoxPosition = () => {
    setInterval(this.checkPlusMinusBoxPosition, 100);
  }

  checkPlusMinusBoxPosition = () => {
    const sliderHandle = document.getElementsByClassName('rc-slider-handle');

    if (sliderHandle && sliderHandle[0]) {
      this.setState({
        stylePlusMinusWrapper: {
          left: sliderHandle[0].style.left
        }
      });
    }
  }

  handleReady = () => {
    const duration = this.$player.getDuration();

    if (duration === this.state.duration) return;

    if (this.state.productTime) {
      this.$player.seekTo(this.state.productTime, 'seconds');
    }

    this.setState({
      playing: true,
      duration,
      time25Percent: duration * 0.25,
      time50Percent: duration * 0.5,
      time75Percent: duration * 0.75
    })
  }

  handlePlay = () => {
    if (this.state.playing) {
      this.setState({
        playing: false
      })
    }
  }

  handleProgress = (e) => {
    this.setState({
      productTime: e.playedSeconds,
      percentVideo: e.played,
      textProductTime: undefined
    });

    this.props.onChangeProductTime(e.playedSeconds);
  }

  handleSliderChange = (e) => {
    const percent = e / 100;

    this.setState({
      percentVideo: percent
    });

    this.$player.seekTo(percent);
  }

  handleChangeTextProductTime = (e, { value }) => {
    this.setState({
      textProductTime: value
    });

    if (value.length < 4 || value.length > 5) return;
    const [minutes, seconds] = value.split(':');
    if (seconds.length !== 2) return;
    
    const [_minutes, _seconds] = [minutes, seconds].map(v => parseInt(v));

    if (!Number.isInteger(_minutes) || !Number.isInteger(_seconds)) return;
    if (seconds > 59) return;

    const productTime = (minutes * 60) + seconds;

    if (productTime > this.state.duration) return;
    const percent = productTime / this.state.duration;

    this.setState({
      percentVideo: percent
    });

    this.$player.seekTo(productTime, 'seconds');
  }

  handleClickMinusProductTime = () => {
    if (this.state.productTime === 0) return;

    const newProductTime = this.state.productTime - TINY_ADJUST_TIME_SECONDS;

    this.$player.seekTo(newProductTime < 0 ? 0 : newProductTime, 'seconds');
  }

  handleClickPlusProductTime = () => {
    const { duration } = this.state;

    if (this.state.productTime === duration) return;

    const newProductTime = this.state.productTime + TINY_ADJUST_TIME_SECONDS;

    this.$player.seekTo(newProductTime > duration ? duration : newProductTime, 'seconds');
  }

  render() {
    const { youtubeId, fileUrl, className } = this.props;
    const { playing, percentVideo, textProductTime, duration, time25Percent, time50Percent, time75Percent, stylePlusMinusWrapper = {}, productTime } = this.state;

    return (
      <div className={`VideoTimeSelector ${className ? className : ''}`}>
        <div className='VideoTimeSelector__Selector'>
          <Form className='VideoTimeSelector__Selector__Form'>
            <div className='cgrey'>Show at time</div>
            <Form.Input fluid
                        value={textProductTime != undefined ? textProductTime : getPrettyTime(productTime)}
                        onChange={this.handleChangeTextProductTime}
                        className='VideoTimeSelector__Form__FieldWrapper__Field' />
          </Form>
          <div className='VideoTimeSelector__Selector__SliderWrapper'>
            <Slider
                onChange={this.handleSliderChange}
                defaultValue={0}
                value={percentVideo * 100}
                step={0.3}
                trackStyle={{
                    backgroundColor: '#adadad',
                    transition: 'width 0.1s linear',
                    height: 6
                }}
                handleStyle={{
                    height: 16,
                    width: 16,
                    borderWidth: 0,
                    backgroundColor: '#c11d42',
                    transition: 'left 0.1s linear'
                }}
            />
            { duration ? (
              <Fragment>
                <div  className='VideoTimeSelector__Selector__SliderWrapper__Minus'
                      onClick={this.handleClickMinusProductTime}>
                  -
                </div>
                <div  className='VideoTimeSelector__Selector__SliderWrapper__Plus'
                      onClick={this.handleClickPlusProductTime}>
                  +
                </div>
                {/* <div className='VideoTimeSelector__Selector__SliderWrapper__PlusMinusWrapper'
                      style={stylePlusMinusWrapper}>
                  <div  className='VideoTimeSelector__Selector__SliderWrapper__PlusMinusWrapper__Minus'
                        onClick={this.handleClickMinusProductTime}>
                    -
                  </div>
                  <div className='VideoTimeSelector__Selector__SliderWrapper__PlusMinusWrapper__Plus'
                        onClick={this.handleClickPlusProductTime}>
                    +
                  </div>
                </div> */}
                <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers'>
                  <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper overline'>
                    <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper__Pointer cgrey'>|</div>
                    <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper__Time'>{getPrettyTime(0)}</div>
                  </div>
                  <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper overline'>
                    <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper__Pointer cgrey'>|</div>
                    <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper__Time'>{getPrettyTime(time25Percent)}</div>
                  </div>
                  <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper overline'>
                    <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper__Pointer cgrey'>|</div>
                    <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper__Time'>{getPrettyTime(time50Percent)}</div>
                  </div>
                  <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper overline'>
                    <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper__Pointer cgrey'>|</div>
                    <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper__Time'>{getPrettyTime(time75Percent)}</div>
                  </div>
                  <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper overline'>
                    <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper__Pointer cgrey'>|</div>
                    <div className='VideoTimeSelector__Selector__SliderWrapper__TimeMarkers__MarkerWrapper__Time'>{getPrettyTime(duration)}</div>
                  </div>
                </div>
              </Fragment>
            ) : null }
          </div>
        </div>
        { fileUrl ? (
          <div className='VideoTimeSelector__VideoWrapper'>
            <ReactPlayer
              ref={($e) => (this.$player = $e)}
              config={{
                youtube: {
                  playerVars: {
                    showinfo: 0,
                    controls: 1,
                    title: 0,
                    modestbranding: 1,
                    rel: 0,
                    autohide: 1,
                    iv_load_policy: 3,
                    fullscreen: 0,
                    disablekb: 1,
                    autoplay: false
                  }
                }
              }}
              url={(youtubeId) ? `https://www.youtube.com/watch?v=${youtubeId}` : fileUrl}
              height={youtubeId ? '400px' : '100%'}
              width="100%"
              playing={playing}
              volume={0}
              loop={false}
              muted={true}
              progressInterval={100}
              onProgress={this.handleProgress}
              onReady={this.handleReady}
              onPlay={this.handlePlay}
              controls={true}
            />
          </div>
        ) : null }
      </div>
    )
  }
}