import gql from 'graphql-tag';
import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';

import AssetImageEdit from '../../components/AssetImageEdit';

class AssetImageEditContainer extends Component {
  state = {
    searchingItemLinkIsLoading: false
  }

  handleChangeItemLink = (index, item) => {
    const { frames, items } = this.state;
    const _frames = [...frames[0]];

    const _item = {...item, id: item.productId};
    _frames[index].productId = _item.id;

    const _items = items[_item.id] ? items : {...items, [_item.id]: _item};

    this.setState({
      frames: { 0: _frames },
      items: _items
    });
  }

  handleRemoveItemLink = (index) => {
    const frames = [...this.state.frames[0]];
    frames.splice(index, 1);
    this.setState({
      frames: {
        0: frames
      }
    });
  }

  handleSave = () => {
    const { asset, items, frames } = this.state;

    const productIdsInFrames = frames[0].map(({ productId }) => productId);

    const _asset = {
      ...asset,
      description: asset.description || null,
      frames,
      items: Object.keys(items).reduce((prev, cur, i) => {
        if (productIdsInFrames.indexOf(cur) !== -1) {
          prev[cur] = items[cur];
        }

        return prev;
      }, {})
    };

    this.setState({
      isLoadingSave: true
    });

    this.props
      .editMutation({
        variables: {
          name: _asset.name,
          title: _asset.title,
          description: _asset.description,
          company: _asset.company,
          items: _asset.items,
          frames: _asset.frames
        }
      })
      .then(() => {
        this.props.close(true);
        this.setState({
          isLoadingSave: false
        });
      })
      .catch(() => {
        this.setState({
          isLoadingSave: false
        });
      });
  }

  handleAddItem = (item) => {
    const frames = [...this.state.frames[0]];

    if (frames.length > 10) {
      return;
    }

    frames.push(item);

    this.setState({ frames: { 0: frames }});
  }

  componentWillReceiveProps(props) {
    if (this.props.data.loading && !props.data.loading && props.data.asset) {
      const asset = props.data.asset;
      const { items, frames } = asset;

      this.setState({
        items,
        frames,
        asset
      });
    }
  }

  render() {
    const { open, close } = this.props;
    const { asset, frames, items } = this.state;

    return (
      <AssetImageEdit asset={asset}
                      open={open}
                      onSave={this.handleSave}
                      onClose={close}
                      onChangeItemLink={this.handleChangeItemLink}
                      items={items}
                      frames={frames}
                      onAddItem={this.handleAddItem}
                      onClickRemoveItemLink={this.handleRemoveItemLink}
                      isLoadingSave={this.state.isLoadingSave} />
    );
  }
}

const AssetImageEditQuery = gql`
  query asset($name: String!, $company: String!) {
    asset(name: $name, company: $company) {
      name
      title
      company
      description
      location
      filePath
      jsonLocation
      preview
      type
      frames
      items
      autoreplay
    }
  }
`;

const AssetImageEditMutation = gql`
  mutation saveAsset($name: String!, $title: String!, $description: String, $company: String!, $frames: ObjectScalarType, $items: ObjectScalarType){
    asset(name: $name, title: $title, description: $description, company: $company, frames: $frames, items: $items) {
      name
      title
      company
      description
      frames
      items
    }
  }
`;

const componentGql = compose(
  graphql(AssetImageEditQuery, {
    options: props => {
      return {
        fetchPolicy: 'network-only'
      };
    },
    props: (props) => {
      return {
        ...props,
        name: props.name,
        company: props.company
      }
    }
  }),
  graphql(AssetImageEditMutation, {
    name: 'editMutation'
  })
)

export default componentGql(AssetImageEditContainer);