import * as d3 from 'd3';
import React, { Component } from 'react';


export default class TimeSeries2 extends Component {
	state = {
		timeSeries: null
	}

	componentDidUpdate(prevProps) {
	  	if (this.props !== prevProps) {
		  	const svg = this.timeSeries;
		  	while (svg.hasChildNodes()) {
			    svg.removeChild(svg.lastChild);
			}
		    this.renderChart();
	  	}
	}

	componentDidMount() {
		this.renderChart();
	}

	renderChart() {
		const startDate = this.parseDateRange(this.props.startDate);
		const endDate = this.parseDateRange(this.props.endDate);
		const data = this.fillInMissingDates(this.props.data, startDate, endDate);

		const margin = {top: 10, right: 10, bottom: 45, left: 50};
		const width = 600 - margin.left - margin.right;
 		const height = 200 - margin.top - margin.bottom;

		const cred = d3.rgb("#B7072E");

		const x = d3.scaleTime()
							.domain(d3.extent(data, (d) => d.date))
							.rangeRound([0,width]);

		const y = d3.scaleLinear()
							.domain([0, Math.ceil( d3.max( data, (d) => d.value ) / 10 ) * 10])
							.range([height,0]);

		const valueline = d3.line()
 						.x((d) => x(d.date))
 						.y((d) => y(d.value));

		const xMax = d3.max(data, (d)=>d.date);
		const xMin = d3.min(data, (d)=>d.date);
		console.log(xMax);
		console.log(xMin);

		const xAxis = d3.axisBottom(x)
										.ticks(xMin,xMax,9)
										.tickFormat(d3.timeFormat('%b %d'));
		const yAxis = d3.axisLeft(y)
										.ticks(10);

		const svg = d3.select(this.timeSeries).append("svg")
								.attr("width", width + margin.left + margin.right)
								.attr("height", height + margin.top + margin.bottom)
							.append("g")
								.attr("transform", "translate(" + margin.left+ "," + margin.top + ")");

		const make_x_gridlines = () => d3.axisBottom(x).ticks(10);
		const make_y_gridlines = () => d3.axisLeft(y).ticks(10);

		svg.append("g")
	    .attr("class", "grid")
	    .attr("transform", "translate(0," + height + ")")
	    .call(
				make_x_gridlines()
	        .tickSize(-height)
	        .tickFormat("")
		  )

	  svg.append("g")
	    .attr("class", "grid")
	    .call(
				make_y_gridlines()
	        .tickSize(-width)
	        .tickFormat("")
			)

		svg.append("path")
				.data([data])
				.attr("class", "line")
				.attr("d", valueline)
				.attr("stroke", cred)
				.attr("fill", "none");

		svg.append("g")
				.attr("class","xAxis")
				.attr("transform", "translate(0," + height + ")")
				.call(xAxis)
				.selectAll("text")
				.style("text-anchor", "end")
				.attr("dx", "-.8em")
				.attr("dy", ".15em")
				.attr("transform", "rotate(-65)");

		svg.append("g")
				.call(d3.axisLeft(y)
								.ticks(10))
				.append("text")
				.attr("fill", "#000")
				.attr("transform", "rotate(-90)")
				.attr("y", -40)
				.attr("dy", "0.71em")
				.attr("text-anchor", "end")
				.text(this.props.label);
	}

	fillInMissingDates(olddata, start, end) {
		const data = olddata.slice()

		if (start !== data[0].date) {
			data.unshift({date: start, value: 0});
		}

		if (end !== data[data.length-1].date) {
			data.push({date: end, value: 0});
		}

		const result = [{date: this.parseDate(data[0].date), value: data[0].value}];
		for (let i=1; i<data.length; i++) {
			const parsedDate = this.parseDate(data[i].date);
			const diff = this.dateDifference(this.parseDate(data[i].date), this.parseDate(data[i-1].date));
			if (diff > 1) {
				const prevDate = this.parseDate(data[i-1].date);
				for (let j=0; j<diff-1; j++) {
					const fillDate = new Date(prevDate.setDate(prevDate.getDate()+1));
					const val = {date: fillDate, value: 0}
					result.push(val)
				}
			}
			result.push({date: parsedDate, value: data[i].value});
		}
		return result;
	}

	formatDate(date) {
	    const d = new Date(date)
	    const month = String(d.getMonth() + 1).length < 2 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
	    const day = String(d.getDate()).length < 2 ? '0' + d.getDate() : d.getDate();
	    const year = d.getFullYear()

	    return [year, month, day].join('');
	}

	parseDate(dateString) {
		const year = dateString.substring(0,4);
		const month = dateString.substring(4,6);
		const day = dateString.substring(6,8);

		const date = new Date(year, month-1, day);
		return date;
	}

	parseDateRange(dateString) {
		const date = dateString.split('-').join('');
		return date;
	}

	dateDifference(start, end) {
		const timeDiff = Math.abs(start.getTime() - end.getTime());
		const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
		return diffDays;
	}

	render() {
		return (
			<svg
				ref={(ref: SVGSVGElement) => this.timeSeries = ref}
				width={600}
				height={200}
			/>
		)
	}
}
