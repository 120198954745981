import gql from 'graphql-tag';
import React, { Component, Fragment } from 'react';
import { compose, graphql } from 'react-apollo';
import ModalAssetUpload from '../../components/ModalAssetUpload';
import { EnumAssetType } from '../../models/Asset';
import ModalAssetGeneralInformationEdit from '../../components/ModalAssetGeneralInformationEdit';
import Axios from 'axios';
import ModalAssetImportFromUrl from '../../components/ModalAssetImportFromUrl';
import ModalAssetImportFromYoutube from '../../components/ModalAssetImportFromYoutube';
import ModalChooseOrder from '../../components/ModalChooseOrder';

const STAGES = {
  FILE_SELECT: 'file select',
  INFORM_URL: 'inform url',
  SELECT_YOUTUBE: 'select from youtube',
  CHOOSE_ORDER: 'choose order',
  GENERAL_INFORMATION: 'general information'
};

class AssetCreateContainer extends Component {
  state = {}

  componentWillReceiveProps(props) {
    if (!props.youtubeVideos.loading && props.youtubeVideos.youtubeVideos) {
      this.setState({
        youtubeVideos: props.youtubeVideos.youtubeVideos
      });
    }

    if (this.props.youtubeVideos.loading && !props.youtubeVideos.loading) {
      if (props.youtubeVideos.error) {
        this.setState({
          askForGoogleCredentialsYoutubeImport: true
        })
      }
    }
  }

  componentDidMount() {
    this.setState({
      assets: this.props.assets,
      assetsGrouped: this.props.assets && this.props.assets.length ? this.groupAssets(this.props.assets.map(asset => ({
        set: null,
        name: asset.name
      }))) : null
    });
    this.setStartingStage();
  }

  groupAssets = assets => assets.reduce((prev, cur, i) => {
    if (!prev.length) {
        prev.push({
            set: cur.set,
            items: [cur.name]
        });

        return prev;
    }

    const indexOfSet = prev.findIndex(({set}) => set === cur.set);

    if (indexOfSet === -1) {
        prev.push({
            set: cur.set,
            items: [cur.name]
        });

        return prev;
    }

    prev[indexOfSet].items.push(cur.name);

    return prev;
  }, []);

  setStartingStage = () => {
    const { isPlaylistCreation, isSlideshowCreation, isCreationFromFile, isCreationFromYoutube, isCreationFromURL } = this.getCreationType();

    if (isPlaylistCreation || isSlideshowCreation) {
      this.setState({
        stage: STAGES.CHOOSE_ORDER
      })
    } else if (isCreationFromFile) {
      this.setState({
        stage: STAGES.FILE_SELECT
      });
    } else if (isCreationFromURL) {
      this.setState({
        stage: STAGES.URL
      });
    } else if (isCreationFromYoutube) {
      this.setState({
        stage: STAGES.SELECT_YOUTUBE
      });
    }
  }

  getCreationType = () => {
    const { type, from } = this.props;

    const isPlaylistCreation = type === EnumAssetType.PLAYLIST;
    const isSlideshowCreation = type === EnumAssetType.SLIDESHOW;
    const isCreationFromFile = from === EnumAssetCreateFrom.FILE;
    const isCreationFromYoutube = from === EnumAssetCreateFrom.YOUTUBE;
    const isCreationFromURL = from === EnumAssetCreateFrom.URL;

    return {
      isPlaylistCreation,
      isSlideshowCreation,
      isCreationFromFile,
      isCreationFromYoutube,
      isCreationFromURL
    };
  }

  handleClickSave = () => {
    const { isPlaylistCreation, isSlideshowCreation } = this.getCreationType();

    if (isPlaylistCreation || isSlideshowCreation) {
      const { title, description, closeLocation, assetsGrouped, videoCreator, disablePlaylist, zoomIn } = this.state;

      this.setState({
        loadingSave: true
      });
    
      this.props
        .assetCreate({
          variables: {
            title: title,
            description: description,
            company: localStorage.getItem('company'),
            closeLocation,
            assets: assetsGrouped.reduce((prev, { set, items }) => [...prev, ...items.map(name => ({ set, name }))], []),
            assetsType: isPlaylistCreation ? EnumAssetType.PLAYLIST : EnumAssetType.SLIDESHOW,
            videoCreator,
            disablePlaylist,
            zoomIn
          }
        })
        .then(() => {
          this.setState({
            loadingSave: false
          });
          this.props.onClose({ savedPlaylistOrSlideshow: true })
        })
        .catch(() => {
          this.setState({
            loadingSave: false
          });
        });
    } else {
      const { title, description, closeLocation, file, urlFile, videoCreator, disablePlaylist, zoomIn } = this.state;
      const data = new FormData();
      const variables = {
        title,
        description,
        file: null,
        closeLocation,
        company: localStorage.getItem('company'),
        videoCreator,
        disablePlaylist,
        zoomIn
      };
      const params = {
        operationName: 'asset',
        variables,
        query: `mutation asset (
          $title: String!,
          $company: String!,
          $description: String,
          $file: Upload,
          $fileUrl: String,
          $closeLocation: String,
          $videoCreator: String,
          $disablePlaylist: Boolean,
          $zoomIn: Boolean
        ) {
          asset (
            title: $title,
            company: $company,
            description: $description,
            file: $file,
            fileUrl: $fileUrl,
            closeLocation: $closeLocation,
            videoCreator: $videoCreator,
            disablePlaylist: $disablePlaylist,
            zoomIn: $zoomIn
          ) {
            name
            title
            company
            preview
            description
            filePath
            location
            jsonLocation
            status
            type
            createdAt
            products {
              url
            }
            __typename
          }
        }`
      }
  
      if (file) {
        data.append('operations', JSON.stringify(params));
        data.append('map', JSON.stringify({
          0: ['variables.file']
        }));
        data.append('0', file);
  
      } else if (urlFile) {
        variables.fileUrl = urlFile;
  
      } else {
        throw new Error('must to send file or fileUrl');
      }
  
      this.setState({
        loadingSave: true
      });
      this.setState(Object.assign({}, this.state, { loadingProgress: 0 }));
  
      Axios
        .post(`${process.env.REACT_APP_API_URL}/graphql`, file ? data : params, {
          headers: {
            'authorization': 'Bearer ' + localStorage.getItem('token')
          },
          onUploadProgress: (progressEvent) => {
            this.setState(Object.assign({}, this.state, { loadingProgress: Math.round((progressEvent.loaded * 100) / progressEvent.total) }));
          }
        })
        .then((response) => {
          this.setState(Object.assign({}, this.state, { loadingProgress: undefined }));
          this.props.onClose();
        })
        .catch((error) => {
          this.setState(Object.assign({}, this.state, { loadingProgress: undefined }));
        });
    }
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value
    });
  }

  handleSelectFile = ({ target: { files } }) => {
    if (files && files[0]) {
      const splittedFileName = files[0].name.split('.');
      const fileName = splittedFileName.slice(0, splittedFileName.length - 1).join('');
      this.setState(Object.assign({}, this.state, {
        title: fileName,
        file: files[0],
        urlFile: URL.createObjectURL(files[0])
      }));
    }
  }

  handleClickNextAssetUpload = () => {
    this.setState({
      stage: STAGES.GENERAL_INFORMATION
    });
  }
  
  handleClickNextImportFromUrl = () => {
    const { urlFile } = this.state;

    const youtubeRegexResult = (urlFile).match(/(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^\?&"'>]+)/);
    const youtubeId = youtubeRegexResult ? youtubeRegexResult[5] : null;
    if (youtubeId) {
      Axios.get('https://www.googleapis.com/youtube/v3/videos', {
        params: {
          part: 'id, snippet, contentDetails',
          id: youtubeId,
          key: process.env.REACT_APP_YOUTUBE_API_KEY
        }
      })
        .then((resp) => this.setState({ title: resp.data.items[0].snippet.title }, () => this.handleClickSave()));
    } else {
      const splittedFileName = urlFile.split('.');
      const fileName = splittedFileName.slice(0, splittedFileName.length - 1).join('');
      this.setState({
        title: fileName.split('/').pop(),
        stage: STAGES.GENERAL_INFORMATION
      });
    }
  }

  handleClickPrevGeneralInformation = () => {
    this.setStartingStage();
  }

  onErrorGoogleAccountInfo = ({ error }) => {
    if (error === 'popup_blocked_by_user') {
      // to-do feedback to allow browser to show popups on this site
    }
  }

  onSuccessGoogleAccountInfo = (googleResp = {}) => {
    this.props.updateGoogleAccount({
      variables: {
        name: localStorage.getItem('company'),
        account: googleResp.code ? undefined : JSON.stringify(googleResp),
        code: googleResp.code
      }
    })
    .then(() => this.props.youtubeVideos.refetch({ company: localStorage.getItem('company') }));
  }

  handleCheckVideo = (id) => (event, data) => {
    this.setState({
      youtubeVideos: this.state.youtubeVideos.map((youtubeVideo) => {
        if (youtubeVideo.id === id) {
          return {
            ...youtubeVideo,
            checked: data.checked
          }
        }

        return youtubeVideo;
      })
    })
  }

  handleClickImport = () => {
    const { youtubeVideos } = this.state;

    this.setState({
      isLoadingImportYoutube: true
    });

    Promise.all(
      youtubeVideos
        .filter(({ checked }) => checked)
        .map(({ id, title, description, thumbnail, channelTitle }) => this.props.assetCreate({
          variables: {
            title,
            company: localStorage.getItem('company'),
            youtubeId: id,
            description,
            thumbnail,
            videoCreator: channelTitle
          }
        }))
    )
    .then(() => {
      this.setState({
        isLoadingImportYoutube: true,
        isImportYoutubeSuccess: true
      })
    })
    .catch(() => this.setState({
      isLoadingImportYoutube: false
    }));
  }

  handleClickNextChooseOrder = () => {
    this.setState({
      stage: STAGES.GENERAL_INFORMATION
    });
  }

  handleSortEnd = ({ items, set }) => {
    const { assetsGrouped } = this.state;

    const _assetsGrouped = assetsGrouped.map((assetGrouped) => {
      if (set === assetGrouped.set) {
        assetGrouped.items = items;
      }

      return assetGrouped;
    });

    this.setState({
      assetsGrouped: _assetsGrouped
    });
  }

  handleRemoveChooseOrder = ({ name, set }) => {
    const { assetsGrouped } = this.state;

    const _assetsGrouped = assetsGrouped.map((assetGrouped) => {
      if (set === assetGrouped.set) {
        const indexToRemove = assetGrouped.items.findIndex(assetName => assetName === name);
        assetGrouped.items.splice(indexToRemove, 1);
      }

      return assetGrouped;
    });

    this.setState({
      assetsGrouped: _assetsGrouped
    });
  }

  handleChangeSet = ({ value, groupI }) => {
    const _assetsGrouped = [...this.state.assetsGrouped];

    _assetsGrouped[groupI].set = value;

    this.setState({
      assetsGrouped: _assetsGrouped,
      showInputGroupI: undefined
    });
  }

  handleClickAddSet = () => {
    const _assetsGrouped = [...this.state.assetsGrouped];

    if (_assetsGrouped.length === 1) {
      _assetsGrouped[0].set = 'Set 1';
      const removedItems = _assetsGrouped[0].items.splice(_assetsGrouped[0].items.length / 2);

      _assetsGrouped.push({
        set: 'Set 2',
        items: removedItems
      });
    } else {
      _assetsGrouped.push({
        set: `Set ${_assetsGrouped.length + 1}`,
        items: []
      });
    }

    this.setState({
      assetsGrouped: _assetsGrouped
    })
  }

  handleClickShowInputGroup = (groupI) => {
    this.setState({
      showInputGroupI: groupI
    });
  }

  handleRemoveSet = (set) => {
    this.setState({
      assetsGrouped: [...this.state.assetsGrouped].filter((group) => group.set !== set)
    })
  }

  render() {
    const { onClose, open, type } = this.props;
    const {
      file,
      title,
      description,
      loadingSave,
      closeLocation,
      urlFile,
      stage,
      youtubeVideos,
      searchYoutube,
      isLoadingImportYoutube,
      isImportYoutubeSuccess,
      askForGoogleCredentialsYoutubeImport,
      assets,
      videoCreator,
      loadingProgress,
      disablePlaylist,
      zoomIn,
      assetsGrouped,
      showInputGroupI,
      autoreplay
    } = this.state;

    const isImageCreation = type === EnumAssetType.IMAGE;
    const isVideoCreation = type === EnumAssetType.VIDEO;

    const acceptedFileTypes = isImageCreation ?
      'image/x-png,image/gif,image/jpeg' :
      isVideoCreation ? 
        'video/mp4' :
        '';

    return (
      <Fragment>
        { stage === STAGES.FILE_SELECT ? 
        <ModalAssetUpload onClose={onClose}
                          open={open}
                          onSelectFile={this.handleSelectFile}
                          onClickNext={this.handleClickNextAssetUpload}
                          file={file}
                          imageUrlFile={isImageCreation ? urlFile : null}
                          videoUrlFile={isVideoCreation ? urlFile : null}
                          acceptedFileTypes={acceptedFileTypes} />
        : null }
        { stage === STAGES.URL ? 
        <ModalAssetImportFromUrl  onClose={onClose}
                                  open={open}
                                  onChange={this.handleChange}
                                  onClickNext={this.handleClickNextImportFromUrl}
                                  urlFile={urlFile} />
        : null }
        { stage === STAGES.SELECT_YOUTUBE ? 
        <ModalAssetImportFromYoutube  onClose={onClose}
                                      open={open}
                                      onChange={this.handleChange}
                                      onErrorGoogleAccountInfo={this.onErrorGoogleAccountInfo}
                                      onSuccessGoogleAccountInfo={this.onSuccessGoogleAccountInfo}
                                      onCheckVideo={this.handleCheckVideo}
                                      youtubeVideos={youtubeVideos}
                                      searchYoutube={searchYoutube}
                                      onClickImport={this.handleClickImport}
                                      isLoadingImportYoutube={isLoadingImportYoutube}
                                      isImportYoutubeSuccess={isImportYoutubeSuccess}
                                      askForGoogleCredentialsYoutubeImport={askForGoogleCredentialsYoutubeImport} />
        : null }
        { stage === STAGES.CHOOSE_ORDER ?
        <ModalChooseOrder onClose={onClose}
                          open={open}
                          type={type}
                          assets={assets}
                          assetsGrouped={assetsGrouped}
                          onClickNext={this.handleClickNextChooseOrder}
                          onSortEnd={this.handleSortEnd}
                          onRemove={this.handleRemoveChooseOrder}
                          onChangeSet={this.handleChangeSet}
                          onClickAddSet={this.handleClickAddSet}
                          showInputGroupI={showInputGroupI}
                          onClickShowInputGroup={this.handleClickShowInputGroup}
                          onRemoveSet={this.handleRemoveSet} />
        : null }
        { stage === STAGES.GENERAL_INFORMATION ?
        <ModalAssetGeneralInformationEdit title={title}
                                          description={description}
                                          closeLocation={closeLocation}
                                          videoCreator={videoCreator}
                                          onClose={onClose}
                                          open={open}
                                          onChange={this.handleChange}
                                          onClickSave={this.handleClickSave}
                                          loadingSave={loadingSave}
                                          onClickPrevGeneralInformation={this.handleClickPrevGeneralInformation}
                                          loadingProgress={loadingProgress}
                                          disablePlaylist={disablePlaylist}
                                          zoomIn={zoomIn}
                                          autoreplay={autoreplay}
                                          hideDisablePlaylist={!isVideoCreation}
                                          hideZoomIn={!isVideoCreation}
                                          hideAutoreplay={!isVideoCreation} />
        : null }
      </Fragment>
    );
  }
}

export const EnumAssetCreateFrom = {
  FILE: 'File',
  YOUTUBE: 'Youtube',
  URL: 'URL'
};


const QueryYoutubeVideos = gql`
  query youtubeVideos ($company: String!){
    youtubeVideos(company: $company) {
      id
      title
      description
      thumbnail
      publishedAt
      channelTitle
    }
  }
`;

const UpdateGoogleAccount = gql`
  mutation updateGoogleAccount ($name: String!, $account: String, $code: String){
    updateGoogleAccount(name: $name, account: $account, code: $code) {
      name
    }
  }
`;



const AssetCreateMutation = gql`
  mutation saveAsset(
    $title: String!,
    $company: String!,
    $youtubeId: String,
    $thumbnail: String,
    $description: String,
    $assets: [ISubAsset],
    $assetsType: Int,
    $closeLocation: String,
    $videoCreator: String,
    $disablePlaylist: Boolean,
    $zoomIn: Boolean,
    $autoreplay: Boolean
  ) {
    asset(
      title: $title,
      company: $company,
      youtubeId: $youtubeId,
      thumbnail: $thumbnail,
      description: $description,
      assets: $assets,
      assetsType: $assetsType,
      closeLocation: $closeLocation,
      videoCreator: $videoCreator,
      disablePlaylist: $disablePlaylist,
      zoomIn: $zoomIn,
      autoreplay: $autoreplay
    ) {
      name
      title
      youtubeId
      assets {
        set
        name
      }
    }
  }
`;

const assetGqls = compose(
  graphql(QueryYoutubeVideos, {
    name: 'youtubeVideos',
    options: () => ({
      variables: {
        company: localStorage.getItem('company')
      },
      fetchPolicy: 'network-only'
    })
  }),
  graphql(UpdateGoogleAccount, {
    name: 'updateGoogleAccount'
  }),
  graphql(AssetCreateMutation, {
    name: 'assetCreate'
  })
)

export default assetGqls(AssetCreateContainer);