import './style.css';

import React, { Component } from 'react';
import AppMenuContainer from '../AppMenu';
import { Redirect } from 'react-router';

export default class PageWrapper extends Component {
  state = {
    activeModuleTitle: ''
  }

  setActiveModuleTitle = (title) => this.setState({ activeModuleTitle: title })

  onRedirect = (redirectTo) => {
    this.setState({ redirectTo })
  }

  render() {
    const { children } = this.props;
    const { activeModuleTitle, redirectTo } = this.state;

    if (redirectTo) {
      const _redirectTo = redirectTo;
      this.state.redirectTo = undefined;
      if (_redirectTo.indexOf('https://') !== -1 ) {
        window.open(_redirectTo, '_blank');
      } else {
        return (<Redirect to={{pathname: _redirectTo}}
                          push={true}/>);
      }
    }

    return (
      <div className='PageWrapper'>
        <AppMenuContainer activeModuleTitle={activeModuleTitle}
                          onRedirect={this.onRedirect} />
        <div className='PageWrapper__Content'>
          { React.cloneElement(children, { setActiveModuleTitle: this.setActiveModuleTitle }) }
        </div>
      </div>
    )
  }
}