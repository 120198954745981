import './style.css';

import React, { Component, Fragment } from 'react';
import { GoogleLogin } from 'react-google-login';
import { Button, Modal, Form, Input, TextArea, Dimmer, Loader, Image, Popup } from 'semantic-ui-react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import logoFacebook from '../../resources/fb.png';

export default class ModalAssetSynchronizeToYoutube extends Component {
  componentDidMount() {
    if (this.props.askGoogleCredentials) {
      setTimeout(() => this.googleAccountButton ? this.googleAccountButton.ref.click() : null, 1000);
    }
  }

  componentWillReceiveProps(props) {
    if (!this.props.askGoogleCredentials && props.askGoogleCredentials) {
      setTimeout(() => this.googleAccountButton ? this.googleAccountButton.ref.click() : null, 1000);
    }
  }

  onResponseFacebook = (response) => {
    console.log(response);
  }

  render() {
    const { open, onClose, onErrorGoogleAccountInfo, onSuccessGoogleAccountInfo, isLoadingPublishYoutube, onUpdateYoutube, isLoadingUpdateYoutube, onClickPublishYoutube,
            videoUrl, title, description, onChange, isLoadingGeneral, hideTitle, onClickCopy, isCopied,
            onCallbackFacebookLogin, isLoadingPublishFacebook } = this.props;

    const onRenderGoogleLogin = (renderProps) => {
      return <Button  color='red'
                      onClick={renderProps ? renderProps.onClick : undefined}
                      className='ModalAssetSynchronizeToYoutube__GoogleLoginButton'
                      ref={(ref) => this.googleAccountButton = ref} >
        <div className='body-bold'>Google Login</div>
      </Button>;
    };

    const onRenderFacebookLogin = (renderProps) => {
      return <Button  className='Action__FacebookButton'
                      onClick={renderProps ? renderProps.onClick : undefined}
                      loading={isLoadingPublishFacebook} >
        <div className='Action__FacebookButton__Wrapper'>
          <Image  src={logoFacebook}
                  className='Action__FacebookButton__Logo' />
          <div className='body-bold'>Post to Facebook</div>
        </div>
      </Button>;
    };

    const textDescriptionField = (
      <Form.Field control={TextArea}
                  label='Description'
                  placeholder='Description'
                  name='description'
                  rows={8}
                  value={description || ''}
                  onChange={(e, { value }) => onChange({ name: 'description', value })}/>
    );

    return (
      <Modal  open={open}
              size='small'
              onClose={() => onClose()}
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        { isLoadingGeneral ?
          <Dimmer active
                  inverted >
            <Loader />
          </Dimmer>
        : null }
        <Modal.Content>
          <div className='heading-3 cblack'>
            { onSuccessGoogleAccountInfo ? onUpdateYoutube ? 'Update this video description on your Youtube channel' : 'Publish this video to a Youtube channel' : null }
            { onClickCopy ? 'Copy AcuityAI generated description' : null }
            { onCallbackFacebookLogin ? 'Post this video to Facebook' : null }
          </div>
          <div className='ModalAssetSynchronizeToYoutube__Content'>
            { videoUrl ?
              <div className='ModalAssetSynchronizeToYoutube__VideoWrapper'>
                <video  controls>
                  <source src={videoUrl}
                          type="video/mp4"/>
                </video>
              </div>
            : null }
            <Form>
              { hideTitle ?
              null : (
                <Form.Field control={Input}
                            label='Title'
                            placeholder='Title'
                            name='title'
                            value={title || ''}
                            onChange={(e, { value }) => onChange({ name: 'title', value })} />
              ) }
              { textDescriptionField }
              {/* { onCallbackFacebookLogin ? (
                <Popup trigger={textDescriptionField}>
                  <Popup.Content>
                    <div className='body-bold cgrey' style={{minWidth: '300px'}}>Paste (CTRL+V) description here to increase your post revenue.</div>
                  </Popup.Content>
                </Popup>
              ) : textDescriptionField } */}
            </Form>
          </div>
        </Modal.Content>
        <Modal.Actions>
          { onSuccessGoogleAccountInfo ? (
            <Fragment>
              <GoogleLogin  clientId={'41979601646-1oupgoq5l4curirqarssiqrmgrcf1452.apps.googleusercontent.com'}
                            scope='https://www.googleapis.com/auth/youtube'
                            prompt='consent'
                            responseType='code'
                            accessType='offline'
                            onSuccess={onSuccessGoogleAccountInfo}
                            onFailure={onErrorGoogleAccountInfo}
                            render={onRenderGoogleLogin} />
              { onUpdateYoutube ?
                <Button color='red'
                        onClick={onUpdateYoutube}
                        loading={isLoadingPublishYoutube} >
                  <div className='body-bold'>Update</div>
                </Button>
                :
                <Button color='red'
                        onClick={onClickPublishYoutube}
                        loading={isLoadingPublishYoutube} >
                  <div className='body-bold'>Upload</div>
                </Button>
              }
            </Fragment>
          ) : null }
          { onCallbackFacebookLogin ? (
            <FacebookLogin  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                            fields='name,email,picture'
                            callback={onCallbackFacebookLogin}
                            render={onRenderFacebookLogin}
                            scope='public_profile, email, manage_pages, publish_pages' />
          ) : null}
          { onClickCopy ?
            <Button color={isCopied ? 'green' : 'red'}
                    onClick={onClickCopy} >
              <div className='body-bold'>{isCopied ? 'Copied to Clipboard' : 'Copy'}</div>
            </Button>
          : null }
        </Modal.Actions>
      </Modal>
    );
  }
}
