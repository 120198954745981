import clipboardCopy from 'clipboard-copy';
import gql from 'graphql-tag';
import _get from 'lodash/get';
import { sha256 } from 'js-sha256';

import React, { Component, Fragment } from 'react';
import { compose, graphql } from 'react-apollo';

import AssetList from '../../components/AssetList';
import EmbedAsset from '../../components/EmbedAsset';
import {
  generateFileUrl, generatePreviewUrl, EnumStatusAsset, EnumAssetType, generateJSONUrl, getProductsFromVideo, assetExtensions
} from '../../models/Asset';
import { isUserMaster } from '../../models/User';
import CompanyService from '../../tools/company';


export const ASSETLIST_TABS = {
  ALL: 'all',
  UPLOAD: 'upload',
  YOUTUBE: 'youtube',
  SLIDESHOW: 'slideshow',
  PLAYLIST: 'playlist'
};

class AssetListContainer extends Component {
  state = {
    assets: [],
    orderBy: {
      fieldName: 'createdAt',
      order: 'desc'
    },
    activeTab: ASSETLIST_TABS.ALL
  }

  componentDidMount() {
    this.props.setActiveModuleTitle('Assets');

    if (this.props.location.state && this.props.location.state.redirectTo) {
      this.setState({ redirectTo: this.props.location.state.redirectTo });
    }
  }

  componentWillReceiveProps(props) {
    if (this.props.assetList.loading && !props.assetList.loading && props.assetList.assets && props.assetList.assets.length) {
      if (!isUserMaster()) {
        const productPromises = props.assetList.assets
          .filter(({ status, type }) => status && status.value === EnumStatusAsset.APPROVED && type && type.value !== EnumAssetType.PLAYLIST && type.value !== EnumAssetType.SLIDESHOW)
          .map(({ company, name, type, frames, items, media }) => {
            if (!frames || !items) return { name, products: [] };
            return {
              name,
              products: getProductsFromVideo({
                frames, items, media
              })
            };
          });


        Promise.all(productPromises)
          .then((results) => {
            const { assets } = this.state;
            const newAssets = assets.map((asset) => {
              const result = results.find(({ name }) => name === asset.name);
              if (result) {
                return Object.assign(asset, { products: result.products });
              }

              return asset;
            });

            this.setState({ assets: newAssets });
          })
      }
      return this.setState(Object.assign({}, this.state, { assets: [...props.assetList.assets] }));
    }

    const _asset = (this.props.assetSubscription && this.props.assetSubscription.asset) ? this.props.assetSubscription.asset : null;
    const asset = (props.assetSubscription && props.assetSubscription.asset) ? props.assetSubscription.asset : null;

    if (asset) {
      const canApplyChangesToCompany = (isUserMaster() || asset.company === localStorage.getItem('company'));

      if (canApplyChangesToCompany && ((asset && _asset && (asset.name !== _asset.name)) || (asset !== _asset))) {
        const existingAsset = this.state.assets.find((a) => a.name === asset.name);
        if (!existingAsset) {
          return this.setState(Object.assign({}, this.state, { assets: [asset, ...this.state.assets] }));
        } else {
          const assets = this.state.assets.map((a) => {
            if (a.name !== asset.name) {
              return a;
            }

            return asset;
          })
          return this.setState(Object.assign({}, this.state, { assets }));
        }
      }
    }

    const _assetDelete = (this.props.assetDeleteSubscription && this.props.assetDeleteSubscription.assetDelete) ? this.props.assetDeleteSubscription.assetDelete : null;
    const assetDelete = (props.assetDeleteSubscription && props.assetDeleteSubscription.assetDelete) ? props.assetDeleteSubscription.assetDelete : null;

    if (assetDelete) {
      const canApplyChangesToCompany = (isUserMaster() || assetDelete.company === localStorage.getItem('company'));
      if (canApplyChangesToCompany && ((assetDelete && _assetDelete && (assetDelete.name !== _assetDelete.name)) || (assetDelete !== _assetDelete))) {
        this.setState({
          assets: this.state.assets.filter((ass) => ass.name !== assetDelete.name || ass.company !== assetDelete.company)
        });
      }
    }
  }

  handleCheckAllChecked = (event, data) => {
    const assets = this.state.assets
      .map((_stateAsset) => {
        const stateAsset = Object.assign({}, _stateAsset);
        stateAsset.checked = data.checked;

        return stateAsset;
      });

    this.setState(Object.assign({}, this.state, { assets }));
  }

  handleClickDetails = (asset) => () => {
    this.setState({
      redirectTo: `/assets/${asset.name}/details${isUserMaster() ? `/${asset.company}` : ''}`
    })
  }

  handleClickAnalytics = (asset) => () => {
    this.setState({
      redirectTo: `/assets/${asset.name}/analytics${isUserMaster() ? `/${asset.company}` : ''}`
    })
  }

  handleSelectAsset = (asset) => {
    return (event, data) => {
      const assets = this.state.assets
        .map((_stateAsset) => {
          const stateAsset = Object.assign({}, _stateAsset);
          if (stateAsset.name === asset.name) {
            stateAsset.checked = !stateAsset.checked;
          }

          return stateAsset;
        });

      this.setState({ assets });
    }
  }

  deleteCheckedAssets = () => {
    Promise.all(this.state.assets
      .filter((asset) => asset.checked)
      .map((asset) => this.props.excludeAssetMutation({
        variables: {
          name: asset.name,
          company: asset.company
        }
      })));
  }

  handleClickGetLink = ( e, asset ) => {
    const { webpage } = CompanyService.get();
    let previewUrl;

    if (webpage) {
      previewUrl = `${webpage}?asset=${asset.name}&type=${assetExtensions[asset.type.value]}`;
    } else {
      previewUrl = generatePreviewUrl({ company: asset.company, name: asset.name, type: asset.type.value });
    }

    clipboardCopy(previewUrl);
    this.setState({
      isLinkCopied: true
    });

    setTimeout(() => this.afterLinkCopied(), 2000);
  }

  afterLinkCopied = () => {
    this.setState({
      isLinkCopied: false
    });
  }

  handleClickGetEmbedCode = ( e, asset) => {
    this.setState({ asset });
    const { assetConnection } = this.props;
    const hash = sha256(`${asset.name}:${asset.company}:videotag`);

    assetConnection({
      variables: {
        name: asset.name,
        company: asset.company,
        connectionHash: hash,
        webLocation: hash
      }
    });

    const source = asset.type.value === EnumAssetType.PLAYLIST ? (
        generateFileUrl({
          company: asset.company,
          name: asset.assets[0],
          type: EnumAssetType.VIDEO
        })
      ) : (
        generateFileUrl({
          company: asset.company,
          name: asset.name,
          type: asset.type.value
        })
    );

    this.setState({
      openEmbedAsset: true,
      connection: {
        source,
        hash
      }
    });
  }

  handleCloseEmbedAsset = () => {
    this.setState({
      openEmbedAsset: false,
      connection: null
    })
  }

  handleChangeSearch = (event, { value }) => {
    this.setState({ search: value });
  }

  onChangeOrder = (fieldName) => {
    let orderBy = Object.assign({}, this.state.orderBy);
    if (this.state.orderBy.fieldName !== fieldName) {
      orderBy = {
        fieldName,
        order: 'asc'
      };
    } else {
      orderBy.order = orderBy.order === 'asc' ? 'desc' : 'asc';
    }

    const isEnumValue = fieldName === 'status' || fieldName === 'type';

    this.state.assets
      .sort((a, b) => {
        if ((isEnumValue && _get(a, `${orderBy.fieldName}.name`) && _get(b, `${orderBy.fieldName}.name`) && _get(a, `${orderBy.fieldName}.name`) > _get(b, `${orderBy.fieldName}.name`)) ||
            (!isEnumValue && a[orderBy.fieldName] > b[orderBy.fieldName])) {
          return orderBy.order === 'asc' ? 1 : -1;
        } else if ((isEnumValue && _get(a, `${orderBy.fieldName}.name`) && _get(b, `${orderBy.fieldName}.name`) && _get(a, `${orderBy.fieldName}.name`) < _get(b, `${orderBy.fieldName}.name`)) ||
            (!isEnumValue && _get(a, fieldName) < _get(b, fieldName))) {
          return orderBy.order === 'asc' ? -1 : 1;
        }

        return 0;
      });

    this.setState({ orderBy });
  }

  handleAfterCreatePlaylistOrSlideshow = () => {
    this.handleCheckAllChecked(undefined, { checked: false });
  }

  handleClickSetActiveTab = (activeTab) => {
    this.setState({ activeTab });
  }

  getUploadedVideos = () => {
    return this.state.assets.filter((asset) => asset.type && asset.type.value === EnumAssetType.VIDEO && !asset.youtubeId);
  }

  getYoutubeVideos = () => {
    return this.state.assets.filter((asset) => asset.type && asset.type.value === EnumAssetType.VIDEO && asset.youtubeId);
  }

  getSlideshows = () => {
    return this.state.assets.filter((asset) => asset.type && asset.type.value === EnumAssetType.SLIDESHOW);
  }

  getPlaylists = () => {
    return this.state.assets.filter((asset) => asset.type && asset.type.value === EnumAssetType.PLAYLIST);
  }

  filterBySearch = (search, assets) => {
    if (!search) return assets;

    return assets.filter((asset) => (
      (asset.products && asset.products.find(({ title }) => title.toUpperCase().indexOf(search.toUpperCase()) !== -1)) ||
      (asset.title.toUpperCase().indexOf(search.toUpperCase()) !== -1)
    ));
  }

  render () {
    const { orderBy, asset, assets, connection, openEmbedAsset, isLinkCopied, redirectTo, search, activeTab } = this.state;

    const filteredByTabAssets =
      (activeTab === ASSETLIST_TABS.UPLOAD) ? this.getUploadedVideos() :
      (activeTab === ASSETLIST_TABS.YOUTUBE) ? this.getYoutubeVideos() :
      (activeTab === ASSETLIST_TABS.SLIDESHOW) ? this.getSlideshows() :
      (activeTab === ASSETLIST_TABS.PLAYLIST) ? this.getPlaylists() :
      [...assets];

    const filteredBySearchAssets = this.filterBySearch(search, filteredByTabAssets);

    return (
      <Fragment>
        {connection && openEmbedAsset && (
          <EmbedAsset
            open={openEmbedAsset}
            source={connection.source}
            hash={connection.hash}
            onClose={this.handleCloseEmbedAsset}
            type={asset.type.value}
            name={asset.name}
            company={asset.company}
          />
        )}
        <AssetList  assets={filteredBySearchAssets}
                    redirectTo={redirectTo}
                    onClickDetails={this.handleClickDetails}
                    onClickAnalytics={this.handleClickAnalytics}
                    onCheckAllChecked={this.handleCheckAllChecked}
                    onSelectAsset={this.handleSelectAsset}
                    deleteCheckedAssets={this.deleteCheckedAssets}
                    onClickGetLink={this.handleClickGetLink}
                    isLinkCopied={isLinkCopied}
                    onClickGetEmbedCode={this.handleClickGetEmbedCode}
                    onChangeSearch={this.handleChangeSearch}
                    orderBy={orderBy || {}}
                    onChangeOrder={this.onChangeOrder}
                    afterCreatePlaylistOrSlideshow={this.handleAfterCreatePlaylistOrSlideshow}
                    activeTab={activeTab}
                    onClickSetActiveTab={this.handleClickSetActiveTab} />
      </Fragment>
    );
  }
}

const AssetListQuery = gql`
  query allAssets($company: String!) {
    assets(company: $company) {
      name
      title
      company
      preview
      description
      thumbnail
      status
      type
      filePath
      location
      jsonLocation
      assets {
        set
        name
      }
      youtubeId
      videoSrc
      frames
      items
      media {
        width
        framerate
        height
        ratio
      }
      products {
        url
      }
      createdAt
    }
  }
`;

const ExcludeAssetMutation = gql`
  mutation excludeAsset($name: String!, $company: String!){
    excludeAsset(name: $name, company: $company) {
      name
      title
      company
      preview
      description
      status
      type
      filePath
      location
      jsonLocation
      assets {
        set
        name
      }
      youtubeId
      products {
        url
      }
      createdAt
    }
  }
`;

const AssetListSubscription = gql`
  subscription onAssetChange {
    asset {
      name
      title
      company
      preview
      description
      thumbnail
      status
      type
      filePath
      location
      jsonLocation
      assets {
        set
        name
      }
      youtubeId
      products {
        url
      }
      createdAt
    }
  }
`;

const AssetDeleteSubscription = gql`
  subscription onAssetDelete {
    assetDelete {
      name
      company
    }
  }
`;

const AssetConnectionMutation = gql`
  mutation assetConnection($connectionHash: String!, $company: String!, $name: String!, $webLocation: String!){
    assetConnection(connectionHash: $connectionHash, company: $company, name: $name, webLocation: $webLocation)  {
      connectionHash
      name
      company
    }
  }
`;

const assetGqls = compose(
  graphql(AssetListQuery, {
    name: 'assetList',
    options: () => ({
      variables: {
        company: localStorage.getItem('company')
      },
      fetchPolicy: 'network-only'
    })
  }),
  graphql(ExcludeAssetMutation, {
    name: 'excludeAssetMutation'
  }),
  graphql(AssetListSubscription, {
    name: 'assetSubscription'
  }),
  graphql(AssetDeleteSubscription, {
    name: 'assetDeleteSubscription'
  }),
  graphql(AssetConnectionMutation, {
    name: 'assetConnection'
  })
);

export default assetGqls(AssetListContainer);
