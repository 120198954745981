import './style.css';

import React, { Component } from 'react';
import { Modal, Form, Button, Input, TextArea, Checkbox, Progress } from 'semantic-ui-react';

export default class ModalAssetGeneralInformationEdit extends Component {
  render() {
    const {
      title,
      description,
      closeLocation,
      disablePlaylist,
      open,
      onClose,
      onChange,
      onClickSave,
      loadingSave,
      onClickPrevGeneralInformation,
      videoCreator,
      loadingProgress,
      zoomIn,
      hideAutoreplay,
      hideDisablePlaylist,
      hideZoomIn,
      autoreplay
    } = this.props;

    return (
      <Modal  size='small'
              open={open}
              onClose={() => onClose()}
              closeIcon={!loadingSave}
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='heading-3 cblack'>General Information</div>
          <Form className='ModalAssetGeneralInformationEdit__Form'>
            <Form.Field control={Input}
                        label='Title of Asset'
                        placeholder='Asset title'
                        name='title'
                        value={title || ''}
                        disabled={loadingSave}
                        onChange={(e, { value }) => onChange({ name: 'title', value })} />
            <Form.Field control={Input}
                        label='Close Destination'
                        placeholder='Address'
                        name='closeLocation'
                        value={closeLocation || ''}
                        disabled={loadingSave}
                        onChange={(e, { value }) => onChange({ name: 'closeLocation', value })} />
            <Form.Field control={Input}
                        label='Channel / Author'
                        placeholder='Name of Channel / Author'
                        name='videoCreator'
                        value={videoCreator || ''}
                        disabled={loadingSave}
                        onChange={(e, { value }) => onChange({ name: 'videoCreator', value })} />
            <Form.Field control={TextArea}
                        label='Description'
                        placeholder='Description'
                        name='description'
                        rows={10}
                        value={description || ''}
                        disabled={loadingSave}
                        onChange={(e, { value }) => onChange({ name: 'description', value })}/>
            { hideDisablePlaylist ? null : (
              <Form.Field control={Checkbox}
                          label='Hide Related Videos'
                          toggle
                          name='disablePlaylist'
                          checked={disablePlaylist || false}
                          disabled={loadingSave}
                          onChange={(e, { checked }) => {
                            onChange({ name: 'disablePlaylist', value: checked })
                          }}/>
            ) }
            { hideZoomIn ? null : (
              <Form.Field control={Checkbox}
                          toggle={true}
                          label='Open as a fullscreen video?'
                          name='zoomIn'
                          checked={zoomIn || false}
                          disabled={loadingSave}
                          onChange={(e, { checked }) => {
                            onChange({ name: 'zoomIn', value: checked })
                          }} />
            )}
            { hideAutoreplay ? null : (
              <Form.Field control={Checkbox}
                          label='Replay video on the end? (loop)'
                          toggle
                          name='autoreplay'
                          checked={autoreplay || false}
                          disabled={loadingSave}
                          onChange={(e, { checked }) => {
                            onChange({ name: 'autoreplay', value: checked })
                          }}/>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          { loadingSave && loadingProgress != undefined ? (
            <Progress className='ModalAssetGeneralInformationEdit__Actions__ProgressBar'
                      percent={loadingProgress}
                      progress
                      color='green' />
          ) : null}
          { onClickPrevGeneralInformation ?
            <Button onClick={onClickPrevGeneralInformation}
                    disabled={loadingSave} >
              <div className='body-bold'>Previous</div>
            </Button>
          : (
            <Button onClick={() => onClose()}
                    disabled={loadingSave} >
              <div className='body-bold'>Cancel</div>
            </Button>
          ) }
          <Button color='red'
                  onClick={onClickSave}
                  loading={loadingSave}
                  disabled={loadingSave} >
              <div className='body-bold'>Save</div>
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}