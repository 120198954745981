import './style.css';

import React, { Component } from 'react';
import 'rc-slider/assets/index.css';

import { Modal, Button } from 'semantic-ui-react';

import ProductSearchContainer from '../../containers/ProductSearch';

export default class AssetVideoEditProduct extends Component {
  state = {};

  render() {
    const { open, onClose, selectedProduct, onChangeProduct, onClickSave } = this.props;

    return (
      <Modal  size='large'
              open={open}
              onClose={onClose}
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='AssetVideoEditProduct__Content'>
            <div className='AssetVideoEditProduct__Content__Right'>
              <div className='AssetVideoEditProduct__Content__Right__Title'>
                <div className='heading-3 cblack'>Select the product</div>
              </div>
              <div className='AssetVideoEditProduct__Content__Right__ProductSearch'>
                <ProductSearchContainer onChange={onChangeProduct}
                                        name='product'
                                        product={selectedProduct} />
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red'
                  onClick={onClickSave} >
              <div className='body-bold'>Save</div>
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}