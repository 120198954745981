import './style.css';

import download from 'downloadjs';
import React, { Component, Fragment } from 'react';
import ReactPlayer from 'react-player';
import { Card, Button, Image, Popup, Dimmer, Loader, Dropdown } from 'semantic-ui-react';
import { EditOutlined, Code, AccessTime, Link, Check, Dehaze, Close, VideoLabel, CloudDownload, CodeOutlined, DeleteOutline } from '@material-ui/icons';
import { EnumAssetType, EnumStatusAsset, generateFileUrl, generateJSONUrl } from '../../models/Asset';
import Moment from 'react-moment';
import StatusAsset from '../StatusAsset';
import TypeAsset from '../TypeAsset';
import AssetGeneralInformationEditContainer from '../../containers/AssetGeneralInformationEdit';
import AssetImageEditContainer from '../../containers/AssetImageEdit';
import AssetSynchronizeToYoutubeContainer from '../../containers/AssetSynchronizeToYoutube';
import AssetSynchronizeToFacebookContainer from '../../containers/AssetSynchronizeToFacebook';
import AssetGroupEditContainer from '../../containers/AssetGroupEdit';
import AssetDescriptionContainer from '../../containers/AssetDescription';
import EmbedMiniClip from '../EmbedMiniClip';
import { mdiYoutube, mdiCloudUpload, mdiLibraryMovie, mdiDeleteForever, mdiLogout, mdiFacebook, mdiPlus, mdiCamera } from '@mdi/js'
import Icon from '@mdi/react'
import { isUserMaster } from '../../models/User';
import AssetVideoEditProductContainer from '../../containers/AssetVideoEditProduct';
import AssetVideoEditLookContainer from '../../containers/AssetVideoEditLook';
import ChangeLookMainImage from '../../containers/ChangeLookMainImage';

export default class AssetDetail extends Component {
  state = {
    isAssetDescriptionOpened: false,
    isAssetEditOpened: false,
    isEmbedMiniClipOpened: false
  };

  getPrettyTime = (time) => {
    const withZero = (n) => (String(n).length > 1 ? n : `0${n}`);
    const seconds = withZero(Math.floor(time % 60));
    const minutes = withZero(Math.floor(time / 60));
    return `${minutes}:${seconds}`;
  }

  handleOpenAssetGeneralInformationEdit = () => {
    this.setState({
      isAssetGeneralInformationEditOpened: true
    });
  }

  handleCloseAssetGeneralInformationEdit = (values) => {
    this.setState({
      isAssetGeneralInformationEditOpened: false
    });

    if (values) {
      const { title, description, closeLocation } = values;
      this.props.onAfterEditGeneralInformation({ title, description, closeLocation });
    }
  }

  handleClickDownload = (fileUrl) => {
    return () => download(fileUrl);
  }

  onClickOpenEmbedMiniClip = (miniClipUrl) => {
    return () => {
      this.setState({ isEmbedMiniClipOpened: true, miniClipUrlToEmbed: miniClipUrl });
    }
  }

  onClickCloseEmbedMiniClip = () => {
    this.setState({ isEmbedMiniClipOpened: false });
  }

  onClickEditImageProducts = () => {
    this.setState({ isAssetEditImageProductsOpened: true });
  }

  onCloseEditImageProducts = (hasChanged) => {
    this.setState({ isAssetEditImageProductsOpened: false });

    if (hasChanged) {
      this.props.afterCloseAssetEditShoppableImage();
    }
  }

  handleOpenSynchronizeYoutube = () => {
    this.setState({
      isSynchronizeYoutubeOpened: true
    });
  }

  handleCloseSynchronizeYoutube = (isPublished) => {
    this.setState({
      isSynchronizeYoutubeOpened: false
    });

    if (isPublished) {
      this.props.afterPublishYoutubeVideo();
    }
  }

  handleOpenChooseOrder = () => {
    this.setState({
      isChooseOrderOpened: true
    });
  }

  handleCloseChooseOrder = (isChanged) => {
    this.setState({
      isChooseOrderOpened: false
    });

    if (isChanged) {
      this.props.onAfterChooseOrder();
    }
  }

  handleOpenAssetDescription = () => {
    this.setState({
      isAssetDescriptionOpened: true
    });
  }

  handleCloseAssetDescription = () => {
    this.setState({
      isAssetDescriptionOpened: false
    });
  }

  handleOpenSynchronizeFacebook = () => {
    this.setState({
      isSynchronizeFacebookOpened: true
    });
  }

  handleCloseSynchronizeFacebook = (isPublished) => {
    this.setState({
      isSynchronizeFacebookOpened: false
    });

    if (isPublished) {
      this.props.afterPublishFacebookVideo();
    }
  }

  groupPlaylists = playlist => playlist.reduce((prev, cur, i) => {
    if (!prev.length) {
        prev.push({
            set: cur.set,
            items: [cur.name]
        });

        return prev;
    }

    const indexOfSet = prev.findIndex(({set}) => set === cur.set);

    if (indexOfSet === -1) {
        prev.push({
            set: cur.set,
            items: [cur.name]
        });

        return prev;
    }

    prev[indexOfSet].items.push(cur.name);

    return prev;
  }, []);

  handleClickOpenAddVideoProduct = () => {
    const { lastProductTime } = this.props;

    this.setState({
      isOpenProductVideoPopup: true,
      editProductVideo: undefined,
      initialTimeProduct: lastProductTime
    });
  }

  handleClickCloseEditVideoProduct = ({ product, time, addOther }) => {
    this.setState({
      isOpenProductVideoPopup: false
    }, () => {
      if (addOther) this.handleClickOpenAddVideoProduct();
    });

    if (product && this.state.editProductVideo) return this.props.onEditProduct({ newProduct: product, time, previousProduct: this.state.editProductVideo });

    if (product) this.props.onAddProduct({ product, time });
  }

  handleClickOpenAddVideoLook = () => {
    const { lastProductTime } = this.props;

    this.setState({
      isOpenLookVideoPopup: true,
      editLookVideo: undefined,
      initialTimeProduct: lastProductTime
    });

  }

  handleClickOpenEditVideoLook = (look) => {
    const _look = Array.isArray(look) ? look : [look];

    this.setState({
      isOpenLookVideoPopup: true,
      editLookVideo: _look,
      initialTimeProduct: undefined
    });
  }

  handleClickCloseEditVideoLook = ({ products, time, addOther, mainLookImage }) => {
    this.setState({
      isOpenLookVideoPopup: false
    }, () => {
      if (addOther) this.handleClickOpenAddVideoLook();
    });

    if (products && this.state.editLookVideo) return this.props.onEditProducts({ newLook: products, time, previousLook: this.state.editLookVideo, mainLookImage });

    if (products) this.props.onAddProducts({ look: products, time });
  }

  handleClickOpenChangeMainImageLook = (product, time) => {
    this.setState({
      isOpenChangeMainImageLook: true,
      changeMainImageLook: product,
      changeMainImageLookTime: time
    });
  }

  handleClickCloseChangeMainImageLook = (imageLocation) => {
    const { changeMainImageLook, changeMainImageLookTime } = this.state;

    this.setState({
      isOpenChangeMainImageLook: false
    });

    if (imageLocation) {
      this.props.onEditProducts({
        newLook: changeMainImageLook,
        time: changeMainImageLookTime,
        previousLook: changeMainImageLook,
        mainLookImage: imageLocation
      });
    }
  }

  render () {
    const { asset, products, onClickGetLink, isLinkCopied, onLoadedMiniClip, onClickProductLink, onClickGetEmbbedCode, assets, previewUrlDashboard, companyDomain, onClickTreeAssets, onClickApprove, isLoadingApproveOrDecline, onClickDecline, onChangeJSONFile, onClickAssetDetails, onClickGenerateMiniClips, onClickRemoveMiniclip, isLoadingProducts, onClickDeleteProduct, onClickDeleteLook } = this.props;
    const { company, name, type, filePath, title, createdAt, status, youtubeId, closeLocation, facebookId, videoCreator } = asset;
    const { isEmbedMiniClipOpened, miniClipUrlToEmbed, isAssetEditImageProductsOpened, isSynchronizeYoutubeOpened, isChooseOrderOpened, isAssetDescriptionOpened, isSynchronizeFacebookOpened, isOpenProductVideoPopup, editProductVideo, initialTimeProduct, isOpenLookVideoPopup, editLookVideo, isOpenChangeMainImageLook, changeMainImageLook, isAssetGeneralInformationEditOpened } = this.state;

    const isVideo = type && type.value === EnumAssetType.VIDEO;
    const isYoutubeVideo = type && type.value === EnumAssetType.YOUTUBE_VIDEO;
    const isSlideshow = type && type.value === EnumAssetType.SLIDESHOW;
    const isPlaylist = type && type.value === EnumAssetType.PLAYLIST;
    const isImage = type && type.value === EnumAssetType.IMAGE;
    const isImagePng = type && type.value === EnumAssetType.IMAGE_PNG;

    const isStatusApproved = status && status.value === EnumStatusAsset.APPROVED;
    const isStatusReview = status && status.value === EnumStatusAsset.REVIEW;
    const isStatusInQueue = status && status.value === EnumStatusAsset.IN_QUEUE;
    const fileUrl = filePath ? filePath : type ? generateFileUrl({ company, name, type: type.value }) : '';

    const assetsGrouped = asset.assets ? [...this.groupPlaylists(asset.assets)] : null;

    const mountProductList = () => products && products.length ?
      products
        .filter(product => !isVideo || (Array.isArray(product) || (product.currentTime !== undefined && product.currentTime !== null)))
        .map((product, i) => {
          const isLook = Array.isArray(product);

          const _product = isLook ? product.find(({ main }) => Boolean(main)) : product;
          const imageSrc = _product.mainLookImage ? _product.mainLookImage : (_product.images && _product.images[0]);

          const hasLink = Boolean(_product.link);

          return (
            <div  className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper'
                  key={i}>
              { isVideo ? (
                <div className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper body-bold cgrey2'>
                  <div
                    className="AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Time">
                    <AccessTime fontSize='small'
                                className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Icon' />
                    { this.getPrettyTime(_product.currentTime) }
                  </div>
                  <div className="AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Actions">
                    <div  className="AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Actions__Action"
                          onClick={isLook ? () => this.handleClickOpenEditVideoLook(product) : () => this.handleClickOpenEditVideoLook(product)}>
                      <EditOutlined fontSize='small' />
                    </div>
                    <div  className="AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Actions__Action"
                          onClick={isLook ? () => onClickDeleteLook(product) : () => onClickDeleteProduct(product)}>
                      <DeleteOutline  fontSize='small' />
                    </div>
                  </div>
                  { _product.hasMiniClip ? (
                    <Dropdown item
                              icon='ellipsis vertical'
                              simple
                              className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Dropdown' >
                      <Dropdown.Menu className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Dropdown__Menu'>
                        <Dropdown.Item onClick={this.handleClickDownload(_product.miniClipUrl)}>
                          <span className='caption cgrey'>
                            <CloudDownload  fontSize='small'
                                            className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Dropdown__Icon' />
                            Download Miniclip
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={this.onClickOpenEmbedMiniClip(_product.miniClipUrl)}>
                          <span className='caption cgrey'>
                            <CodeOutlined fontSize='small'
                                          className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Dropdown__Icon' />
                            Embed
                          </span>
                        </Dropdown.Item>
                        { isUserMaster() ? (
                          <Fragment>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => onClickRemoveMiniclip(_product.id)}>
                              <span className='caption cgrey'>
                                <Icon path={mdiDeleteForever}
                                      size={0.9}
                                      className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__HeaderWrapper__Dropdown__Icon' />
                                Remove
                              </span>
                            </Dropdown.Item>
                          </Fragment>
                        ) : null }
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : null }
                </div>
              ) : null }
              <div  className={`AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information
                              ${hasLink ? 'AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information--link' : ''}`}
                    onClick={hasLink ? onClickProductLink(_product) : undefined } >
                <div className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information__ImageWrapper'>
                  { isLook && product.length > 1 ? (
                    <div className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information__ImageWrapper__Image--group__ThumbnailWrapper'>
                      <Image  src={product.find(({ main }) => !main).images[0]}
                              className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information__ImageWrapper__OtherLookImage' />
                      <div className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information__ImageWrapper__MainLookImageWrapper'>
                        <Image  src={imageSrc} />
                        <Popup  content='Change main image of this look'
                                inverted={true}
                                trigger={
                            <div  className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information__ImageWrapper__MainLookImageWrapper__CameraIconWrapper'
                                  onClick={() => this.handleClickOpenChangeMainImageLook(product, _product.currentTime)}>
                              <Icon path={mdiCamera}
                                    size={1} />
                            </div> 
                                }
                        />
                      </div>
                    </div>
                  ) : (
                    <Image  src={imageSrc} />
                  ) }
                  <video  className={`AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information__ImageWrapper__MiniClip
                                      ${_product.hasMiniClip ? 'AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information__ImageWrapper__MiniClip--has' : ''}`}
                          autoPlay={true}
                          muted={true}
                          loop={true}
                          onLoadedMetadata={onLoadedMiniClip(_product)}>
                    <source src={_product.miniClipUrl}
                            type="video/mp4"/>
                  </video>
                </div>
                <div className='AssetDetailPage__ProductsWrapper__Products__ProductWrapper__Information__Title overline-bold'>
                  {_product.title}
                </div>
              </div>
            </div>
          );
        })
    : null;

    const mountAssetsList = () => {
      let count = 0;
      return assetsGrouped && assetsGrouped.length && assets && assets.length ?
        assetsGrouped.map(({ set, items }, groupedI) => {
          const assetsList = items.map((assetName, assetI) => {
            const subAsset = assets.find(({ name }) => assetName === name);
            const isSubAssetImage = subAsset.type && subAsset.type.value === EnumAssetType.IMAGE;
            const isSubAssetImagePng = subAsset.type && subAsset.type.value === EnumAssetType.IMAGE_PNG;
            const isSubAssetVideo = subAsset.type && subAsset.type.value === EnumAssetType.VIDEO;
            count++;

            return (
              <Fragment key={assetI}>
                <div  className='AssetDetailPage__AssetsWrapper__Assets__Asset__AssetWrapper'>
                  <div className='AssetDetailPage__AssetsWrapper__Assets__Asset__Id'>
                    {count}
                  </div>
                  <div className='AssetDetailPage__AssetsWrapper__Assets__Asset__ImageWrapper'>
                    { (subAsset.thumbnail || ( (subAsset.filePath || subAsset.location) && ( isSubAssetImage || isSubAssetImagePng )))
                      ? <Image src={subAsset.thumbnail || subAsset.filePath || subAsset.location} />
                      : isSubAssetVideo
                        ? <VideoLabel name='file video outline'
                                size='large'/>
                        : null
                    }
                  </div>
                  <div className='AssetDetailPage__AssetsWrapper__Assets__Asset__ColumnNameAndDate cgrey'>
                      <div className='AssetDetailPage__AssetsWrapper__Assets__Asset__ColumnNameAndDate__Info__Name body'>
                      {subAsset.title}
                      </div>
                      <div className='AssetDetailPage__AssetsWrapper__Assets__Asset__ColumnNameAndDate__Info__UploadDate caption'>
                        { subAsset.createdAt
                          ?
                            <Moment format="MMM DD, YYYY hh:mma">
                              { subAsset.createdAt }
                            </Moment>
                          : ' '
                        }
                      </div>
                      <div className='AssetDetailPage__AssetsWrapper__Assets__Asset__Link'>
                        <Icon path={mdiLogout}
                              size={1}
                              onClick={ onClickAssetDetails(subAsset) }
                                className='AssetDetailPage__AssetsWrapper__Assets__Asset__Link__Icon' />
                      </div>
                  </div>
                </div>
                <div className='HorizontalDivider'> &nbsp; </div>
              </Fragment>
            )
          });

          return assetsGrouped.length > 1 ? (
            <Fragment key={groupedI}>
              <div className='AssetDetailPage__AssetsWrapper__Assets__GroupTitle cgrey heading-3'>{set}</div>
              { assetsList }
            </Fragment>
          ) : assetsList;
        })
        :
        null;
    };

    return (
      <div className='AssetDetailPage'>
        { isAssetGeneralInformationEditOpened ?
          <AssetGeneralInformationEditContainer open={isAssetGeneralInformationEditOpened}
                                                onClose={this.handleCloseAssetGeneralInformationEdit}
                                                asset={asset} />
        : null }
        { isEmbedMiniClipOpened && miniClipUrlToEmbed
        ?
        <EmbedMiniClip  open={isEmbedMiniClipOpened}
                        source={miniClipUrlToEmbed}
                        onClose={this.onClickCloseEmbedMiniClip}/>
        :
        null
        }
        { isAssetEditImageProductsOpened ? (
          <AssetImageEditContainer  open={isAssetEditImageProductsOpened}
                                    asset={asset}
                                    name={asset ? asset.name : ''}
                                    company={asset.company}
                                    close={this.onCloseEditImageProducts} />
        ) : null }
        { isSynchronizeYoutubeOpened ? (
          <AssetSynchronizeToYoutubeContainer open={isSynchronizeYoutubeOpened}
                                              asset={asset}
                                              onClose={this.handleCloseSynchronizeYoutube} />
        ) : null }
        { isChooseOrderOpened ? (
          <AssetGroupEditContainer  open={isChooseOrderOpened}
                                    asset={asset}
                                    assets={assets}
                                    assetsGrouped={assetsGrouped}
                                    onClose={this.handleCloseChooseOrder} />
        ) : null }
        { isAssetDescriptionOpened ?
          <AssetDescriptionContainer  open={isAssetDescriptionOpened}
                                      asset={asset}
                                      onClose={this.handleCloseAssetDescription} />
        : null }
        { isSynchronizeFacebookOpened ? (
          <AssetSynchronizeToFacebookContainer  open={isSynchronizeFacebookOpened}
                                                asset={asset}
                                                onClose={this.handleCloseSynchronizeFacebook} />
        ) : null }
        { isOpenProductVideoPopup ? (
          <AssetVideoEditProductContainer product={editProductVideo}
                                          open={isOpenProductVideoPopup}
                                          asset={asset}
                                          onClose={this.handleClickCloseEditVideoProduct}
                                          assetJSON={asset}
                                          initialTimeProduct={initialTimeProduct} />
        ) : null }
        { isOpenLookVideoPopup ? (
          <AssetVideoEditLookContainer  product={editLookVideo}
                                        open={isOpenLookVideoPopup}
                                        asset={asset}
                                        onClose={this.handleClickCloseEditVideoLook}
                                        assetJSON={asset}
                                        initialTimeProduct={initialTimeProduct} />
        ) : null }
        { isOpenChangeMainImageLook ? (
          <ChangeLookMainImage  look={changeMainImageLook}
                                open={isOpenChangeMainImageLook}
                                asset={asset}
                                onClose={this.handleClickCloseChangeMainImageLook} />
          
        ) : null }
        <div className='HeaderWrapper'>
          <div  className='HeaderWrapper__Item HeaderWrapper__Item--link cred heading-3'
                onClick={onClickTreeAssets}>
            Assets
          </div>
          <span className='HeaderWrapper__Divider'>></span>
          <div className='HeaderWrapper__Item cred heading-3'>Details</div>
        </div>
        <div className='AssetDetailPage__Content'>
          <div className='AssetDetailPage__Content__Left'>
            <Card>
              <Card.Content>
                <div className='AssetDetailPage__GeneralContent__Header'>
                  <div className='heading-4 cblack'>
                    General Information
                  </div>
                  <div  className='AssetDetailPage__GeneralContent__Header__EditWrapper caption cgrey'
                        onClick={this.handleOpenAssetGeneralInformationEdit}>
                    <EditOutlined fontSize='small'
                                  className='AssetDetailPage__GeneralContent__Header__EditWrapper__Icon' />
                    Edit
                  </div>
                </div>
                <div className='HorizontalDivider'>&nbsp;</div>
                <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Name</div>
                <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>{title}</div>
                <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Type</div>
                <div className='AssetDetailPage__GeneralContent__ColumnValue caption cgrey'>
                  <TypeAsset type={type.value} />
                </div>
                <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Created At</div>
                <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>{ createdAt
                  ? <Moment format="MMM DD, YYYY | hh:mma">
                      {createdAt}
                    </Moment>
                  : ' '}</div>
                <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Status</div>
                <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>
                  { isLoadingApproveOrDecline ?
                    <Dimmer active
                            inverted >
                      <Loader />
                    </Dimmer>
                  : null }
                  <StatusAsset status={status.value} />
                  { isStatusReview ?
                  <div className='AssetDetailPage__GeneralContent__ReviewActions'>
                    <Popup  className='AssetDetailPage__GeneralContent__ReviewActions_DeclineTooltip'
                            trigger={
                      <Button onClick={onClickDecline}>
                        <Close  fontSize='small'
                                className='cred' />
                      </Button> }
                    >
                      <Popup.Content>
                        <div className='caption cwhite'>
                          Decline
                        </div>
                      </Popup.Content>
                    </Popup>
                    <Popup  className='AssetDetailPage__GeneralContent__ReviewActions_ApproveTooltip'
                            trigger={
                      <Button onClick={onClickApprove}>
                        <Check  fontSize='small'
                                className='cgreen' />
                      </Button> }
                    >
                      <Popup.Content>
                        <div className='caption cwhite'>
                          Approve
                        </div>
                      </Popup.Content>
                    </Popup>
                  </div>
                  : null }
                </div>
                { videoCreator ? (
                  <Fragment>
                    <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Name of Channel / Author</div>
                    <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>{videoCreator}</div>
                  </Fragment>
                ) : null }
                { (closeLocation || companyDomain) ? (
                <div className='AssetDetailPage__GeneralContent__ColumnName overline-bold'>Close Destination</div>
                ) : null }
                { (closeLocation || companyDomain) ? (
                <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>
                  <a href={closeLocation || companyDomain}
                     target='_blank'
                     rel="noopener noreferrer" >
                    {closeLocation || companyDomain}
                  </a>
                </div>
                ) : null }
                { isUserMaster() ?
                  <div className='AssetDetailPage__GeneralContent__ColumnName AssetDetailPage__GeneralContent__ColumnName--JSON overline-bold'>
                    JSON
                    { ( isImage || isImagePng || isVideo ) ? (
                      <Button onClick={() => this.fileRef.click()}
                              className='AssetDetailPage__GeneralContent__ColumnJSON__Upload IconButton'>
                        <Icon path={mdiCloudUpload}
                              size={0.8} />
                        <div className='body-bold'>Upload</div>
                        <input  type='file'
                                name='file'
                                onChange={onChangeJSONFile}
                                accept='application/json,.json'
                                className='AssetDetailPage__GeneralContent__ColumnJSON__Upload__FileInput'
                                ref={(ref) => this.fileRef = ref} />
                      </Button>
                    ) : null }
                  </div>
                : null }
                { isUserMaster() ?
                  <div className='AssetDetailPage__GeneralContent__ColumnValue body cgrey'>
                    { isStatusInQueue ? null : (
                      <a  href={generateJSONUrl({ company, name, type: type.value })}
                          target='_blank'
                          rel="noopener noreferrer" >
                        { generateJSONUrl({ company, name, type: type.value }) }
                      </a>
                    ) }
                  </div>
                : null }
              </Card.Content>
            </Card>
            { isVideo && isStatusApproved ? (
              <Card>
                <Card.Content>
                  <div className='AssetDetailPage__ShareContent__Header'>
                    <div className='heading-4 cblack'>
                      Social Network
                    </div>
                  </div>
                  <div className='HorizontalDivider'>&nbsp;</div>
                  <div  className='AssetDetailPage__ShareContent__BoxWrapper'
                        onClick={this.handleOpenSynchronizeYoutube}>
                    <div className='AssetDetailPage__ShareContent__BoxWrapper__IconWrapper cgrey'>
                      <Icon path={mdiYoutube}
                            size={1} />
                    </div>
                    <div className='AssetDetailPage__ShareContent__BoxWrapper__ActionWrapper'>
                      <div className='AssetDetailPage__ShareContent__BoxWrapper__ActionWrapper__ActionTitle body-bold cgrey'>Youtube</div>
                      <div className='overline cblack'>{ youtubeId ? 'Update video description on channel' : 'Publish video to Youtube channel'}</div>
                    </div>
                  </div>
                  {/* { !facebookId ? (
                    <div  className='AssetDetailPage__ShareContent__BoxWrapper'
                          onClick={this.handleOpenSynchronizeFacebook}>
                      <div className='AssetDetailPage__ShareContent__BoxWrapper__IconWrapper cgrey'>
                        <Icon path={mdiFacebook}
                              size={1} />
                      </div>
                      <div className='AssetDetailPage__ShareContent__BoxWrapper__ActionWrapper'>
                        <div className='AssetDetailPage__ShareContent__BoxWrapper__ActionWrapper__ActionTitle body-bold cgrey'>Facebook</div>
                        <div className='overline cblack'>Publish video to Facebook channel</div>
                      </div>
                    </div>
                  ) : null } */}
                </Card.Content>
              </Card>
            ) : null }
            { isStatusApproved ?
            <Card>
              <Card.Content>
                <div className='AssetDetailPage__ShareContent__Header'>
                  <div className='heading-4 cblack'>
                    Share
                  </div>
                </div>
                <div className='HorizontalDivider'>&nbsp;</div>
                <div  className='AssetDetailPage__ShareContent__BoxWrapper'
                      onClick={onClickGetLink}>
                  <div  className='AssetDetailPage__ShareContent__BoxWrapper__IconWrapper cgrey'>
                    { isLinkCopied ?
                    <Check  fontSize='small'
                            className='cgreen' /> :
                    <Link fontSize='small' /> }
                  </div>
                  <div className='AssetDetailPage__ShareContent__BoxWrapper__ActionWrapper'>
                    <div className={`AssetDetailPage__ShareContent__BoxWrapper__ActionWrapper__ActionTitle body-bold
                                      ${isLinkCopied ? 'cgreen' : 'cgrey'}`}>
                      { isLinkCopied ? 'Copied to clipboard' : 'Get Link' }
                    </div>
                    <div className='overline cblack'>Use it linking on Social Network ads</div>
                  </div>
                </div>
                { isVideo || isImage || isImagePng ?
                <div  className='AssetDetailPage__ShareContent__BoxWrapper'
                      onClick={onClickGetEmbbedCode}>
                  <div className='AssetDetailPage__ShareContent__BoxWrapper__IconWrapper cgrey'>
                    <Code fontSize='small' />
                  </div>
                  <div className='AssetDetailPage__ShareContent__BoxWrapper__ActionWrapper'>
                    <div className='AssetDetailPage__ShareContent__BoxWrapper__ActionWrapper__ActionTitle body-bold cgrey'>Embed</div>
                    <div className='overline cblack'>Use this code to insert this asset into your HTML page</div>
                  </div>
                </div>
                : null }
                { isVideo || isImage || isImagePng ?
                <div  className='AssetDetailPage__ShareContent__BoxWrapper'
                      onClick={this.handleOpenAssetDescription}>
                  <div className='AssetDetailPage__ShareContent__BoxWrapper__IconWrapper cgrey'>
                    <Dehaze fontSize='small' />
                  </div>
                  <div className='AssetDetailPage__ShareContent__BoxWrapper__ActionWrapper'>
                    <div className='AssetDetailPage__ShareContent__BoxWrapper__ActionWrapper__ActionTitle body-bold cgrey'>Description</div>
                    <div className='overline cblack'>List of asset's products and PDP pages</div>
                  </div>
                </div>
                : null }
              </Card.Content>
            </Card>
            : null }
          </div>
          <div className='AssetDetailPage__Content__Right'>
            <Card>
              <Card.Content>
                <div className='AssetDetailPage__PreviewContent__Header'>
                  <div className='heading-4 cblack'>
                    Preview
                  </div>
                </div>
                <div className='HorizontalDivider'>&nbsp;</div>
                <div className='AssetDetailPage__PreviewContent__PreviewWrapper'>
                { isStatusApproved || isStatusReview ?
                  <iframe src={previewUrlDashboard}
                          title='preview'
                          frameBorder='0'
                          style={{
                            width: '100%',
                            height: '400px'
                          }} />
                  :
                  <Fragment>
                      { isImage || isImagePng ?
                        <Image  src={fileUrl}
                                className='AssetDetailPage__PreviewContent__PreviewWrapper__Image' />
                      : null }
                      { isVideo || isYoutubeVideo ?
                        <ReactPlayer
                          className='AssetDetailPage__PreviewContent__PreviewWrapper__Video'
                          config={{
                            youtube: {
                              playerVars: {
                                showinfo: 0,
                                controls: 1,
                                title: 0,
                                modestbranding: 1,
                                rel: 0,
                                autohide: 1,
                                iv_load_policy: 3,
                                fullscreen: 0,
                                disablekb: 1,
                                autoplay: false
                              }
                            }
                          }}
                          url={(youtubeId) ? `https://www.youtube.com/watch?v=${youtubeId}` : fileUrl}
                          height={youtubeId ? '400px' : '100%'}
                          width="100%"
                          playing={false}
                          volume={0}
                          loop={false}
                          muted={true}
                          controls={true}
                        />
                      : null }
                  </Fragment>
                  }
                </div>
              </Card.Content>
            </Card>
            { !isSlideshow && !isPlaylist ?
                isStatusApproved ? (
                  <Card>
                    <Card.Content>
                      <div className='AssetDetailPage__ProductsWrapper__Header'>
                        { isLoadingProducts ?
                          <Dimmer active
                                  inverted >
                            <Loader />
                          </Dimmer>
                        : null }
                        <div className='heading-4 cblack AssetDetailPage__ProductsWrapper__Header__Title'>
                          Products in this asset
                          { isUserMaster() ?
                          <Button className='AssetDetailPage__ProductsWrapper__Header__GenerateMiniClip IconButton'
                                  onClick={onClickGenerateMiniClips}>
                            <Icon path={mdiLibraryMovie}
                                  size={0.8} />
                            <div className='body-bold'>Generate Mini Clips</div>
                          </Button>
                        : null }
                        </div>
                        { isVideo ? (
                          <div className='AssetDetailPage__ProductsWrapper__Header__EditWrapper caption cgrey'>
                            <div  className='AssetDetailPage__ProductsWrapper__Header__EditWrapper__ActionWrapper'
                                  onClick={this.handleClickOpenAddVideoLook}>
                              <Icon path={mdiPlus}
                                    size={0.8}
                                    className='AssetDetailPage__ProductsWrapper__Header__EditWrapper__Icon' />
                              Tag Product
                            </div>
                            {/* <div  className='AssetDetailPage__ProductsWrapper__Header__EditWrapper__ActionWrapper'
                                  onClick={this.handleClickOpenAddVideoProduct}>
                              <Icon path={mdiPlus}
                                    size={0.8}
                                    className='AssetDetailPage__ProductsWrapper__Header__EditWrapper__Icon' />
                              Add Product
                            </div> */}
                          </div>
                        ) : (
                          <div className='AssetDetailPage__ProductsWrapper__Header__EditWrapper caption cgrey'
                              onClick={this.onClickEditImageProducts}>
                            <EditOutlined fontSize='small'
                                          className='AssetDetailPage__ProductsWrapper__Header__EditWrapper__Icon' />
                            Edit
                          </div>
                        ) }
                      </div>
                      <div className='HorizontalDivider'>&nbsp;</div>
                      { products && products.length ? (
                        <div className='AssetDetailPage__ProductsWrapper__Products'>
                          {mountProductList()}
                        </div>
                      ) : !isLoadingProducts ? (
                        <div className='AssetDetailPage__ProductsWrapper__AddProducts'>
                          <div className='AssetDetailPage__ProductsWrapper__AddProducts__Text cblack'>
                            You don't have products in this asset
                          </div>
                          <div className='AssetDetailPage__ProductsWrapper__AddProducts__button'>
                            <Button icon='plus'
                                    size='medium'
                                    content='Tag product'
                                    onClick={isVideo ? this.handleClickOpenAddVideoLook : this.onClickEditImageProducts} />
                          </div>
                        </div>
                      ) : null }
                    </Card.Content>
                  </Card>
                ) : ( null )
                : null }
            { isStatusApproved && (isSlideshow || isPlaylist) ?
              <Card>
                <Card.Content>
                  <div className='AssetDetailPage__AssetsWrapper'>
                    <div className='AssetDetailPage__AssetsWrapper__Header'>
                      <div className='heading-4 cblack'>
                        { isPlaylist ? 'Videos' : 'Images' } in this asset
                      </div>
                      <div  className='AssetDetailPage__AssetsWrapper__Header__EditWrapper caption cgrey'
                            onClick={() => this.handleOpenChooseOrder()}>
                        <EditOutlined fontSize='small'
                                      className='AssetDetailPage__AssetsWrapper__Header__EditWrapper__Icon' />
                        Edit
                      </div>
                    </div>
                    <div className='HorizontalDivider'>&nbsp;</div>
                    <div className='AssetDetailPage__AssetsWrapper__Assets'>
                      {mountAssetsList()}
                    </div>
                  </div>
                </Card.Content>
              </Card>
            : null }
          </div>
        </div>
      </div>
    );
  }
}
