import './style.css';
import React from 'react';
import { Modal, Button } from 'semantic-ui-react';

export default ({
  open,
  onClose = () => null,
  onConfirm = () => null
}) => (
  <Modal
      size='tiny'
      open={open}
      onClose={() => onClose()}
      closeIcon
      closeOnEscape={false}
      closeOnDimmerClick={false}
      className='ModalDisconnect'>
    <Modal.Content>
    <div className='heading-3 cblack'>
      Confirm disconnection!
    </div>
    <div
      className='ModalDisconnect__Description'>
      <p>On disconnect this current e-commerce, your existing existent assets can break.</p>
      <p>All products and cart actions use the current connection to work, removing it, old assets will stop to work.</p>
    </div>
    </Modal.Content>
    <Modal.Actions>
      <Button color='red'
            onClick={() => onConfirm()} >
        <div className='body-bold'>Confirm</div>
      </Button>
    </Modal.Actions>
  </Modal>
)