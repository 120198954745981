import './style.css';

import React, {Component} from 'react';
import gql from 'graphql-tag';
import {Modal, Button, Form} from 'semantic-ui-react';
import {compose, graphql} from 'react-apollo';
import _get from 'lodash/get';
import CompanyService from "../../tools/company";
import {VERTICAL_ID} from "../../tools/vertical";

class ModalCreateVertical extends Component {
  state = {};

  handleChangeTitle = (title) => {
    this.setState({title})
  }

  handleCreateVertical = () => {
    const {title} = this.state;
    const company = _get(CompanyService.get(), 'name');
    const variables = {
      title,
      company,
    }
    this.props.createVertical({variables}).then(() => {
      this.props.onClose();
    })
  }

  render() {
    const {open, onClose} = this.props;
    const {title} = this.state;

    return (
      <Modal
        size='tiny'
        open={open}
        onClose={() => onClose()}
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}>
        <Modal.Content>
          <div className='heading-3 cblack'>Create vertical</div>
          <Form.Field
            className='SettingsInput'>
            <label>Title</label>
            <Form.Input
              fluid
              name='name'
              onChange={value => this.handleChangeTitle(value.target.value)}
              value={title}
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red'
                  onClick={() => this.handleCreateVertical()}
          >
            <div className='body-bold'>Save</div>
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const CreateVerticalMutation = gql`
 mutation createVertical($title: String!, $company: String!) {
    createVertical(title: $title, company: $company) {
      id
    }
 }
`;

const componentGql = compose(
  graphql(CreateVerticalMutation, {
    name: 'createVertical'
  })
)


export default componentGql(ModalCreateVertical);