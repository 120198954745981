import './style.css';

import React, { Component } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { mdiCloudUpload } from '@mdi/js';
import Icon from '@mdi/react'

export default class ProductUpload extends Component {
  fileRef;

  onChangeFile = ({ target: { files } }) => {
    const { onChangeFile } = this.props;
    if (!files) { return; }
    onChangeFile(files[0]);
  }

  onSave = () => {
    this.props.onSave(this.fileRef);
  }

  render() {
    const { open, onClose, file } = this.props;

    return (
      <Modal  open={open}
              size='small'
              onClose={() => onClose()}
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='heading-3 cblack'>Upload products.csv</div>
          <div className='body cgrey'>Import products in bulk using a csv file. <a download href="http://assets.acuityai.com/cdn/products.csv">Download csv example here</a></div>
          <div className='ProductsImport__DropSpace cgrey body'>
            <input  type='file'
                    name='file'
                    accept='.csv'
                    onChange={this.onChangeFile}
                    className='ProductsImport__DropSpace__FileInput'
                    ref={(ref) => this.fileRef = ref} />
            <Icon path={mdiCloudUpload}
                  size={1.5} />
            <div>{ (file && file.name) ? file.name : 'Click to upload or just drop a file from your computer here' }</div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red'
                  disabled={!file}
                  onClick={this.onSave} >
            <div className='body-bold'>Upload csv</div>
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}