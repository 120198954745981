import './style.css';

import React, { Component } from 'react';
import { ChromePicker } from 'react-color';

export default class ButtonColorPicker extends Component {
  state = {
    displayColorPicker: false
  };

  componentWillReceiveProps(props) {
    if (this.props.color && props.color && props.color !== this.props.color) {
      this.setState({ color: props.color });
    }
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    if (this.state.colorResult && this.props.onClosePicker) {
      this.props.onClosePicker(this.state.colorResult);
    }
  };

  handleChangeComplete = (_color) => {
    const { r, g, b, a } = _color.rgb;
    const color = `rgba(${r},${g},${b},${a})`;

    this.setState(Object.assign({}, this.state, {
      color,
      colorResult: _color
    }));

    if (this.props.onChangeComplete) {
      this.props.onChangeComplete(_color);
    }
  };

  render() {
    const color = this.state.color || this.props.color;

    if (!color) {
      return null;
    }

    const button = {
      backgroundColor: color,
    };

    return (
      <div className='ButtonColorPicker'>
        <button className='ButtonColorPicker__Button'
                onClick={ this.handleClick }
                style={ button }>
          &nbsp;
        </button>
        { this.state.displayColorPicker
          ? <div className='ButtonColorPicker__PopOver'>
              <div  className='ButtonColorPicker__PopOver__Cover'
                    onClick={ this.handleClose }/>
              <ChromePicker onChangeComplete={ this.handleChangeComplete }
                            color={color}
                            disableAlpha={this.props.disableAlpha}/>
            </div>
          : null }
      </div>
    )
  }
}