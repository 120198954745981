import './style.css';

import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';

export default class ConfirmAssetEditRestoreDataModal extends Component {
  render() {
    const { onClose, open } = this.props;

    return (
      <Modal  size='tiny'
              open={open}
              onClose={() => onClose()}
              className='ConfirmAssetEditRestoreDataModal'
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='heading-3 cblack ConfirmAssetEditRestoreDataModal__ConfirmActionsTitle'>Restore Data</div>
          <div className='body cblack'>Are you sure you want to reset changes to default state? You will lose all changes made.</div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => onClose()} >
              <div className='body-bold'>Cancel</div>
          </Button>
          <Button color='red'
                  onClick={() => onClose(true)} >
              <div className='body-bold'>Restore</div>
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}