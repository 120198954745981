import './style.css';

import React, {Component, Fragment} from 'react';
import {Card, Button} from 'semantic-ui-react';
import {Add} from '@material-ui/icons';
import Moment from 'react-moment';
import ListSortableHeader from '../List/sortable-header';
import ModalCreateVertical from "../ModalCreateVertical";

export default class VerticalList extends Component {
  state = {
    isCreateVerticalOpened: false,
  }

  handleCreateVertical = () => {
    this.setState({ isCreateVerticalOpened: true });
  }

  handleCloseCreateVertical = () => {
    this.setState({ isCreateVerticalOpened: false });
    this.props.onRefetchVertical();
  }

  render() {
    const {
      verticals,
      onClickDetails,
      orderBy,
      onChangeOrder,
    } = this.props;
    const { isCreateVerticalOpened } = this.state;

    const verticalList = verticals.map(group => {
      return (
        <Fragment key={`${group.company}-${group.createdAt}`}>
          <div className='AssetList__Content__Asset'>
            <div className='AssetList__Header__Column AssetList__Header__ColumnName body'>
              {group.title}
            </div>
            <div className='AssetList__Header__Column AssetList__Header__ColumnCreatedAt caption'>
              <Moment format="MMM DD, YYYY hh:mma">{group.createdAt}</Moment>
            </div>
            <div className='AssetList__Header__Column AssetList__Header__ColumnActions overline-bold'>
              <Button onClick={onClickDetails(group)}
                      className='AssetList__Header__ColumnActions__Detail'>
                <div className='body-bold'>Details</div>
              </Button>
            </div>
          </div>
          <div className='HorizontalDivider'/>
        </Fragment>
      );
    });

    return (
      <div className='AssetListPage'>
        {isCreateVerticalOpened ?
          <ModalCreateVertical
            onClose={this.handleCloseCreateVertical}
            open={isCreateVerticalOpened}
          />
          : null}
        <div className='cred heading-3'>
          Verticals
        </div>
        <Card>
          <Card.Content>
            <div className='AssetListWrapper__Header'>
              <div className='AssetListWrapper__Header__CreateAssetWrapper'>
                <Button color='red'
                        className='IconButton'
                        onClick={this.handleCreateVertical}
                >
                  <Add fontSize='small'/>
                  <div className='body-bold'>Create Vertical</div>
                </Button>
              </div>
              <div className='AssetListWrapper__Header__RightActions'>
                {/* <Popup trigger={<Button disabled={!hasSelectedItem}
                                        onClick={this.onClickOpenConfirmDelete}>
                  <DeleteOutline fontSize='small'/>
                </Button>}
                       content='Delete'
                       hideOnScroll/>
                <Input placeholder='Search'
                       name='search'
                       icon='search'
                       onChange={onChangeSearch}/> */}
              </div>
            </div>
            <div className='AssetList'>
              <div className='AssetList__Header'>
                <div className='AssetList__Header__Column AssetList__Header__ColumnName overline-bold'>
                  <ListSortableHeader text='NAME'
                                      fieldName='title'
                                      orderBy={orderBy}
                    // onChangeOrder={onChangeOrder}
                                      onChangeOrder={() => {
                                      }}
                  />
                </div>
                <div className='AssetList__Header__Column AssetList__Header__ColumnCreatedAt overline-bold'>
                  <ListSortableHeader text='CREATED AT'
                                      fieldName='createdAt'
                                      orderBy={orderBy}
                    // onChangeOrder={onChangeOrder}
                                      onChangeOrder={() => {
                                      }}
                  />
                </div>
                <div className='AssetList__Header__Column AssetList__Header__ColumnActions overline-bold'>
                  &nbsp;
                </div>
              </div>
              <div className='AssetList__Content'>
                {verticalList}
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    );
  }
}
