import axios from 'axios';
import gql from 'graphql-tag';
import React, { Component } from 'react';
import { compose, graphql } from 'react-apollo';

import { EnumAssetType, EnumStatusAsset } from '../../models/Asset';
import CompanyPlayer from '../../components/CompanyPlayer';

class CompanyPlayerContainer extends Component {
  state = { };

  hasLoadedCompanyTheme = false;
  hasLoadedAssetList = false;

  componentWillReceiveProps(props) {
    if (!this.hasLoadedCompanyTheme && !props.companyQuery.loading && props.companyQuery.company && props.companyQuery.company.name) {
      this.hasLoadedCompanyTheme = true;
      const { companyTheme } = props.companyQuery.company;

      let theme;
      const state = {};

      if (companyTheme) {
        const { logo, header, logoLoader, enableProductMap } = companyTheme;

        this.initialLogo = logo;
        this.initialHeader = header;
        this.initialLogoLoader = logoLoader;

        theme = companyTheme.theme;

        Object.assign(state, { logo, header, enableProductMap });
      }

      if (props.companyQuery.company.theme) {
        theme = props.companyQuery.company.theme;
      }

      if (theme) {
        Object.assign(state, { ...theme });
        this.initialTheme = { ...theme };
      } else {
        this.initialTheme = { ...(this.generateColorsFromMainColor({ r: 183, g: 7, b: 46, a: 1 })) };
        Object.assign(state, this.initialTheme);
      }

      this.setState(state);
    }

    if (!this.hasLoadedAssetList && !props.assetListQuery.loading && props.assetListQuery.assets) {
      this.hasLoadedAssetList = true;

      const approvedVideoAssets = props.assetListQuery.assets
        .filter((asset) => ((asset.type && asset.type.value === EnumAssetType.VIDEO) &&
                                    (asset.status && asset.status.value === EnumStatusAsset.APPROVED)));

      const assetToAdPreview = approvedVideoAssets.filter((asset) => Boolean(asset.filePath || asset.location || asset.youtubeId));
      if (assetToAdPreview.length) {
        this.setState({
          previewAdUrl: `${process.env.REACT_APP_APP_URL}/${assetToAdPreview[0].company}/${assetToAdPreview[0].name}/mp4`
        });
      }
      
      const assetToWebPreview = approvedVideoAssets.filter((asset) => Boolean(asset.filePath || asset.videoSrc));
      if (assetToWebPreview.length) {
        this.setState({
          previewWebVideoUrl: assetToWebPreview[0].filePath || assetToWebPreview[0].videoSrc,
          previewWebVideoName: assetToWebPreview[0].name,
          appendWebVideoShoppable: true
        });
      }
    }
  }

  handleChangeTheme = (color) => {
    const oldMain = this.state.main;

    const { main, ...colorTheme } = this.generateColorsFromMainColor(color.rgb);

    if (main === oldMain) {
      return;
    }

    this.setState(Object.assign(this.state, { main, ...colorTheme }));

    this.testAdProductChange();
  }

  generateColorsFromMainColor = (mainColor) => {
    const { r, g, b, a } = mainColor;

    const isLight = ((r*0.299 + g*0.587 + b*0.114) > 186);

    return Object.assign({
      '--main': `rgba(${r},${g},${b},${a})`,
      '--primary': `rgba(${r},${g},${b},0.00)`,
      '--secondary': `rgba(${r},${g},${b},0.80)`,
      '--track-border': `rgba(${r},${g},${b},${a})`,
      '--track-color': `rgba(${r},${g},${b},${a})`,
      '--product-primary': `rgba(${r},${g},${b},0.60)`,
      '--product-secondary': `rgba(${r},${g},${b},0.20)`,
      '--track-border-active': isLight ? `rgba(0, 0, 0, 1)` : `rgba(255, 255, 255, 1)`,
      '--track-color-active': `rgba(${r},${g},${b},${a})`
    }, (this.isDarkLoader() ? this.generateDarkLoaderColors() : this.generateLightLoaderColors(mainColor)));
  }

  generateLightLoaderColors = (mainColor) => {
    let loaderColor;
    if (mainColor) {
      const { r, g, b, a } = mainColor;
      loaderColor = `rgba(${r},${g},${b},${a})`
    } else {
      loaderColor = this.state.main;
    }

    return {
      loaderBackground: `rgba(255, 255, 255, 1)`,
      loaderColor
    }
  }

  generateDarkLoaderColors = () => {
    return {
      loaderBackground: `rgba(0, 0, 0, 1)`,
      loaderColor: `rgba(255, 255, 255, 1)`
    }
  }

  handleChangeLogoFile = (file) => {
    if (file) {
      this.setState(Object.assign(this.state, {
        logoFile: file,
        urlLogoFile: URL.createObjectURL(file),
        hasSavedTestTheme: false
      }));

      this.testWebProductChange();
    }
  }

  handleChangeHeaderFile = (file) => {
    if (file) {
      this.setState(Object.assign(this.state, {
        headerFile: file,
        urlHeaderFile: URL.createObjectURL(file),
        hasSavedTestTheme: false
      }));

      this.testAdProductChange();
    }
  }

  uploadLogoTest = (logoFile) => {
    const data = new FormData();
    data.append('operations', JSON.stringify({
      operationName: 'uploadLogoTest',
      variables: {
        file: null,
        company: localStorage.getItem('company')
      },
      query: 'mutation uploadLogoTest($company: String!, $file: Upload!) {\n  uploadLogoTest(company: $company, file: $file) {\n    url\n    __typename\n  }\n}\n'
    }));

    data.append('map', JSON.stringify({
      0: ['variables.file']
    }));
    if (logoFile) {
      data.append('0', logoFile);
    }

    this.setState(Object.assign(this.state, { loadingLogoProgress: 0 }));

    return axios
      .post(`${process.env.REACT_APP_API_URL}/graphql`, data, {
        headers: {
          'authorization': 'Bearer ' + localStorage.getItem('token')
        },
        onUploadProgress: (progressEvent) => {
          this.setState(Object.assign(this.state, { loadingLogoProgress: Math.round((progressEvent.loaded * 100) / progressEvent.total) }));
        }
      })
      .then((response) => {
        this.setState(Object.assign(this.state, { loadingLogoProgress: undefined }));
        return response.data.data.uploadLogoTest.url;
      })
      .catch((error) => {
        this.setState(Object.assign(this.state, { loadingLogoProgress: undefined }));
        return error;
      });
  }

  uploadHeaderTest = (headerFile) => {
    const data = new FormData();
    data.append('operations', JSON.stringify({
      operationName: 'uploadHeaderLogoTest',
      variables: {
        file: null,
        company: localStorage.getItem('company')
      },
      query: 'mutation uploadHeaderLogoTest($company: String!, $file: Upload!) {\n  uploadHeaderLogoTest(company: $company, file: $file) {\n    url\n    __typename\n  }\n}\n'
    }));

    data.append('map', JSON.stringify({
      0: ['variables.file']
    }));
    if (headerFile) {
      data.append('0', headerFile);
    }

    this.setState(Object.assign(this.state, { loadingHeaderProgress: 0 }));

    return axios
      .post(`${process.env.REACT_APP_API_URL}/graphql`, data, {
        headers: {
          'authorization': 'Bearer ' + localStorage.getItem('token')
        },
        onUploadProgress: (progressEvent) => {
          this.setState(Object.assign(this.state, { loadingHeaderProgress: Math.round((progressEvent.loaded * 100) / progressEvent.total) }));
        }
      })
      .then((response) => {
        this.setState(Object.assign(this.state, { loadingHeaderProgress: undefined }));
        return response.data.data.uploadHeaderLogoTest.url;
      })
      .catch((error) => {
        this.setState(Object.assign(this.state, { loadingHeaderProgress: undefined }));
        return error;
      });
  }

  mountTestThemeVariables = () => {
    const {
      main,
      primary,
      secondary,
      trackBorder,
      trackColor,
      productPrimary,
      productSecondary,
      trackBorderActive,
      trackColorActive,
      logo,
      header,
      loaderBackground,
      loaderColor
    } = this.state;

    const testTheme = {
      main,
      primary,
      secondary,
      trackBorder,
      trackColor,
      productPrimary,
      productSecondary,
      trackBorderActive,
      trackColorActive,
      logo,
      header,
      logoLoader: this.isDarkLoader() ? header : logo,
      loaderBackground,
      loaderColor,
      enableProductMap: true,
      name: localStorage.getItem('company'),
    };

    return testTheme;
  }

  testWebProductChange = async () => {
    this.setState(Object.assign(this.state, { appendWebVideoShoppable: false }));

    setTimeout(async () => {
      await this.handleTestChanges();

      this.setState(Object.assign(this.state, { appendWebVideoShoppable: true }));
    }, 50);
  }

  testAdProductChange = async () => {
    await this.handleTestChanges();

    const { previewAdUrl } = this.state;

    this.setState(Object.assign(this.state, {
      previewAdUrl: (previewAdUrl && previewAdUrl.indexOf('?theme=test') === -1)
        ? `${previewAdUrl}?theme=test`
        : `${previewAdUrl}&v=1`,
    }));
  }

  handleTestChanges = async () => {
    const testTheme = this.mountTestThemeVariables();

    const state = this.state;
    const { logoFile } = state;
    if (logoFile) {
      const logoLocation = await this.uploadLogoTest(logoFile);
      testTheme.logo = `${logoLocation}?v=${new Date().valueOf()}`;
      if (!this.isDarkLoader()) {
        testTheme.logoLoader = testTheme.logo;
      }
    }

    const { headerFile } = state;
    if (headerFile) {
      const headerLocation = await this.uploadHeaderTest(headerFile);
      testTheme.header = `${headerLocation}?v=${new Date().valueOf()}`;
      if (this.isDarkLoader()) {
        testTheme.logoLoader = testTheme.header;
      }
    }

    await this.props
      .generateTestTheme({
        variables: testTheme
      });

    this.setState(Object.assign(this.state, {
      logo: testTheme.logo,
      header: testTheme.header,
      logoLoader: testTheme.logoLoader,
      enableProductMap: testTheme.enableProductMap,
      hasSavedTestTheme: true,
      logoFile: undefined,
      headerFile: undefined,
      urlLogoFile: undefined,
      urlHeaderFile: undefined
    }));
  }

  handleSave = async () => {
    this.setState(Object.assign(this.state, { isLoadingSave: true }));
    const testTheme = this.mountTestThemeVariables();

    const company = await this.props.updateTheme({  
      variables: testTheme
    });

    this.setState(Object.assign(this.state, {
      isLoadingSave: false,
      hasSavedTestTheme: false,
      isSuccessModalOpened: true
    }));

    return company;
  }

  handleUndoChanges = () => {
    const { previewAdUrl } = this.state;
    this.setState(Object.assign(this.state, {
      appendWebVideoShoppable: false,
      hasSavedTestTheme: false,
      previewAdUrl: previewAdUrl ? previewAdUrl.split('?')[0] : '',
      headerFile: undefined,
      urlHeaderFile: undefined,
      logoFile: undefined,
      urlLogoFile: undefined,
      logo: this.initialLogo,
      header: this.initialHeader,
      ...this.initialTheme
    }));

    setTimeout(() => {
      this.setState(Object.assign(this.state, {
        appendWebVideoShoppable: true
      }));
    }, 50);
  }

  handleClickAlertOk = () => {
    this.setState(Object.assign(this.state, { isSuccessModalOpened: false }));
  }

  isDarkLoader = () => {
    return this.state.loaderColor !== this.state.main;
  }

  handleCheckLightLoader = () => {
    this.setState(this.generateLightLoaderColors(), () => this.testAdProductChange());
  }

  handleCheckDarkLoader = () => {
    this.setState(this.generateDarkLoaderColors(), () => this.testAdProductChange());
  }

  render() {
    const { ...state } = this.state;

    const isDarkLoader = this.isDarkLoader();

    return (
      <CompanyPlayer  onSave={this.handleSave}
                      onChangeTheme={this.handleChangeTheme}
                      onChangeHeaderFile={this.handleChangeHeaderFile}
                      onChangeLogoFile={this.handleChangeLogoFile}
                      onTestChanges={this.handleTestChanges}
                      onClickAlertOk={this.handleClickAlertOk}
                      onClickUndoChanges={this.handleUndoChanges}
                      isDarkLoader={isDarkLoader}
                      onCheckLightLoader={this.handleCheckLightLoader}
                      onCheckDarkLoader={this.handleCheckDarkLoader}
                      {...state} />
    );
  }
}

const CompanyQuery = gql`
  query company($name: String!) {
    company(name: $name) {
      name
      theme
    }
  }
`;

const UpdateTheme = gql`
  mutation updateTheme($name: String!, $logo: String, $header: String, $enableProductMap: Boolean, $main: String, $primary: String, $secondary: String, $trackBorder: String,
                       $trackColor: String, $productPrimary: String, $productSecondary: String, $trackBorderActive: String, $trackColorActive: String,
                       $loaderBackground: String, $loaderColor: String, $logoLoader: String){
    updateTheme(name: $name, logo: $logo, header: $header, enableProductMap: $enableProductMap, main: $main, primary: $primary, secondary: $secondary, trackBorder: $trackBorder,
                trackColor: $trackColor, productPrimary: $productPrimary, productSecondary: $productSecondary, trackBorderActive: $trackBorderActive, trackColorActive: $trackColorActive,
                loaderBackground: $loaderBackground, loaderColor: $loaderColor, logoLoader: $logoLoader) {
      name
      companyTheme {
        logo
        header
        logoLoader
        enableProductMap
      }
    }
  }
`;

const GenerateTestTheme = gql`
  mutation generateTestTheme($name: String!, $logo: String, $header: String, $enableProductMap: Boolean, $main: String, $primary: String, $secondary: String, $trackBorder: String,
                       $trackColor: String, $productPrimary: String, $productSecondary: String, $trackBorderActive: String, $trackColorActive: String,
                       $loaderBackground: String, $loaderColor: String, $logoLoader: String){
    generateTestTheme(name: $name, logo: $logo, header: $header, enableProductMap: $enableProductMap, main: $main, primary: $primary, secondary: $secondary, trackBorder: $trackBorder,
                trackColor: $trackColor, productPrimary: $productPrimary, productSecondary: $productSecondary, trackBorderActive: $trackBorderActive, trackColorActive: $trackColorActive,
                loaderBackground: $loaderBackground, loaderColor: $loaderColor, logoLoader: $logoLoader) {
      name
      companyTheme {
        logo
        header
        logoLoader
        enableProductMap
      }
      theme
    }
  }
`;

const AssetListQuery = gql`
  query allAssets($company: String!) {
    assets(company: $company) {
      name
      company
      preview
      status
      type
      filePath
      location
      filePath
      videoSrc
      youtubeId
    }
  }
`;

const componentGql = compose(
  graphql(CompanyQuery, {
    name: 'companyQuery',
    options: () => ({
      variables: {
        name: localStorage.getItem('company')
      },
      fetchPolicy: 'network-only'
    })
  }),
  graphql(UpdateTheme, {
    name: 'updateTheme'
  }),
  graphql(GenerateTestTheme, {
    name: 'generateTestTheme'
  }),
  graphql(AssetListQuery, {
    name: 'assetListQuery',
    options: () => ({
      variables: {
        company: localStorage.getItem('company')
      }
    })
  })
)

export default componentGql(CompanyPlayerContainer);