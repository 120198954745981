import { parse } from 'query-string';
import React, { Component } from 'react';
import Sign from '../../components/Sign';

export default class SignContainer extends Component {
  state = {
    login: true,
    email: '',
    password: '',
    companyName: '',
    errorMessage: '',
    domain: '',
    successLogin: false
  }

  componentWillMount() {
    const { token } = parse(document.location.search);

    if (token && !Array.isArray(token)) {
        this.successLogin(token);
    }
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value });
  }

  confirm = () => {
    this.doRequestLogin()
      .then((resp) => this.afterLogin(resp));
  }

  doRequestLogin = () => {
    const { login, email, password, companyName, domain } = this.state;

    const url = `${process.env.REACT_APP_API_URL}/authorization/${login ? 'authorize' : 'register'}`;

    return fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
        companyName,
        domain
      })
    })
  }

  afterLogin = (resp) => {
    if (resp.status === 401) {
      this.setState({ errorMessage: 'Incorrect email or password' });
      return Promise.resolve();
    }

    return resp.json()
      .then((parsedResp) => {
        if (parsedResp) {
          if (parsedResp.accessToken) {
            this.successLogin(parsedResp.accessToken)
    
          } else if (parsedResp.message) {
            this.setState({ errorMessage: parsedResp.message });
          }
        }
      });
  }

  successLogin = (accessToken) => {
    localStorage.setItem('token', accessToken);
    this.setState({ successLogin: true });
  }

  onClickCreateAccount = () => {
    this.setState({
      login: false,
      errorMessage: ''
    });
  }

  render() {
    return (
      <React.Fragment>
        <Sign in={this.state.login}
              onChange={this.handleChange}
              onSubmit={this.confirm}
              errorMessage={this.state.errorMessage || ''}
              successLogin={this.state.successLogin || false}
              onClickCreateAccount={this.onClickCreateAccount}/>
      </React.Fragment>
    )
  }
}