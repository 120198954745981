import React, { Component } from 'react';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import ModalAssetGeneralInformationEdit from '../../components/ModalAssetGeneralInformationEdit';
import { EnumAssetType } from '../../models/Asset';

class AssetGeneralInformationEditContainer extends Component {
  state = {}

  componentDidMount() {
    const { asset } = this.props;

    this.setState({
      name: asset.name,
      title: asset.title,
      closeLocation: asset.closeLocation,
      description: asset.description,
      company: asset.company,
      disablePlaylist: asset.disablePlaylist,
      videoCreator: asset.videoCreator,
      zoomIn: asset.zoomIn,
      autoreplay: asset.autoreplay,
      type: asset.type
    });
  }

  handleClickSave = () => {
    const { name, title, description, company, closeLocation, disablePlaylist, videoCreator, zoomIn, autoreplay } = this.state;

    this.setState({
      loadingSave: true
    });

    this.props
      .editMutation({
        variables: {
          name,
          title,
          description: description ? description : null,
          videoCreator,
          disablePlaylist,
          company,
          closeLocation: closeLocation ? closeLocation : null,
          zoomIn: zoomIn,
          autoreplay
        }
      })
      .then(() => this.props.onClose({ name, title, description, closeLocation }))
      .catch(() => this.setState({ loadingSave: false }));
  }

  handleChange = ({ name, value }) => {
    this.setState({
      [name]: value
    });
  }

  render() {
    const { onClose, open } = this.props;
    const { title, description, loadingSave, closeLocation, disablePlaylist, videoCreator, zoomIn, autoreplay, type } = this.state;

    const isVideo = type && type.value === EnumAssetType.VIDEO;

    return <ModalAssetGeneralInformationEdit  title={title}
                                              description={description}
                                              disablePlaylist={disablePlaylist}
                                              closeLocation={closeLocation}
                                              videoCreator={videoCreator}
                                              onClose={onClose}
                                              open={open}
                                              onChange={this.handleChange}
                                              onClickSave={this.handleClickSave}
                                              loadingSave={loadingSave}
                                              zoomIn={zoomIn}
                                              autoreplay={autoreplay}
                                              hideDisablePlaylist={!isVideo}
                                              hideZoomIn={!isVideo}
                                              hideAutoreplay={!isVideo} />;
  }
}

const AssetEditMutation = gql`
  mutation saveAsset(
    $name: String!,
    $title: String!,
    $description: String,
    $company: String!,
    $closeLocation: String,
    $disablePlaylist: Boolean,
    $videoCreator: String,
    $zoomIn: Boolean,
    $autoreplay: Boolean
  ) {
    asset(
      name: $name,
      title: $title,
      description: $description,
      company: $company,
      closeLocation: $closeLocation,
      disablePlaylist: $disablePlaylist,
      videoCreator: $videoCreator,
      zoomIn: $zoomIn,
      autoreplay: $autoreplay
    ) {
      name
      title
      company
      description
      closeLocation
      disablePlaylist
      autoreplay
      videoCreator
    }
  }
`;

const componentGql = compose(
  graphql(AssetEditMutation, {
    name: 'editMutation'
  })
)

export default componentGql(AssetGeneralInformationEditContainer);