import './style.css';

import clipboardCopy from 'clipboard-copy';
import React, { Component } from 'react';
import Highlight from 'react-highlight';
import { Button, Modal } from 'semantic-ui-react';

class EmbedMiniClip extends Component {
  state = {
    scriptIsCopied: false
  };

  scriptIsCopiedTimeout;

  getCode = (source) => [
    `<video `,
    `  width="135"`,
    `  autoplay`,
    `  muted`,
    `  loop>`,
    `    <source src="${source}" type="video/mp4"/>`,
    `</video>`
  ].join('\n');

  handleCopy = () => {
    const { source } = this.props;

    clipboardCopy(this.getCode(source));
   
    this.setState({
      scriptIsCopied: true
    });

    this.scriptIsCopiedTimeout = setTimeout(() => {
      this.setState({ scriptIsCopied: false });
    }, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.scriptIsCopiedTimeout);
  }

  render() {
    const { open, source, onClose } = this.props;

    return (
      <React.Fragment>
        <Modal  size='small'
                key='1'
                open={open}
                closeIcon
                closeOnEscape={false}
                closeOnDimmerClick={false}
        >
          <Modal.Content>
            <div className='heading-3 cblack EmbedMiniClip__Title'>Embed your Mini Clip</div>
            <div className='HorizontalDivider'>&nbsp;</div>
            <div className='body cgrey EmbedMiniClip__Description'>
              Copy and paste this video tag in your  site's code
            </div>
            <Highlight language="javascript">
              {this.getCode(source)}
            </Highlight>
            <div className="ModalScript__Copy">
              {!this.state.scriptIsCopied ? (
                <Button primary onClick={this.handleCopy}>Copy to clipboard</Button>
              ) : (
                <Button basic color='green'>Copied to clipboard!</Button>
              )}
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>Close</Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

export default EmbedMiniClip;