import './style.css';

import React, { Component } from 'react';

export default class ImageMapper extends Component {
  maxX = (n, rect, diff) => {
    if (n > rect.width - diff * 2) {
      return rect.width - diff * 2;
    }
    if (n < 0) {
      return 0;
    }
    return n;
  }
  maxY = (n, rect, diff) => {
    if (n > rect.height - diff * 2) {
      return rect.height - diff * 2;
    }
    if (n < 0) {
      return 0;
    }
    return n;
  }

  onClickImage = (event) => {
    const $image = event.currentTarget;
    const rect = $image.getBoundingClientRect();
    const x = event.pageX;
    const y = event.pageY;
    const center = {
      x: x - rect.left,
      y: y - rect.top
    }
    const diffX = 25;
    const diffY = 25;
    const { onAddItem } = this.props;

    if (onAddItem) {
      onAddItem({
        x1: this.maxX(center.x - diffX, rect, diffX)/rect.width,
        x2: this.maxX(center.x + diffX, rect, diffX)/rect.width,
        y1: this.maxY(center.y - diffY, rect, diffY)/rect.height,
        y2: this.maxY(center.y + diffY, rect, diffY)/rect.height
      });
    }
  }

  fatorToPercent = (n) => `${n * 100}%`

  render() {
    const {
      items = [],
      className,
      src
    } = this.props;

    return (
      <div className="ImageMaper">
        {items.map((item, index) => (
          <div
            className="ImageMaper__Dot"
            key={index}
            data-item={index + 1}
            style={{
              top: this.fatorToPercent(item.y1),
              left: this.fatorToPercent(item.x1),
              height: 50,
              width: 50
            }}
          />
        ))}
        <img
          src={src}
          alt=''
          onClick={this.onClickImage}
          className={className} />
      </div>
    );
  }
}