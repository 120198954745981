import React, { Component } from 'react';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { Redirect } from 'react-router';

import AssetVideoEdit from '../../components/AssetVideoEdit';
import { isUserMaster } from '../../models/User';
import { generatePreviewUrl, generatePreviewDashboardUrl } from '../../models/Asset';
import adminSDK from '../../tools/admin-sdk';

const TIME_WINDOW = 15;

class AssetVideoEditContainer extends Component {
  videoEdit;

  state = {}
  $iframeVideoRef;

  componentDidMount() {
    this.props.setActiveModuleTitle('Assets');
    adminSDK.on('on-change-active-bubble', this.onChangeActiveBubble);
    adminSDK.on('on-theme-changed', this.onChangeTheme);
  }

  componentWillUnmount() {
    adminSDK.off('on-change-active-bubble', this.onChangeActiveBubble);
    adminSDK.off('on-theme-changed', this.onChangeTheme);
  }

  componentWillReceiveProps(props) {
    if (this.props.asset.loading && !props.asset.loading) {
      this.loadPageData(props.asset.asset);
    }
  }

  async loadPageData(asset) {
    const { company, name, type } = asset;
    const previewUrl = generatePreviewUrl({ company, name, type: type.value });
    const previewUrlDashboard = generatePreviewDashboardUrl({ company, name, type: type.value });

    this.setState({
      previewUrlDashboard,
      previewUrl,
      assetJSON: asset,
      products: this.getProducts(asset)
    });
  }

  onChangeActiveBubble = ({ activeIndex }) => {
    this.setState({
      activeIndex
    });
  }

  onChangeTheme = (theme) => {
    Object.keys(theme).forEach((key) => {
      document.body.style.setProperty(key, theme[key]);
    });
  }

  getProducts(data) {
    const {
      frames = {},
      items = {},
      media
    } = data;

    if (!frames || !items) return [];

    Object.keys(frames).forEach((key) => {
      frames[key] = (frames[key] || []);
    });

    return Object.keys(frames).reduce((reduce, frame) => {
      const reduceItensOnFrame = this.mergeEqualProducts(frames[frame]);

      reduceItensOnFrame.forEach(productId => {
        const currentTime = Number(frame) / Number(media.framerate);
        const last = Array.from(reduce).reverse().find((r) => r.productId === productId);

        if (!last || (currentTime - last.currentTime) >= TIME_WINDOW) {
            reduce.push({
                ...items[productId],
                productId,
                frame,
                currentTime
            });
        }
      });

      return reduce;
    }, []);
  }

  mergeEqualProducts = (productsOnFrame) => {
    return productsOnFrame.reduce((items, { productId }) => {
      if (items.indexOf(productId) === -1) {
        items.push(productId);
      }
      return items;
    }, []);
  }

  handleReset = () => {
    const { asset, assetMutation } = this.props;
    const { company, title, name, defaultState } = asset.asset;

    if (!defaultState) return;

    this.setState({
      loadingReset: true
    });

    assetMutation({
      variables: {
        company,
        name,
        title,
        frames: defaultState.frames,
        items: defaultState.items
      }
    })
    .then(async () => {
      const { data } = await this.props.asset.refetch();
      const { previewUrl } = this.state;

      this.loadPageData(data.asset);
      this.setState({
        previewUrl: previewUrl.indexOf('?v=') === -1 ? `${previewUrl}?v=${Date.now()}` : previewUrl.split('?v=')[0],
        loadingReset: false
      });
    })
    .catch((e) => {
      this.setState({
        loadingReset: false
      });
    })
  }

  refVideoEdit = (e) => {
    this.videoEdit = e;
  }

  handleEditProduct = ({ newProduct, time, previousProduct }) => {
    this._removeProduct(previousProduct);

    this.handleAddProduct({ product: newProduct, time });

    this.setState({
      hasChanged: true
    });
  }

  handleAddProduct = ({ product, time }) => {
    const { assetJSON } = this.state;

    if (!assetJSON.items) {
      assetJSON.items = {};
    }

    assetJSON.items[product.productId] = {
      description: product.description,
      id: product.productId,
      images: product.images,
      title: product.title,
      price: product.price,
      sku: product.sku
    }

    const { media: { framerate = 0 } } = assetJSON;
    const frameKey = Math.round(framerate * time);

    if (!assetJSON.frames) {
      assetJSON.frames = {};
    }

    assetJSON.frames[frameKey] = [{
      productId: product.productId
    }];

    this.setState({
      assetJSON,
      products: this.getProducts(assetJSON),
      hasChanged: true
    });

    this.$iframeVideoRef.contentWindow.postMessage({ msg: 'asset-json', data: assetJSON }, '*');
  }

  getNextItemId = (items, product) => {
    if (items) {
      const keys = Object.keys(items);
      const currentIndex = keys.findIndex(key => key === product.id);

      if (currentIndex > -1) {
        return currentIndex;
      }

      if (keys.length) {
        return Math.max(...keys.map(i => Number(i))) + 1
      }
    }

    return 0;
  }

  getProductIndexs = (product) => {
    const { assetJSON } = this.state;
    const { media: { framerate = 0 } } = assetJSON;

    if (assetJSON) {
      const keyFrames = Object.keys(assetJSON.frames);
      const indexKeyFrame = keyFrames.findIndex(key => key === product.frame);
      let lastKeyFrame = Math.round(Number(TIME_WINDOW * framerate));

      if (lastKeyFrame !== -1) {
        lastKeyFrame = indexKeyFrame + lastKeyFrame;
      }

      return [indexKeyFrame, lastKeyFrame];
    }

    return [];
  }

  _removeProduct = (product) => {
    const { assetJSON } = this.state;

    if (assetJSON) {
      const keyFrames = Object.keys(assetJSON.frames);
      const [indexKeyFrame, lastKeyFrame] = this.getProductIndexs(product);

      keyFrames.slice(indexKeyFrame, lastKeyFrame).forEach(key => {
        const items = assetJSON.frames[key];
        const __items = items.filter(({ productId }) => (
          productId !== product.id
        ));

        assetJSON.frames[key] = __items;

        if (!__items.length) {
          delete assetJSON.frames[key];
        }

        this.setState({
          products: this.getProducts(assetJSON)
        });
      });
    }
  }

  handleClickProductCard = (product) => {
    this.$iframeVideoRef.contentWindow.postMessage({ msg: 'video-play', data: { currentTime: product.currentTime } }, '*');;
  }

  handleClickSaveChanges = ({ saveAsDefault }) => {
    const { assetJSON } = this.state;
    const { asset, assetMutation } = this.props;
    const { company, name } = asset.asset;

    this.setState({
      loadingSave: true
    });
    const variables = {
      name,
      company,
      title: assetJSON.title,
      items: assetJSON.items,
      frames: assetJSON.frames,
    };

    if (saveAsDefault) {
      variables.defaultState = {
        items: assetJSON.items,
        frames: assetJSON.frames
      };
    }

    assetMutation({
      variables
    }).then(() => {
      this.setState({
        redirectTo: `/assets/${name}/details`,
        loadingSave: false
      });
    }).catch((e) => {
      this.setState({
        loadingSave: false
      });
    });
  }

  handleRemoveProduct = ({ deletedProduct }) => {
    this._removeProduct(deletedProduct);

    const { assetJSON } = this.state;

    this.$iframeVideoRef.contentWindow.postMessage({ msg: 'asset-json', data: assetJSON }, '*');

    this.setState({
      hasChanged: true
    });
  }

  handleClickTreeDetails = () => {
    this.setState({
      redirectTo: `/assets/${this.props.asset.asset.name}/details`,
    })
  }

  handleClickTreeAssets = () => {
    this.setState({
      redirectTo: `/assets`,
    })
  }

  render() {
    const { asset } = this.props;
    const { previewUrl, previewUrlDashboard, assetJSON, products, activeIndex, redirectTo, loadingSave, hasChanged, loadingReset } = this.state;

    return redirectTo ? (
      <Redirect to={{pathname: redirectTo}}
                push={true}/>
    ) : (
      <AssetVideoEdit ref={this.refVideoEdit}
                      onResetAsset={this.handleReset}
                      asset={asset.asset}
                      previewUrl={previewUrl}
                      previewUrlDashboard={previewUrlDashboard}
                      assetJSON={assetJSON}
                      products={products}
                      activeIndex={activeIndex}
                      onAddProduct={this.handleAddProduct}
                      onEditProduct={this.handleEditProduct}
                      onRemoveProduct={this.handleRemoveProduct}
                      onClickProductCard={this.handleClickProductCard}
                      onClickSaveChanges={this.handleClickSaveChanges}
                      onIframeRef={(ref) => this.$iframeVideoRef = ref}
                      loadingSave={loadingSave}
                      onClickTreeDetails={this.handleClickTreeDetails}
                      onClickTreeAssets={this.handleClickTreeAssets}
                      hasChanged={hasChanged}
                      loadingReset={loadingReset} />
    );
  }
}

const AssetEditQuery = gql`
  query asset($name: String!, $company: String!) {
    asset(name: $name, company: $company) {
      name
      title
      company
      description
      location
      filePath
      jsonLocation
      preview
      type
      youtubeId
      frames
      items
      defaultState
      autoreplay
      media {
        width
        framerate
        height
        ratio
      }
      products {
        id
        title
        url
        position {
          x1
          x2
          y1
          y2
        }
      }
    }
  }
`;

const AssetMutation = gql`
  mutation asset($name: String, $title: String!, $company: String!, $frames: ObjectScalarType, $items: ObjectScalarType, $defaultState: ObjectScalarType){
    asset(name: $name, title: $title, company: $company, frames: $frames, items: $items, defaultState: $defaultState) {
      name
      title
      company
    }
  }
`;

const componentGql = compose(
  graphql(AssetEditQuery, {
    name: 'asset',
    options: ({ match }) => ({
      variables: {
        company: isUserMaster() ? (match.params.company || '') : (localStorage.getItem('company') || ''),
        name: match.params.name
      },
      fetchPolicy: 'network-only'
    }),
    props: (props) => {
      return {
        ...props,
        name: props.name,
        company: props.company
      }
    }
  }),
  graphql(AssetMutation, {
    name: 'assetMutation'
  })
)

export default componentGql(AssetVideoEditContainer);