import './style.css';

import clipboardCopy from 'clipboard-copy';
import React, { Component, Fragment } from 'react';
import Highlight from 'react-highlight';
import { Button, Modal } from 'semantic-ui-react';

import { EnumAssetType } from '../../models/Asset';
import ModalIntegrationScript, { getCode } from '../ModalIntegrationScript';

const {
  REACT_APP_ACUITY_WEB_URL
} = process.env;

class EmbedAsset extends Component {
  state = {
    scriptIsCopied: false,
    openIntegration: false
  }
  scriptIsCopiedTimeout

  getCode = (source, hash, type) => {
    const { name, company } = this.props;

    switch (type) {
      case EnumAssetType.VIDEO:
      case EnumAssetType.PLAYLIST:
return `<div id="acuity-player"></div>
<script>
  (function (d, w, c, o) {
    if (w.acuityai) return w.acuityai.inject(o);
    var $s = d.createElement('script');
    var $e = d.getElementById(o.container);
    var f = $e.style;
    if (!f.height) {f.height=o.height+'px';$e._ac=true;}
    $s.src = '${REACT_APP_ACUITY_WEB_URL}';
    $s.onload = function () {w.acuityai.start({ company: c });w.acuityai.inject(o);};
    d.body.appendChild($s);
  })(document, window, '${company}', {
    width: 1024,
    height: 520,
    container: 'acuity-player',
    type: '${type === EnumAssetType.VIDEO ? 'mp4' : type === EnumAssetType.PLAYLIST ? 'playlist' : ''}',
    asset: '${name}'
  });
</script>`;
      case EnumAssetType.IMAGE:
      case EnumAssetType.IMAGE_PNG:
      return [
          `<img `,
          `  src="${source}"`,
          `  data-acuityai-id="${hash}" />`,
          getCode()
        ].join('\n');
      default:
        return [];
    }
  }

  handleCopy = () => {
    const { source, hash, type } = this.props;

    clipboardCopy(this.getCode(source, hash, type));
   
    this.setState({
      scriptIsCopied: true
    });

    this.scriptIsCopiedTimeout = setTimeout(() => {
      this.setState({ scriptIsCopied: false });
    }, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.scriptIsCopiedTimeout);
  }

  handleOpenIntegration = () => {
    this.setState({
      openIntegration: true
    })
  }

  handleClickCloseGetCode = () => {
    this.setState({
      openIntegration: false
    });
  }

  render() {
    const { open, source, hash, onClose, type } = this.props;
    const { openIntegration, scriptIsCopied } = this.state;
    const isVideo = EnumAssetType.VIDEO === type;
    const isPlaylist = EnumAssetType.PLAYLIST === type;

    return (
      <Fragment>
        <ModalIntegrationScript
          open={openIntegration}
          onClose={this.handleClickCloseGetCode}
        />
        <Modal  size='small'
                open={open}
                onClose={onClose}
                closeIcon
                closeOnEscape={false}
                closeOnDimmerClick={false} >
          <Modal.Content>
            <div className='heading-3 cblack EmbedAsset__Title'>Embed your {isVideo ? 'Video' : isPlaylist ? 'Playlist' : 'Image'}</div>
            <div className='HorizontalDivider'>&nbsp;</div>
            { isVideo || isPlaylist ? (
              <div className='body cgrey EmbedAsset__Detail'>
                If you're gonna change the size, it's recommended to keep the video ratio on 16:9
              </div>
            ) : null }
            <Highlight  language="javascript"
                        className='EmbedAsset__Code'>
              {this.getCode(source, hash, type)}
            </Highlight>
          </Modal.Content>
          <Modal.Actions>
            <Button color={scriptIsCopied ? 'green' : 'red'}
                    onClick={this.handleCopy} >
              {scriptIsCopied ? 'Copied to clipboard' : 'Copy to clipboard'}
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

export default EmbedAsset;