import './style.css';

import React, { Component, Fragment } from 'react';
import { GoogleLogin } from 'react-google-login';
import { Modal, Button, Image, Input, Form, Dimmer, Loader, Checkbox } from 'semantic-ui-react';
import Moment from 'react-moment';
import Icon from '@mdi/react';
import { mdiCheckCircle } from '@mdi/js';

export default class ModalAssetImportFromYoutube extends Component {
  googleAccountButton;

  componentDidMount() {
    if (this.props.askForGoogleCredentialsYoutubeImport) {
      setTimeout(() => this.googleAccountButton ? this.googleAccountButton.ref.click() : null, 1000);
    }
  }

  componentWillReceiveProps(props) {
    if (!this.props.askForGoogleCredentialsYoutubeImport && props.askForGoogleCredentialsYoutubeImport) {
      setTimeout(() => this.googleAccountButton ? this.googleAccountButton.ref.click() : null, 1000);
    }
  }

  render() {
    const { open, onClose, onErrorGoogleAccountInfo, onSuccessGoogleAccountInfo, youtubeVideos, onCheckVideo, onClickImport, isLoadingYoutubeVideos, searchYoutube, onChange, isLoadingImportYoutube, isImportYoutubeSuccess } = this.props;

    const filteredYoutubeVideos = searchYoutube && youtubeVideos && youtubeVideos.length ?
      youtubeVideos.filter(({ title }) => title.toUpperCase().indexOf(searchYoutube.toUpperCase()) !== -1)
      : youtubeVideos;
    
    const canImport = Boolean(youtubeVideos && youtubeVideos.length && youtubeVideos.find(({checked}) => Boolean(checked)));

    const onRenderGoogleLogin = (renderProps) => {
      return <Button  className='ModalAssetImportFromYoutube__GoogleLoginWrapper__YoutubeButton'
                      color='red'
                      icon='youtube'
                      size='small'
                      content='Login to Google Account'
                      onClick={renderProps ? renderProps.onClick : undefined}
                      loading={false}
                      ref={(ref) => this.googleAccountButton = ref} />
    };

    return (
      <Modal  open={open}
              onClose={() => onClose()}
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          { isImportYoutubeSuccess ? (
        <div className='ModalAssetImportFromYoutube__Success'>
          <Icon path={mdiCheckCircle}
                size={2.5}
                color="green"
                />
          <div className='cgrey body ModalAssetImportFromYoutube__Success__Text'>
            Your videos were imported successfully
          </div>
        </div>
          ) : (
        <Fragment>
          { !youtubeVideos ?
            <Dimmer active
                    inverted >
              <Loader />
            </Dimmer>
          : null }
          <div className='heading-3 cblack'>Select Youtube videos to Import</div>
          <Form className='ModalAssetImportFromYoutube__Form'>
            <Form.Field control={Input}
                        placeholder='Search'
                        name='searchYoutube'
                        icon='search'
                        iconPosition='left'
                        value={searchYoutube || ''}
                        onChange={(e, { value }) => onChange({ name: 'searchYoutube', value })} />
          </Form>
          { filteredYoutubeVideos ? 
            filteredYoutubeVideos.length ? (
              <div className='ModalAssetImportFromYoutube__YoutubeVideos'>
                { filteredYoutubeVideos.map(({ id, title, description, thumbnail, checked, publishedAt }, index) => (
                  <Fragment key={id}>
                    <div className={`ModalAssetImportFromYoutube__YoutubeVideos__YoutubeVideo
                                      ${checked ? 'ModalAssetImportFromYoutube__YoutubeVideos__YoutubeVideo--checked' : ''}`} >
                      <div className='ModalAssetImportFromYoutube__YoutubeVideos__YoutubeVideo__ColumnChecked'>
                        <Checkbox label=''
                                  checked={checked}
                                  onChange={onCheckVideo(id)}/>
                      </div>
                      <div className='ModalAssetImportFromYoutube__YoutubeVideos__YoutubeVideo__ColumnThumbnail'>
                        <Image src={thumbnail} />
                      </div>
                      <div className='ModalAssetImportFromYoutube__YoutubeVideos__YoutubeVideo__ColumnTitle'>
                        <div className='body-bold cgrey'>{title}</div>
                        <div className='caption cgrey ModalAssetImportFromYoutube__YoutubeVideos__YoutubeVideo__ColumnTitle__Description'>{description}</div>
                      </div>
                      <div className='ModalAssetImportFromYoutube__YoutubeVideos__YoutubeVideo__ColumnPublishedAt caption cgrey'>
                        <Moment format="MMM DD, YYYY hh:mma">
                          { publishedAt }
                        </Moment>
                      </div>
                    </div>
                    <div className='HorizontalDivider'>&nbsp;</div>
                  </Fragment>
                )) }
              </div>
            ) : (
              <span>No videos on this channel</span>
            )
          : (
            <div className='ModalAssetImportFromYoutube__GoogleLoginWrapper'>
              <GoogleLogin  clientId={'41979601646-1oupgoq5l4curirqarssiqrmgrcf1452.apps.googleusercontent.com'}
                            scope='https://www.googleapis.com/auth/youtube'
                            prompt='consent'
                            responseType='code'
                            accessType='offline'
                            onSuccess={onSuccessGoogleAccountInfo}
                            onFailure={onErrorGoogleAccountInfo}
                            render={onRenderGoogleLogin} />
            </div>
          ) }
        </Fragment>
          ) }
        </Modal.Content>
        <Modal.Actions>
          { isImportYoutubeSuccess ? (
            <Button color='red'
                    onClick={() => onClose()} >
              <div className='body-bold'>Ok</div>
            </Button>
          ) : (
            <Button color='red'
                    onClick={onClickImport}
                    loading={isLoadingImportYoutube}
                    disabled={!canImport} >
              <div className='body-bold'>Import</div>
            </Button>
          ) }
        </Modal.Actions>
      </Modal>
    );
  }
}
