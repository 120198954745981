import './style.css';

import clipboardCopy from 'clipboard-copy';
import React, { Component, Fragment } from 'react';
import Highlight from 'react-highlight';
import { Button, Message, Modal } from 'semantic-ui-react';

export const getCode = () => [
  `<script>`,
  `  (function (d, w, c) {`,
  `    if (w.acuityai) return;`,
  `    var $s = d.createElement('script');`,
  `    $s.src = 'https://assets.acuityai.com/acuity-web.js';`,
  `    $s.onload = function () {w.acuityai.start({ company: c });}`,
  `    d.body.appendChild($s);`,
  `  })(document, window, '${localStorage.getItem('company')}');`,
  `</script>`
].join('\n');

class ModalIntegrationScript extends Component {
  state = {
    scriptIsCopied: false
  }

  getCode = () => getCode();

  handleCopy = () => {
    this.setState({
      scriptIsCopied: true
    });
    clipboardCopy(this.getCode());
    setTimeout(() => {
      this.setState({
        scriptIsCopied: false
      })
    }, 2000);
  }

  render() {
    const { open, onClose } = this.props;
    const { scriptIsCopied } = this.state;

    return (
      <Modal  size='small'
              key='1'
              open={open}
              onClose={onClose}
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='heading-3 cblack ModalIntegrationScript__Title'>Integrate AcuityAI</div>
          <div className='HorizontalDivider'>&nbsp;</div>
          <div className='body cgrey ModalIntegrationScript__Description'>
            This script tag is responsible for injecting our script on pages that you want to make assets Shoppable.
          </div>
          <div className='body cgrey ModalIntegrationScript__Detail'>
            Please copy and paste this script tag in a global section or in all the pages that you want to make assets Shoppable.
          </div>
          <Highlight  language="javascript"
                      className='ModalIntegrationScript__Code'>
            {this.getCode()}
          </Highlight>
        </Modal.Content>
        <Modal.Actions>
          <Button color={scriptIsCopied ? 'green' : 'red'}
                  onClick={this.handleCopy} >
            {scriptIsCopied ? 'Copied to clipboard' : 'Copy to clipboard'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ModalIntegrationScript;