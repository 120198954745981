import React, { Component } from 'react';
import AssetVideoEditProduct from '../../components/AssetVideoEditProduct';
import { generateFileUrl } from '../../models/Asset';

export default class AssetVideoEditProductContainer extends Component {
  state = {}

  componentDidMount() {
    const { asset, product, initialTimeProduct } = this.props;

    const fileUrl = asset.filePath ? asset.filePath : generateFileUrl({
      company: asset.company,
      name: asset.name,
      type: ''
    });

    this.setState({
      fileUrl,
      selectedProduct: product,
      initialProduct: product
    });

    if (product) {
      this.calculateProductTimeFromProduct(product);
    } else {
      this.setState({
        productTime: initialTimeProduct || 0
      });
    }
  }

  getSecondsFromFrame = (frame) => {
    const { media } = this.props.assetJSON;
    return frame / media.framerate;
  }

  calculateProductTimeFromProduct = (product) => {
    const productTime = this.getSecondsFromFrame(product.frame);

    this.setState({
      productTime
    });
  }

  handleChangeProduct = (product) => {
    this.setState({
      selectedProduct: product
    });
  }

  handleClickSave = () => {
    const { selectedProduct } = this.state;
    this.props.onClose({
      product: selectedProduct,
    });
  }

  handleClickSaveAndAddNext = () => {
    const { productTime, selectedProduct } = this.state;
    this.props.onClose({
      product: selectedProduct,
      time: productTime,
      addOther: true
    });
  }

  handleChangeProductTime = (productTime) => {
    this.setState({
      productTime
    })
  }

  render() {
    const { asset, open, onClose, product } = this.props;
    const { fileUrl, productTime, selectedProduct, initialProduct } = this.state;

    return <AssetVideoEditProduct product={product}
                                  open={open}
                                  fileUrl={fileUrl}
                                  onClose={onClose}
                                  productTime={productTime}
                                  onChangeProductTime={this.handleChangeProductTime}
                                  onChangeProduct={this.handleChangeProduct}
                                  selectedProduct={selectedProduct}
                                  onClickSave={this.handleClickSave}
                                  initialProduct={initialProduct}
                                  youtubeId={asset.youtubeId}
                                  onClickSaveAndAddNext={this.handleClickSaveAndAddNext} />;
  }
}
