import './style.css';

import React, {Component} from 'react';
import gql from 'graphql-tag';
import {Modal, Button, Form} from 'semantic-ui-react';
import {compose, graphql} from 'react-apollo';
import _get from 'lodash/get';
import CompanyService from "../../tools/company";
import {VERTICAL_ID} from "../../tools/vertical";

class ModalCreateGroup extends Component {
  state = {};

  constructor(props, ...args) {
    super(props, ...args);
    this.state = props.group || {};
  }

  handleChangeTitle = (title) => {
    this.setState({title})
  }

  handleCreateGroup = () => {
    const {title} = this.state;
    const company = CompanyService.get();
    const variables = {
      title,
      company: company.name,
      vertical: this.props.verticalId,
      products: [],
    }
    if (this.state.id) {
      const data = {
        id: this.state.id,
        vertical: this.state.vertical,
        title: this.state.title,
      };
      return this.props.updateGroup({
        variables: data
      }).then(() => {
        this.props.onClose(data);
      })  
    }
    this.props.createGroup({variables}).then(() => {
      this.props.onClose();
    })
  }

  render() {
    const {open, onClose} = this.props;
    const {title} = this.state;

    return (
      <Modal
        size='tiny'
        open={open}
        onClose={() => onClose()}
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}>
        <Modal.Content>
          <div className='heading-3 cblack'>General Information</div>
          <Form.Field
            className='SettingsInput'>
            <label>Title</label>
            <Form.Input
              fluid
              name='name'
              onChange={value => this.handleChangeTitle(value.target.value)}
              value={title}
            />
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red'
                  onClick={() => this.handleCreateGroup()}
          >
            <div className='body-bold'>Save</div>
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}

const CreateGroupMutation = gql`
 mutation createGroup($title: String!, $company: String!, $vertical: String!, $products: [IGroupProductConfig]!) {
    createGroup(title: $title, company: $company, vertical: $vertical, products: $products) {
      id
    }
 }

`;
const UpdateGroupMutation = gql`
 mutation updateGroup($id: String!,  $title: String!, $vertical: String!) {
  updateGroup(id: $id, title: $title, vertical: $vertical) {
    id
  }
 }
`;

const componentGql = compose(
  graphql(CreateGroupMutation, {
    name: 'createGroup'
  }),
  graphql(UpdateGroupMutation, {
    name: 'updateGroup'
  })
)


export default componentGql(ModalCreateGroup);