import './style.css';

import React, { Component, Fragment } from 'react';
import { Modal, Form, Input, Button } from 'semantic-ui-react';

export default class ModalAssetImportFromUrl extends Component {
  render() {
    const { open, onClose, onChange, onClickNext, urlFile } = this.props;

    return (
      <Modal  size='tiny'
              open={open}
              onClose={() => onClose()}
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='cblack heading-3'>Import from URL</div>
          <Form className='UrlModal__Form'>
            <Form.Field control={Input}
                        label='Link'
                        name='urlFile'
                        placeholder='Paste link here'
                        value={urlFile || ''}
                        onChange={(e, { value }) => onChange({ name: 'urlFile', value })} />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red'
                  onClick={onClickNext}
                  disabled={!Boolean(urlFile)} >
            <div className='body-bold'>Next</div>
          </Button>
        </Modal.Actions>
      </Modal>

    );
  }

}
