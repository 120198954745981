import './style.css';

import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import { Card, Image, Button, Loader, Dimmer } from 'semantic-ui-react';
import { Timer, Add, Edit,Restore } from '@material-ui/icons';
import AssetVideoEditProductContainer from '../../containers/AssetVideoEditProduct';
import ConfirmAssetVideoEditProductModal from '../ConfirmAssetVideoEditProductModal';
import ConfirmAssetEditRestoreDataModal from '../ConfirmAssetEditRestoreDataModal';

export default class AssetVideoEdit extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.$productsWrapper = null;
    this.$product = {};
  }

  componentWillReceiveProps(props) {
    if (this.props.activeIndex !== props.activeIndex && props.activeIndex !== undefined && props.activeIndex !== null) {
      const productsWrapperHasScroll = this.$productsWrapper.scrollHeight > this.$productsWrapper.offsetHeight;
      if (productsWrapperHasScroll) {
        const startOfRangeShown = this.$productsWrapper.scrollTop;
        const endOfRangeShown = this.$productsWrapper.offsetHeight + this.$productsWrapper.scrollTop;
        const $productRef = this.$product[props.activeIndex];
        const isProductVisible = !$productRef || $productRef.offsetTop >= startOfRangeShown && $productRef.offsetTop <= endOfRangeShown;

        if (!isProductVisible) {
          this.$productsWrapper.scrollTo(0, $productRef.offsetTop);
        }
      }
    }
  }

  getPrettyTime = (time) => {
    const withZero = (n) => (String(n).length > 1 ? n : `0${n}`);
    const seconds = withZero(Math.floor(time % 60));
    const minutes = withZero(Math.floor(time / 60));
    return `${minutes}:${seconds}`;
  }

  getTime = (frame) => {
    const { media } = this.props.assetJSON;
    const time = frame / media.framerate;
    return this.getPrettyTime(time);
  }

  handleClickOpenAddNewItemPopup = () => {
    const { products, assetJSON } = this.props;
    const { media } = assetJSON;

    this.setState({
      isAssetVideoEditProductOpened: true,
      editProduct: null,
      initialTimeProduct: products && products.length ? products[products.length - 1].frame / media.framerate : null
    });
  }

  handleClickOpenEditItemPopup = (product) => {
    this.setState({
      isAssetVideoEditProductOpened: true,
      editProduct: product,
      initialTimeProduct: null
    });
  }

  handleClickCloseVideoEditProduct = ({ product, time, deletedProduct, addOther }) => {
    this.setState({
      isAssetVideoEditProductOpened: false
    }, () => {
      if (addOther) this.handleClickOpenAddNewItemPopup();
    });

    if (deletedProduct) return this.props.onRemoveProduct({ deletedProduct });

    if (product && this.state.editProduct) return this.props.onEditProduct({ newProduct: product, time, previousProduct: this.state.editProduct });

    if (product) this.props.onAddProduct({ product, time });
  }

  handleClickOpenConfirmSaveChanges = () => {
    this.setState({
      isConfirmSaveChangesOpened: true
    });
  }

  handleClickCloseConfirmSaveChanges = ({ save, saveAsDefault }) => {
    this.setState({
      isConfirmSaveChangesOpened: false
    });

    if (save) {
      this.props.onClickSaveChanges({ saveAsDefault });
    }
  }

  handleClickOpenConfirmRestoreData = () => {
    this.setState({
      isConfirmRestoreDataOpened: true
    });
  }

  handleClickOnCloseConfirmRestoreData = (confirmed) => {
    this.setState({
      isConfirmRestoreDataOpened: false
    });

    if (confirmed) {
      this.props.onResetAsset();
    }
  }

  render() {
    const { asset, previewUrlDashboard, assetJSON, products, activeIndex, onIframeRef, onClickProductCard, loadingSave, onClickTreeDetails, onClickTreeAssets, hasChanged, loadingReset } = this.props;
    const { isAssetVideoEditProductOpened, editProduct, isConfirmSaveChangesOpened, isConfirmRestoreDataOpened, initialTimeProduct } = this.state;

    return (
      <div className='AssetVideoEditPage'>
        { loadingReset ? 
          <Dimmer active
                  inverted >
            <Loader />
          </Dimmer>
        : null }
        { isAssetVideoEditProductOpened ? (
          <AssetVideoEditProductContainer product={editProduct}
                                          open={isAssetVideoEditProductOpened}
                                          asset={asset}
                                          onClose={this.handleClickCloseVideoEditProduct}
                                          assetJSON={assetJSON}
                                          initialTimeProduct={initialTimeProduct} />
        ) : null }
        { isConfirmSaveChangesOpened ? (
          <ConfirmAssetVideoEditProductModal  open={isConfirmSaveChangesOpened}
                                              onClose={this.handleClickCloseConfirmSaveChanges} />
        ) : null }
        { isConfirmRestoreDataOpened ? (
          <ConfirmAssetEditRestoreDataModal open={isConfirmRestoreDataOpened}
                                            onClose={this.handleClickOnCloseConfirmRestoreData} />
        ) : null }
        <div className='AssetVideoEditPage__Header'>
          <div className='HeaderWrapper'>
            <div  className='HeaderWrapper__Item HeaderWrapper__Item--link cred heading-3'
                  onClick={onClickTreeAssets}>Assets</div>
            <span className='HeaderWrapper__Divider'>></span>
            <div  className='HeaderWrapper__Item HeaderWrapper__Item--link cred heading-3'
                  onClick={onClickTreeDetails}>Details</div>
            <span className='HeaderWrapper__Divider'>></span>
            <div className='HeaderWrapper__Item cred heading-3'>Edit</div>
          </div>
          <div className='AssetVideoEditPage__Header__Actions'>
            <Button color='red'
                    onClick={this.handleClickOpenConfirmSaveChanges}
                    loading={loadingSave}
                    disabled={!hasChanged} >
                <div className='body-bold'>Save Changes</div>
            </Button>
          </div>
        </div>
        <Card>
          <Card.Content>
            <div className='AssetVideoEditPage__Wrapper'>
              <div className='AssetVideoEditPage__Wrapper__PreviewContent'>
                <div className='AssetVideoEditPage__Wrapper__PreviewContent__PreviewTitle heading-4 cblack'>
                  Preview
                </div>
                <div className='HorizontalDivider'>&nbsp;</div>
                <div className='AssetVideoEditPage__Wrapper__PreviewContent__PreviewWrapper'>
                  <iframe src={previewUrlDashboard}
                          title='preview'
                          frameBorder='0'
                          style={{
                            width: '100%',
                            height: '450px'
                          }}
                          ref={onIframeRef} />
                </div>
              </div>
              <div className='AssetVideoEditPage__Wrapper__EditOrderContent'>
                <div className='AssetVideoEditPage__Wrapper__EditOrderContent__TitleWrapper'>
                  <div className='heading-4 cblack'>
                    Edit Order
                  </div>
                  <div  className='AssetVideoEditPage__Wrapper__EditOrderContent__TitleWrapper__Restore cgrey'
                        onClick={this.handleClickOpenConfirmRestoreData}>
                    <Restore  fontSize='small'
                              className='AssetVideoEditPage__Wrapper__EditOrderContent__TitleWrapper__Icon' />
                    <div className='caption'>Restore Data</div>
                  </div>
                </div>
                <div className='HorizontalDivider'>&nbsp;</div>
                <div className='AssetVideoEditPage__Wrapper__EditOrderContent__Products'
                     ref={ (ref) => this.$productsWrapper=ref } >
                  {products && products.length ? (
                    products.map((product, index) => (
                      <div  className={`AssetVideoEditPage__Wrapper__EditOrderContent__Products__Product
                                        ${activeIndex === index ? 'AssetVideoEditPage__Wrapper__EditOrderContent__Products__Product--active' : ''} `}
                            key={`${product.id}:${index}`}
                            ref={ (ref) => this.$product[index]=ref }
                            onClick={() => onClickProductCard(product)} >
                        <div className='AssetVideoEditPage__Wrapper__EditOrderContent__Products__Product__ImageWrapper'>
                          <Image src={product.images[0]} />
                        </div>
                        <div className='AssetVideoEditPage__Wrapper__EditOrderContent__Products__Product__InformationWrapper'>
                          <div className='caption'>{product.title}</div>
                          <div className='AssetVideoEditPage__Wrapper__EditOrderContent__Products__Product__InformationWrapper__Time body-bold'>
                            <Timer  className='AssetVideoEditPage__Wrapper__EditOrderContent__Products__Product__InformationWrapper__Time__Icon'
                                    fontSize='small'/>
                            {this.getTime(product.frame)}
                          </div>
                        </div>
                        <div className='AssetVideoEditPage__Wrapper__EditOrderContent__Products__Product__ActionsWrapper'>
                          <Button onClick={() => this.handleClickOpenEditItemPopup(product)} >
                            <Edit fontSize='small' />
                          </Button>
                        </div>
                      </div>
                    ))
                  ) : null }
                </div>
                <div className='AssetVideoEditPage__Wrapper__EditOrderContent__ActionWrapper'>
                  <Button className='IconButton'
                          onClick={this.handleClickOpenAddNewItemPopup}>
                    <Add fontSize='small' />
                    <div className='caption'>
                      Add New Item
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    );
  }
}