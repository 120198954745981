import gql from 'graphql-tag';
import _get from 'lodash/get';

import React, {Component, Fragment} from 'react';
import {compose, graphql} from 'react-apollo';
import { Redirect } from 'react-router-dom';

import GroupList from '../../components/GroupList';
import EmbedAsset from '../../components/EmbedAsset';
import {EnumAssetType} from '../../models/Asset';
import {VERTICAL_ID} from "../../tools/vertical";
import CompanyService from "../../tools/company";
import VerticalList from "../../components/VerticalList";

class VerticalListContainer extends Component {
  state = {
    verticals: [],
    orderBy: {
      fieldName: 'createdAt',
      order: 'desc'
    }
  }

  componentDidMount() {
    this.props.setActiveModuleTitle('Verticals');

    if (this.props.location.state && this.props.location.state.redirectTo) {
      this.setState({redirectTo: this.props.location.state.redirectTo});
    }
  }

  componentWillReceiveProps(props) {
    if (props.verticalsQuery && !props.verticalsQuery.loading && props.verticalsQuery.verticals) {
      this.setState({verticals: props.verticalsQuery.verticals});
    }
  }

  handleClickDetails = (vertical) => () => {
    console.log(vertical);
    this.setState({
      redirectTo: `/verticals/${vertical.id}`
    })
  }

  onChangeOrder = (fieldName) => {
    let orderBy = Object.assign({}, this.state.orderBy);
    if (this.state.orderBy.fieldName !== fieldName) {
      orderBy = {
        fieldName,
        order: 'asc'
      };
    } else {
      orderBy.order = orderBy.order === 'asc' ? 'desc' : 'asc';
    }

    const isEnumValue = fieldName === 'status' || fieldName === 'type';

    this.state.groups
      .sort((a, b) => {
        if ((isEnumValue && _get(a, `${orderBy.fieldName}.name`) && _get(b, `${orderBy.fieldName}.name`) && _get(a, `${orderBy.fieldName}.name`) > _get(b, `${orderBy.fieldName}.name`)) ||
          (!isEnumValue && a[orderBy.fieldName] > b[orderBy.fieldName])) {
          return orderBy.order === 'asc' ? 1 : -1;
        } else if ((isEnumValue && _get(a, `${orderBy.fieldName}.name`) && _get(b, `${orderBy.fieldName}.name`) && _get(a, `${orderBy.fieldName}.name`) < _get(b, `${orderBy.fieldName}.name`)) ||
          (!isEnumValue && _get(a, fieldName) < _get(b, fieldName))) {
          return orderBy.order === 'asc' ? -1 : 1;
        }

        return 0;
      });

    this.setState({orderBy});
  }

  handleClickSetActiveTab = (activeTab) => {
    this.setState({activeTab});
  }

  filterBySearch = (search, groups) => {
    if (!search) return groups;

    return groups.filter((asset) => (
      (asset.products && asset.products.find(({title}) => title.toUpperCase().indexOf(search.toUpperCase()) !== -1)) ||
      (asset.title.toUpperCase().indexOf(search.toUpperCase()) !== -1)
    ));
  }

  render() {
    const {orderBy, asset, verticals, connection, openEmbedAsset, redirectTo, search} = this.state;

    if (redirectTo) {
      return <Redirect to={redirectTo} />
    }

    return (
      <Fragment>
        {connection && openEmbedAsset && (
          <EmbedAsset
            open={openEmbedAsset}
            source={connection.source}
            hash={connection.hash}
            onClose={this.handleCloseEmbedAsset}
            type={asset.type.value}
            name={asset.name}
            company={asset.company}
          />
        )}
        <VerticalList
          verticals={verticals}
          onClickDetails={this.handleClickDetails}
          onChangeSearch={this.handleChangeSearch}
          orderBy={orderBy || {}}
          onRefetchVertical={this.props.verticalsQuery.refetch}
        />
      </Fragment>
    );
  }
}

const VerticalsQuery = gql`
  query verticals($company: String!) {
    verticals(company: $company) {
      id,
      title, 
      company, 
      createdAt,
    }
  }
`;

const assetGqls = compose(
  graphql(VerticalsQuery, {
    name: 'verticalsQuery',
    options: () => ({
      variables: {
        company: _get(CompanyService.get(), 'name'),
      },
      fetchPolicy: 'network-only'
    })
  }),
);

export default assetGqls(VerticalListContainer);
