import './style.css';

import React, { Component } from 'react';
import { Modal, Button, Checkbox } from 'semantic-ui-react';

export default class ConfirmAssetVideoEditProductModal extends Component {
  state = {
    saveAsDefault: false
  }

  handleChangeSaveAsDefault = (e, { checked }, e2) => {
    this.setState({
      saveAsDefault: checked
    })
  }

  render() {
    const { onClose, open } = this.props;
    const { saveAsDefault } = this.state;

    return (
      <Modal  size='tiny'
              open={open}
              onClose={onClose}
              className='ConfirmAssetVideoEditProductModal'
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='heading-3 cblack ConfirmAssetVideoEditProductModal__ConfirmActionsTitle'>Save Changes</div>
          <div className='body cblack'>Are you sure you want to save changes?</div>
        </Modal.Content>
        <Modal.Actions className='ConfirmAssetVideoEditProductModal__ConfirmActionsWrapper'>
          <Checkbox toggle
                    label='Save state as default'
                    checked={saveAsDefault}
                    onChange={this.handleChangeSaveAsDefault} />
          <div>
            <Button onClick={onClose} >
                <div className='body-bold'>Cancel</div>
            </Button>
            <Button color='red'
                    onClick={() => onClose({ save: true, saveAsDefault })} >
                <div className='body-bold'>Save</div>
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    )
  }
}