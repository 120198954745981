import videoDescriptionGenerator from 'video-description-generator';

export const EnumStatusAsset = {
  IN_QUEUE: 1,
  PROCESSING: 2,
  FLAGGED: 3,
  REVIEW: 4,
  APPROVED: 5
};

export const EnumAssetType = {
  VIDEO: 1,
  IMAGE: 2,
  PLAYLIST: 3,
  SLIDESHOW: 4,
  IMAGE_PNG: 5,
  YOUTUBE_VIDEO: 6
};

export const assetExtensions = {
  [EnumAssetType.IMAGE]: 'jpg',
  [EnumAssetType.IMAGE_PNG]: 'png',
  [EnumAssetType.VIDEO]: 'mp4',
  [EnumAssetType.SLIDESHOW]: 'slideshow',
  [EnumAssetType.PLAYLIST]: 'playlist'
};

export const generatePreviewUrl = ({ company, name, type }) => {
  const appUrl = 
    company === 'proactiv' && process.env.REACT_APP_APP_URL ?
      process.env.REACT_APP_APP_URL.replace('app.acuityai', 'shoppable.proactiv') :
      process.env.REACT_APP_APP_URL;

  return `${appUrl}/${company}/${name}/${assetExtensions[type]}`;
}

export const generatePreviewDashboardUrl = ({ company, name, type }) => {
  const appUrl = 
    company === 'proactiv' && process.env.REACT_APP_APP_URL ?
      process.env.REACT_APP_APP_URL.replace('app.acuityai', 'shoppable.proactiv') :
      process.env.REACT_APP_APP_URL;

  return `${appUrl}/${company}/${name}/${assetExtensions[type]}?disableAnalytics=1&mobile=true`;
}

export const generateJSONUrl = ({ company, name, type }, _config) => {
  if (type === EnumAssetType.IMAGE || type === EnumAssetType.IMAGE_PNG || type === EnumAssetType.VIDEO || type === EnumAssetType.YOUTUBE_VIDEO) {
    return `${process.env.REACT_APP_ASSETS_URL}/${company}/${name}/${name}.json${_config && _config.cache ? '' : `?v=${Date.now()}`}`;
  }

  return '';
}

export const generateFileUrl = ({ company, name, type }) => {
  return `${process.env.REACT_APP_ASSETS_URL}/${company}/${name}/${name}.${assetExtensions[type]}`;
}

export const getProductsFromVideo = ({ frames, items, media }) => {
  const framerate = media ? media.framerate : 1;
  const _frames = Object.keys(frames).map(frameKey => ({ frameKey, frame: frames[frameKey] }));
  const _items = _frames.reduce((_products, { frameKey, frame }) => {
    if (Array.isArray(frame)) {
      const ret = { ..._products };

      frame.forEach(({ productId }) => {
        if (productId && !ret[productId]) {
          ret[productId] = items[productId];
          ret[productId].time = (frameKey / framerate);
        }
      });

      return ret;
    }

    return _products;
  }, {});

  return Object.keys(_items).map((key, index) => {
    const product = _items[key];
    const { id, title, link, images, time } = product;

    return { id, title, link, time, images, position: index };
  });
}

export const parseProductsByFirstAppearance = ({ frames, items, media, products }) => {
  const framerate = media ? media.framerate : 0;

  return Object.keys(products)
    .map((productId) => {
      const { id, title, link, images } = products[productId];
      let time;
      let position;

      const item = Object.keys(items)
        .find((_item) => items[_item].productId === productId);

      if (item) {
        let frameNumber;

        Object.keys(frames)
          .forEach((frame) => {
            const frameData = frames[frame].find((frameInfo) => frameInfo.item === parseInt(item, 10));
            if (frameData) {
              delete frameData.item;
              frameNumber = frame;
              position = { ...frameData };
            }
          });

        if (frameNumber) {
          const _frameNumber = parseInt(frameNumber, 10);
          if (_frameNumber || framerate){
            time = Math.floor(_frameNumber / framerate);
          }
        }
      }

      return { id, title, link, time, images, position };
    });
}

export const generateMiniClipUrl = ({ company, name, productId }) => {
  return `${process.env.REACT_APP_ASSETS_URL}/${company}/__miniclips/${name}__${productId}.mp4`;
};

export const PRODUCT_LIST_START_IDENTIFIER = '---------------- Product List ----------------';