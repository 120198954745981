class CompanyService {
  companyData;

  set(companyData) {
    this.companyData = companyData;
  }

  clear() {
    this.companyData = undefined;
  }

  get() {
    return this.companyData;
  }

  generateCompanyJSONUrl(companyName) {
    const _companyName = companyName || (this.companyData ? this.companyData.name : localStorage.getItem('company'));
    return `${process.env.REACT_APP_ASSETS_URL}/${_companyName}/config.json?v=${Date.now()}`;
  }
}

export default new CompanyService();