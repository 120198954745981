import './style.css';

import React, { Component, Fragment } from 'react';
import 'rc-slider/assets/index.css';
import Sortable from 'react-sortablejs';

import { Modal, Button, Image, Form } from 'semantic-ui-react';

import ProductSearchContainer from '../../containers/ProductSearch';
import VideoTimeSelector from '../VideoTimeSelector';
import Icon from '@mdi/react';
import { mdiPencil, mdiPencilOff, mdiMenu } from '@mdi/js';
import { DeleteForever, Add } from '@material-ui/icons';

export default class AssetVideoEditLook extends Component {
  state = {};

  render() {
    const { open, onClose, fileUrl, productTime, onChangeProduct, onClickSave, youtubeId, onClickSaveAndAddNext, onChangeProductTime, products, onClickAddProduct, editIndex, onClickEditProduct, onClickCancelEditProduct, onClickRemoveProduct, onClickSetAsMain, isSaveDisabled, hideSaveAndAddOther, onSortEnd } = this.props;

    const productEls = products && products.length ? products.map((product, index) => {
      const image = product && product.images && product.images[0];

      const productEl = (
        <div  key={`${index}${product && product.productId}`}
              className={'AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink'}
              data-id={product.productId}>
          { editIndex == undefined ? (
            <div className='heading-4 cgreylight AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink__Order'>
              <Icon path={mdiMenu}
                    size={1} />
            </div>
          ) : null }
          <div  className='AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink__ProductBox'>
              <div className='AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink__ProductBox__ImageWrapper'>
                <Image  src={image}
                        className='AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink__ProductBox__ImageWrapper__Image' />
              </div>
              <div className='AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink__ProductBox__Title caption'>
                <div className='caption'>{product.title}</div>
                { products.length > 1 ?
                    product.main ? (
                      <Button onClick={() => onClickSetAsMain(index)}
                              size='tiny'
                              className='AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink__ProductBox__Title__MainButton'
                              color='red' >
                        Main
                      </Button>
                    ) : (
                      <Button onClick={() => onClickSetAsMain(index)}
                              size='tiny'
                              className='AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink__ProductBox__Title__MainButton' >
                        Main
                      </Button>
                    )
                  : null}
              </div>
            <div className='AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink__ButtonWrapper'>
              { editIndex == undefined ? (
                <Button onClick={() => onClickEditProduct(index)} >
                  <Icon path={mdiPencil}
                        size={1} />
                </Button>
              ) : (
                <Button onClick={onClickCancelEditProduct} >
                  <Icon path={mdiPencilOff}
                        size={1} />
                </Button>
              ) }
              <Button onClick={() => onClickRemoveProduct(index)}
                      className='AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink__ButtonWrapper__Delete' >
                <DeleteForever fontSize='small' />
              </Button>
            </div>
          </div>
        </div>
      );

      return (
        <Fragment key={index}>
          { editIndex == undefined || (editIndex === index && product && product.title) ? productEl : null }
          { editIndex === index ? (
            <div  className={`AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink
                              ${product && product.title ? 'AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks__ProductLink--edit' : ''}`}>
              <ProductSearchContainer
                name={`productlink-${index + 1}`}
                product={product}
                onChange={(p) => onChangeProduct(p, index, product.main)}
                hideActiveProduct={true} />
            </div>
          ) : null }
        </Fragment>
      );
    }) : null;

    return (
      <Modal  size='large'
              open={open}
              onClose={onClose}
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='AssetVideoEditLook__Content'>
            <div className='AssetVideoEditLook__Content__Left'>
              <div className='AssetVideoEditLook__Content__Left__Title'>
                <div className='heading-2 cgrey TitleOrderIndicator'>1</div>
                <div className='heading-3 cblack'>Select the frame</div>
              </div>
              <VideoTimeSelector  productTime={productTime}
                                  youtubeId={youtubeId}
                                  fileUrl={fileUrl}
                                  onChangeProductTime={onChangeProductTime} />
            </div>
            <div className='AssetVideoEditLook__Content__Right'>
              <div className='AssetVideoEditLook__Content__Right__Title'>
                <div className='heading-2 cgrey TitleOrderIndicator'>2</div>
                <div className='heading-3 cblack'>Select the products</div>
              </div>
              <div className='AssetVideoEditLook__Content__FormWrapper'>
                <Form className='AssetVideoEditLook__Content__FormWrapper__Form'>
                  { productEls ? (
                    <div className='AssetVideoEditLook__Content__FormWrapper__Form__ProductLinks'>
                      <Sortable
                        options={{
                          animation: 150
                        }}
                        onChange={(_items) => onSortEnd({ items: _items })}
                      >
                        { productEls }
                      </Sortable>
                    </div>
                  ) : null }
                  <Button className='IconButton'
                          onClick={onClickAddProduct}
                          disabled={editIndex != undefined} >
                    <Add fontSize='small' />
                    <div className='caption'>
                      Add Product
                    </div>
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          { hideSaveAndAddOther ? null : (
            <Button onClick={onClickSaveAndAddNext}
                    disabled={isSaveDisabled} >
                <div className='body-bold'>Save and Add Other</div>
            </Button>
          ) }
          <Button color='red'
                  onClick={onClickSave}
                  disabled={isSaveDisabled} >
              <div className='body-bold'>Save</div>
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}