import './style.css';

import React, { Component, Fragment } from 'react';
import { Card, Button, Input } from 'semantic-ui-react';
import Icon from '@mdi/react'
import { mdiCloudUpload } from '@mdi/js';
import ListSortableHeader from '../List/sortable-header';

export default class ProductList extends Component {
  render() {
    const { products = [], onChangeSearch, orderBy, onChangeOrder, onClick } = this.props;

    const productList = products.map(product => (
      <Fragment key={product.id}>
        <div className='ProductList__Content__Product'>
          <div className='ProductList__Header__Column ProductList__Header__ColumnImage'>
            <img src={product.images[0] || 0} />
          </div>
          <div className='ProductList__Header__Column ProductList__Header__ColumnName body'>
            { product.title }
          </div>
          <div className='ProductList__Header__Column ProductList__Header__ColumnSku body'>
            { product.sku }
          </div>
        </div>
        <div className='HorizontalDivider'>&nbsp;</div>
      </Fragment>
    ));

    return (
      <div className='ProductListPage'>
        <div className='cred heading-3'>
          Products
        </div>
        <Card>
          <Card.Content>
            <div className='ProductListWrapper__Header'>
              <div className='ProductListWrapper__Header__CreateAssetWrapper'>
                <Button color='red'
                        className='IconButton'
                        onClick={() => onClick()}>
                  <Icon path={mdiCloudUpload}
                              size={0.8} />
                  <div className='body-bold'>Import Products</div>
                </Button>
              </div>
              <div className='ProductListWrapper__Header__RightActions'>
                <Input  placeholder='Search'
                        name='search'
                        icon='search'
                        onChange={onChangeSearch} />
              </div>
            </div>
            <div className='ProductList'>
              <div className='ProductList__Header'>
                <div className='ProductList__Header__Column ProductList__Header__ColumnImage overline-bold'>
                  &nbsp;
                </div>
                <div className='ProductList__Header__Column ProductList__Header__ColumnName overline-bold'>
                  <ListSortableHeader text='TITLE'
                                      fieldName='title'
                                      orderBy={orderBy}
                                      onChangeOrder={onChangeOrder}/>
                </div>
                <div className='ProductList__Header__Column ProductList__Header__ColumnSku overline-bold'>
                  <ListSortableHeader text='SKU'
                                      fieldName='sku'
                                      orderBy={orderBy}
                                      onChangeOrder={onChangeOrder}/>
                </div>
              </div>
              <div className='ProductList__Content'>
                {productList}
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    )
  }
}