import React, { Component, Fragment } from 'react';
import Highlight from 'react-highlight';
import htmlTemplate from './html-template';
import { Step, Button, Input } from 'semantic-ui-react';
import urlJoin from 'url-join';
import './style.css';
import _get from 'lodash/get';

const {
  REACT_APP_API_URL,
  REACT_APP_ACUITY_INJECTOR_URL
} = process.env;

class VerificationStep extends Component {
  state = {
    url: '',
    verifing: false
  }

  handleVerify = () => {
    const {
      onClickNext = () => null,
      onClickSave = () => null,
      onChange = () => null,
    } = this.props;
    const { url } = this.state;

    this.setState({
      verifing: true,
      verifyError: false
    })

    fetch(urlJoin(REACT_APP_API_URL, 'cross-request'), {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ url })
    })
    .then(resp => {
      if (resp.status === 400 || resp.status === 500 || resp.status === 500) {
        throw new Error('Error on validate url!');
      }
      return resp.text();
    })
    .then(d => {
      if (d.indexOf(REACT_APP_ACUITY_INJECTOR_URL) > -1) {
        this.setState({
          verifyError: false,
          verifing: false
        }, () => {
          onClickNext();
          console.log(url);
          onChange(null, {
            name: 'webpage',
            value: url
          });
          setTimeout(() => onClickSave(), 100);
        });
      }

      this.setState({
        verifing: false,
        verifyError: true
      })
    })
    .catch(e => {
      this.setState({
        verifing: false,
        verifyError: true
      });
    });
  }

  handleChangeUrl = (e) => {
    this.setState({
      url: e.target.value
    });
  }

  render () {
    const {
      company,
      onClickPrevious
    } = this.props;
    const {
      verifing,
      verifyError
    } = this.state;

    return (
      <div className="OthersSetup Step-2">
        <h2 className="OthersSetup__Title">Provide the link to the page:</h2>
        <p>Paste here the exact url of the page that you just create. <br /> (Ex.: {urlJoin(company.domain, 'shoppable')})</p>
        <div>
          <Input
            fluid
            icon='linkify'
            iconPosition='left'
            placeholder='https://....com/shoppable'
            name='name'
            value={this.state.url}
            onChange={this.handleChangeUrl}
          />
          {verifyError ? (
            <p className="OthersSetup__Error">
              Something is wrong. Please check if the url is correct.
            </p>
          ) : null}
        </div>
        <div className="OthersSetup__Toolbar">
          <Button
            onClick={onClickPrevious}>
            Back
          </Button>
          <Button
            onClick={this.handleVerify}
            loading={verifing}
            disabled={this.state.url === ''}
            primary>
            Verify
          </Button>
        </div>
      </div>
    );
  }
}

const OthersSteps = ({
  step,
  company,
  onClickNext,
  onClickPrevious,
  onClickSave,
  onChange
}) => {
  switch (step) {
    case 'page':
      return (
        <div className="OthersSetup Step-1">
          <h2 className="OthersSetup__Title">Create an page on your domain with the code bellow:</h2>
          <p>We suggest to put under the url /shoppable but its your choise</p>
          <Highlight
              language="javascript">
            {htmlTemplate({ name: company.name })}
          </Highlight>
          <div className="OthersSetup__Toolbar">
            <Button
              onClick={onClickNext}
              primary>
              Next
            </Button>
          </div>
        </div>
      );
    case 'verification':
      return (
        <VerificationStep
          company={company}
          onChange={onChange}
          onClickPrevious={onClickPrevious}
          onClickNext={onClickNext}
          onClickSave={onClickSave}
        />
      );
    case 'confirmation':
      return (
        <div className="OthersSetup Step-2">
          <h2 className="OthersSetup__Title">Eveything looks good</h2>
          <p>
            It's our turn, we need to figure out some details of your page.<br />
            We'll let you know when everything is ready!
          </p>
        </div>
      );
    default:
      return null;
  }
}

class OthersSetup extends Component {
  state = {
    activeStep: 0,
    steps: [
      {
        key: 'page',
        completed: true,
        icon: 'file outline',
        title: 'Create a page',
        description: 'Create an e-commerce page'
      },
      {
        key: 'verification',
        icon: 'linkify',
        title: 'Connect',
        description: 'Provide the page link',
      },
      {
        key: 'confirmation',
        active: true,
        icon: 'info',
        title: 'Confirmation',
        description: 'Start use shoppable links',
      }
    ]
  }

  componentWillReceiveProps (props) {
    if (props.company.webpage) {
      const { steps } = this.state;
      this.setState({
        activeStep: steps.length - 1
      }) 
    }
  }
  
  componentDidMount () {
    if (this.props.company.webpage) {
      const { steps } = this.state;
      this.setState({
        activeStep: steps.length - 1
      }) 
    }
  }

  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1
    })
  }

  handlePrevious = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    })
  }
  
  getActiveKey = () => {
    const { steps, activeStep } = this.state;
    return steps[activeStep].key;
  }

  getSteps = () => {
    const {
      activeStep,
      steps
    } = this.state;

    return steps.map((step, index) => {
      step.active = (index === activeStep);
      step.completed = (index < activeStep);
      return step;
    })
  }

  render () {
    const {
      company,
      onClickSave,
      onClickDisconnect,
      onChange
    } = this.props;

    return (
      <div
        className='OthersSetup'>
        {_get(company, 'store.config.checkoutUrl') ? (
          <div>
            <span>
              Connected to  {company.webpage}. <br /> <a onClick={onClickDisconnect}>disconnect</a>
            </span>
          </div>
        ) : (
          <Fragment>
            <Step.Group
              className="OthersSteps__Steper"
              items={this.getSteps()}
            />
            <OthersSteps
              step={this.getActiveKey()}
              company={company}
              onClickNext={this.handleNext}
              onClickPrevious={this.handlePrevious}
              onClickSave={onClickSave}
              onChange={onChange}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

export default OthersSetup;