import React, { Component, Fragment } from 'react';
import AssetAnalytics from '../../components/AssetAnalytics';
import _get from 'lodash/get';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { Redirect } from 'react-router';


import CompanyService from '../../tools/company';


class AssetAnalyticsContainer extends Component {
  state = {}

  handleClickTreeAssets = () => {
    this.setState({
      redirectTo: `/assets`,
    })
  }

  render() {
    const { match } = this.props;
    const { redirectTo } = this.state;
    const name = match.params.name;
    const company = _get(CompanyService.get(), 'name');
    const asset = _get(this.props.assetQuery, 'data.asset', {});

    if (!name) {
      return null;
    }

    if (redirectTo) {
      return (
        <Redirect
          to={{pathname: redirectTo}}
          push={true}
        />
      );
    }

    return (
      <AssetAnalytics
        title={asset.title}
        name={name}
        asset={asset}
        company={company}
        onClickTreeAssets={this.handleClickTreeAssets}
      />
    );
  }
}

const AssetDetailQuery = gql`
  query asset($name: String!, $company: String!) {
    asset(name: $name, company: $company) {
      name
      title
      company
      description
      thumbnail
      status
      type
      filePath
      location
      jsonLocation
      assets {
        name
        set
      }
      youtubeId
      videoSrc
      zoomIn
      closeLocation
      disablePlaylist
      autoreplay
      products {
        id
        title
        url
      }
      createdAt
      closeLocation
    }
  }
`;

const assetGqls = compose(
  graphql(AssetDetailQuery, {
    name: 'assetQuery',
    options: ({ match }) => ({
      variables: {
        company: _get(CompanyService.get(), 'name'),
        name: match.params.name
      },
      fetchPolicy: 'network-only'
    }),
    props: ({ assetQuery, ownProps }) => {
      const { asset, ..._assetQuery } = assetQuery;

      return {
        assetQuery: {
          data: {
            asset
          },
          ..._assetQuery
        },
        ...ownProps
      }
    }
  })
)

export default assetGqls(AssetAnalyticsContainer);
