import './style.css';

import React, { Component, Fragment } from 'react';
import { Modal, Button, Image, Input } from 'semantic-ui-react';
import { EnumAssetType } from '../../models/Asset';
import Moment from 'react-moment';
import { mdiMenu, mdiDeleteOutline } from '@mdi/js';
import Icon from '@mdi/react';
import uniqueId from 'lodash/uniqueId';
import Sortable from 'react-sortablejs';

const AssetGroupedItem = (({ asset, order, onRemove }) => {
  return (
    <div
      className='ModalChooseOrder__AssetList__Asset'
      data-id={asset.name}
    >
      <div className='ModalChooseOrder__AssetList__Asset__ColumnOrder'>
        <Icon path={mdiMenu}
              size={1} />
      </div>
      <div className='ModalChooseOrder__AssetList__Asset__ColumnPlayOrder body'>{order}</div>
      <div className='ModalChooseOrder__AssetList__Asset__ColumnThumbnail'>
        <Image src={ asset.thumbnail || asset.filePath || asset.location } />
      </div>
      <div className='ModalChooseOrder__AssetList__Asset__ColumnTitle'>
        {asset.title}
      </div>
      <div className='ModalChooseOrder__AssetList__Asset__ColumnUploadDate caption'>
        <Moment format="MMM DD, YYYY hh:mma">{ asset.createdAt }</Moment>
      </div>
      { onRemove ? (
        <div className='ModalChooseOrder__AssetList__Asset__ColumnRemove'>
          <Button onClick={onRemove} >
            <Icon path={mdiDeleteOutline}
                  size={1}
                  className='cgrey2' />
          </Button>
        </div>
      ) : null }
    </div>
  )
});

export default class ModalChooseOrder extends Component {
  render() {
    const { type, open, onClose, assets, onClickNext, onSortEnd, onClickSave, onRemove, assetsGrouped, onChangeSet, onClickAddSet, showInputGroupI, onClickShowInputGroup, isLoadingSave, onRemoveSet } = this.props;

    const isSlideshow = type === EnumAssetType.SLIDESHOW;
    const isPlaylist = type === EnumAssetType.PLAYLIST;

    let cont = 0;

    return (
      <Modal  size='small'
              open={open}
              onClose={() => onClose()}
              closeIcon
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='ModalChooseOrder__TitleWrapper'>
            <div className='heading-3 cblack'>Choose Order - {isSlideshow ? 'Slideshow' : isPlaylist ? 'Playlist' : ''}</div>
            { isPlaylist ? (
              <Button onClick={onClickAddSet}
                      color='red'
                      inverted={true} >
                <div className='body-bold'>{assetsGrouped && assetsGrouped.length > 1 ? 'Add set' : 'Separate into sets'}</div>
              </Button>
            ) : null }
          </div>
          <div className='ModalChooseOrder__AssetList'>
            <div className='HorizontalDivider'>&nbsp;</div>
            { assets && assets.length && assetsGrouped && assetsGrouped.length ? (
              assetsGrouped.map(({ set, items }, groupI) => (
                <Fragment key={set + items.length + groupI}>
                  { assetsGrouped.length > 1 ? (
                    <div className='ModalChooseOrder__AssetList__GroupTitle cgrey heading-3'
                        onBlur={(e) => onChangeSet({ value: e.target.value, groupI })}>
                      { showInputGroupI === groupI ? (
                        <Input  name={`set${groupI}`}
                              defaultValue={set} />
                      ) : (
                        <div onClick={() => onClickShowInputGroup(groupI)}
                              className='ModalChooseOrder__AssetList__GroupTitle__SetName'>
                          {set}
                        </div>
                      ) }
                      <Button onClick={() => onRemoveSet(set)}
                              className='IconButton'
                              color='red'
                              inverted={true} >
                        <Icon path={mdiDeleteOutline}
                              size={1} />
                        Remove Set
                      </Button>
                    </div>
                  ) : null}
                  <Sortable
                    options={{
                      animation: 150,
                      group: {
                          name: 'shared',
                          pull: true,
                          put: true
                      }
                    }}
                    onChange={(_items) => onSortEnd({ items: _items, set })}
                  >
                    { items.map((assetName) => {
                      const assetIndex = assets.findIndex(({ name }) => name === assetName);
                      const asset = assets[assetIndex];
                      cont++;

                      return (
                        <Fragment key={uniqueId()}>
                          <AssetGroupedItem asset={asset}
                                            order={cont}
                                            onRemove={() => onRemove({ name: asset.name, set })} />
                        </Fragment>
                      )
                    }) }
                  </Sortable>
                </Fragment>
              ))
            ) : null }
          </div>
        </Modal.Content>
        <Modal.Actions>
          { onClickNext ?
            <Button color='red'
                    onClick={onClickNext} >
              <div className='body-bold'>Next</div>
            </Button>
          : null }
          { onClickSave ?
            <Button color='red'
                    onClick={onClickSave}
                    loading={isLoadingSave} >
              <div className='body-bold'>Save</div>
            </Button>
          : null }
        </Modal.Actions>
      </Modal>
    )
  }
}