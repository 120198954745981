import clipboardCopy from 'clipboard-copy';
import { sha256 } from 'js-sha256';
import React, { Component } from 'react';
import gql from 'graphql-tag';
import { compose, graphql } from 'react-apollo';
import { Redirect } from 'react-router';

import AssetDetail from '../../components/AssetDetail';
import {
  generateFileUrl,
  generateMiniClipUrl,
  generatePreviewUrl,
  assetExtensions,
  generatePreviewDashboardUrl
} from '../../models/Asset';
import { isUserMaster } from '../../models/User';
import CompanyService from '../../tools/company';
import EmbedAsset from '../../components/EmbedAsset';
import ModalConfirm from '../../components/ModalConfirm';

const TIME_WINDOW = 15;

class AssetDetailContainer extends Component {
  componentDidMount() {
    this.props.setActiveModuleTitle('Assets');
  }

  componentWillReceiveProps(props) {
    if (!props.assetQuery.loading && props.assetQuery.data && props.assetQuery.data.asset) {
      if (props.assetQuery.data.asset.closeLocation) {
        this.setState({
          asset: props.assetQuery.data.asset
        });
      } else {
        this.setState({
          companyDomain: CompanyService.get().domain
        })
      }

      if (props.assetQuery.data.asset.type && props.assetQuery.data.asset.company && props.assetQuery.data.asset.name) {
        const { company, name, type } = props.assetQuery.data.asset;
        const previewUrl = generatePreviewUrl({ company, name, type: type.value });
        const previewUrlDashboard = generatePreviewDashboardUrl({ company, name, type: type.value });
        this.setState({
          asset: props.assetQuery.data.asset,
          previewUrl,
          previewUrlDashboard
        }, () => {
          this.setProductsFromJSON(this.state.asset);
        });
      }
    }

    if (!props.assetsFromAsset.loading && props.assetsFromAsset.data && props.assetsFromAsset.data.assetsFromAsset) {
      this.setState({
        assets: props.assetsFromAsset.data.assetsFromAsset
      });
    }
  }

  afterCloseAssetEdit = () => {
    this.props.assetQuery.refetch();
  }

  afterCloseAssetEditShoppableImage = () => {
    const { asset } = this.state;

    const { company, name, type } = asset;
    const previewUrl = generatePreviewUrl({ company, name, type: type.value });
    this.setState({
      previewUrl: `${previewUrl}?v=${Date.now()}`
    });

    this.props.assetQuery.refetch();
  }

  handleLoadedMiniClip = ({ id }) => {
    return () => {
      const products = this.state.products.map((product) => {
        if (product.id === id) {
          return { ...product, hasMiniClip: true }
        }

        return product;
      });

      this.setState({ products });
    }
  }

  handleClickProductLink = (product) => {
    return () => {
      window.open(product.link, '_blank');
    };
  }

  handleClickGetEmbbedCode = () => {
    const { asset } = this.state;
    const { assetConnection } = this.props;
    const hash = sha256(`${asset.name}:${asset.company}:videotag`);

    assetConnection({
      variables: {
        name: asset.name,
        company: asset.company,
        connectionHash: hash,
        webLocation: hash
      }
    });

    const source = generateFileUrl({
      company: asset.company,
      name: asset.name,
      type: asset.type.value
    });

    this.setState({
      openEmbedAsset: true,
      connection: {
        source,
        hash
      }
    });
  }

  handleCloseEmbedAsset = () => {
    this.setState({
      openEmbedAsset: false,
      connection: null
    })
  }

  handleClickAssetDetails = (asset) => {
    return () => {
      this.setState({
        redirectTo: `/assets/${asset.name}/details${isUserMaster() ? `/${asset.company}` : ''}`
      })
    };
  }

  handleClickTreeAssets = () => {
    this.setState({
      redirectTo: `/assets`,
    })
  }

  handleAfterEditGeneralInformation = () => {
    this.props.assetQuery.refetch();
  }

  handleClickGetLink = () => {
    const { webpage } = CompanyService.get();
    const { asset } = this.state;
    let previewUrl;

    if (webpage) {
      previewUrl = `${webpage}?asset=${asset.name}&type=${assetExtensions[asset.type.value]}`;
    } else {
      previewUrl = this.state.previewUrl;
    }

    clipboardCopy(previewUrl);
    this.setState({
      isLinkCopied: true
    });

    setTimeout(() => this.afterLinkCopied(), 2000);
  }

  afterLinkCopied = () => {
    this.setState({
      isLinkCopied: false
    });
  }

  handleClickApprove = () => {
    const { asset } = this.state;

    this.setState({
      isLoadingApproveOrDecline: true
    });

    this.props.approveAssetMutation({
      variables: {
        name: asset.name,
        company: asset.company
      }
    }).then((resp) => {
      this.setState({ isLoadingApproveOrDecline: false });
      this.props.assetQuery.refetch();
    })
    .catch((e) => {
      this.setState({ isLoadingApproveOrDecline: false });
    });
  }

  handleClickDecline = () => {
    const { asset } = this.state;

    this.setState({
      isLoadingApproveOrDecline: true
    });

    this.props.rejectAssetMutation({
      variables: {
        name: asset.name,
        company: asset.company
      }
    }).then((resp) => {
      const _asset = Object.assign({}, asset, { status: resp.data.rejectAsset.status });
      this.setState({ isLoadingApproveOrDecline: false, asset: _asset });
    })
    .catch((e) => {
      this.setState({ isLoadingApproveOrDecline: false });
    });
  }

  handleAfterPublishYoutubeVideo = () => {
    this.props.assetQuery.refetch();
  }

  handleAfterChooseOrder = () => {
    this.props.assetQuery.refetch();
    this.props.assetsFromAsset.refetch();
  }

  handleChangeJSONFile = (files) => {
    console.log('Needs to be implemented!');
  }

  handleClickGenerateMiniClips = () => {
    const { asset } = this.state;
    const { company, name } = asset;

    this.props.createMiniClip({
      variables: { company, name }
    });
  }

  handleClickRemoveMiniClip = (productId) => {
    const { asset } = this.state;
    const { company, name } = asset;

    this.props.removeMiniClip({
      variables: { company, name, productId }
    });
  }

  handleAfterPublishFacebookVideo = () => {
    this.props.assetQuery.refetch();
  }

  handleClickDeleteProduct = (product) => {
    this.setState({
      productToRemove: product,
      isConfirmDeleteProductOpened: true
    })
  }

  handleClickNoConfirmDeleteProduct = () => {
    this.setState({
      isConfirmDeleteProductOpened: false
    });
  }
  
  onDeleteProduct = () => {
    this.setState({
      isConfirmDeleteProductOpened: false
    });
    this._removeProduct(this.state.productToRemove);
    this.saveProducts();
  }

  getProductIndexs = (product) => {
    const { asset } = this.state;
    const { media: { framerate = 0 } } = asset;

    if (asset) {
      const keyFrames = Object.keys(asset.frames);
      const indexKeyFrame = keyFrames.findIndex(key => key === product.frame);
      let lastKeyFrame = Math.round(Number(TIME_WINDOW * framerate));

      if (lastKeyFrame !== -1) {
        lastKeyFrame = indexKeyFrame + lastKeyFrame;
      }

      return [indexKeyFrame, lastKeyFrame];
    }

    return [];
  }

  _removeProduct = (product) => {
    const { asset } = this.state;

    if (asset) {
      const keyFrames = Object.keys(asset.frames);
      const [indexKeyFrame, lastKeyFrame] = this.getProductIndexs(product);

      keyFrames.slice(indexKeyFrame, lastKeyFrame).forEach(key => {
        const items = asset.frames[key];
        const __items = items.filter(({ productId }) => (
          productId !== product.id
        ));

        asset.frames[key] = __items;

        if (!__items.length) {
          delete asset.frames[key];
        }

        this.setProductsFromJSON(asset);
      });
    }
  }

  getProducts(data) {
    const {
      frames = {},
      items = {},
      media
    } = data;

    if (!frames || !items) return [];

    Object.keys(frames).forEach((key) => {
      frames[key] = (frames[key] || []);
    });

    return Object.keys(frames).reduce((reduce, frame) => {
        const currentTime = Number(frame) / Number(media.framerate);

        frames[frame].forEach((products) => {
        if (Array.isArray(products)) {
          reduce.push(products.map(({ productId, main, mainLookImage }) => ({
            ...items[productId],
            productId,
            main,
            mainLookImage,
            frame,
            currentTime
          })));
        } else {
          const { productId } = products;
          const last = Array.from(reduce).reverse().find((r) => r.productId === productId);
  
          if (!last || (currentTime - last.currentTime) >= TIME_WINDOW) {
              reduce.push({
                  ...items[productId],
                  productId,
                  frame,
                  currentTime
              });
          }
        }
      });

      return reduce;
    }, []);
  }

  saveProducts = () => {
    const { asset } = this.state;
    const { assetMutation } = this.props;

    this.setState({
      isLoadingProducts: true
    });

    assetMutation({
      variables: {
        title: asset.title,
        name: asset.name,
        company: asset.company,
        items: asset.items,
        frames: asset.frames
      }
    }).then(() => {
      this.props.assetQuery.refetch();
      this.setState({
        isLoadingProducts: false
      });
    }).catch((e) => {
      this.setState({
        isLoadingProducts: false
      });
    });
  }

  setProductsFromJSON = async (asset) => {
    const products = this.getProducts(asset);
    const { company, name } = asset;

    this.setState({
      isLoadingProducts: false,
      products: products.map((product) => {
        if (Array.isArray(product)) {
          return product.map(p => ({
            miniClipUrl: generateMiniClipUrl({ company, name, productId: p.id }),
            ...p
          }));
        } else {
          return {
            miniClipUrl: generateMiniClipUrl({ company, name, productId: product.id }),
            ...product
          };
        }
      })
    });
  }

  handleAddProduct = ({ product, time }) => {
    this._addProduct({ product, time });

    this.saveProducts();
  }

  _addProduct = ({ product, time }) => {
    const { asset } = this.state;

    if (!asset.items) {
      asset.items = {};
    }

    asset.items[product.productId] = {
      description: product.description,
      id: product.productId,
      images: product.images,
      title: product.title,
      price: product.price,
      sku: product.sku
    }

    const { media: { framerate = 0 } } = asset;
    const frameKey = Math.round(framerate * time);

    if (!asset.frames) {
      asset.frames = {};
    }

    if (!asset.frames[frameKey]) {
      asset.frames[frameKey] = [];
    }

    asset.frames[frameKey].push({
      productId: product.productId
    });

    this.setState({
      asset,
      products: this.getProducts(asset),
      hasChanged: true
    });

    // this.$iframeVideoRef.contentWindow.postMessage({ msg: 'asset-json', data: assetJSON }, '*');
  }

  handleEditProductVideo = ({ newProduct, time, previousProduct }) => {
    this._removeProduct(previousProduct);

    this._addProduct({ product: newProduct, time });

    this.saveProducts();
  }

  handleAddProducts = ({ look, time }) => {
    this._addLook({ look, time });

    this.saveProducts();
  }

  handleEditProducts = ({ newLook, time, previousLook, mainLookImage}) => {
    if (previousLook.length > 1) {
      this._removeLook(previousLook);
    } else {
      this._removeProduct(previousLook[0]);
    }

    this._addLook({ look: newLook, time, mainLookImage });

    this.saveProducts();
  }

  _addLook = ({ look, time, mainLookImage }) => {
    const { asset } = this.state;

    const { media: { framerate = 0 } } = asset;
    const frameKey = Math.round(framerate * time);

    if (!asset.items) {
      asset.items = {};
    }

    if (!asset.frames) {
      asset.frames = {};
    }

    if (!asset.frames[frameKey]) {
      asset.frames[frameKey] = [];
    }

    if (look.length > 1) {
      look.forEach((product) => {
        asset.items[product.productId] = {
          description: product.description,
          id: product.productId,
          images: product.images,
          title: product.title,
          price: product.price,
          sku: product.sku
        };
      });
  
      asset.frames[frameKey].push(look.map(product => ({
        productId: product.productId,
        main: product.main,
        mainLookImage: product.main ? mainLookImage : undefined
      })));
    } else {
      asset.items[look[0].productId] = {
        description: look[0].description,
        id: look[0].productId,
        images: look[0].images,
        title: look[0].title,
        price: look[0].price,
        sku: look[0].sku
      }

      asset.frames[frameKey].push({
        productId: look[0].productId
      });
    }


    this.setState({
      asset,
      products: this.getProducts(asset),
      hasChanged: true
    });

    // this.$iframeVideoRef.contentWindow.postMessage({ msg: 'asset-json', data: assetJSON }, '*');
  }

  handleClickDeleteLook = (products) => {
    this.setState({
      lookToRemove: products,
      isConfirmDeleteLookOpened: true
    })
  }

  handleClickNoConfirmDeleteLook = () => {
    this.setState({
      isConfirmDeleteLookOpened: false
    });
  }
  
  onDeleteLook = () => {
    this.setState({
      isConfirmDeleteLookOpened: false
    });
    this._removeLook(this.state.lookToRemove);
    this.saveProducts();
  }

  _removeLook = (products) => {
    const { asset } = this.state;

    if (asset) {
      const frame = products[0].frame;

      asset.frames[frame] = asset.frames[frame].filter((product) => {
        const isLook = Array.isArray(product);
        if (!isLook) return true;
        if (product.length !== products.length) return true;

        return (product.map(({ productId }) => productId).join() !== products.map(({ productId }) => productId).join());
      });

      if (!asset.frames[frame].length) {
        delete asset.frames[frame];
      }

      this.setProductsFromJSON(asset);
    }
  }

  getLastProductTime = () => {
    const { products, asset } = this.state;
    const { media } = asset;

    if (!this.state.products || !this.state.products.length) return;

    const lastProduct = products[products.length - 1];
    const frame = Array.isArray(lastProduct) ? lastProduct[0].frame : lastProduct.frame;

    return frame / media.framerate;
  }

  render() {
    if (!this.state || !this.state.asset) {
      return null;
    }

    const {
      asset,
      products,
      isLoadingProducts,
      isLinkCopied,
      redirectTo,
      connection,
      openEmbedAsset,
      assets,
      previewUrl,
      previewUrlDashboard,
      companyDomain,
      isLoadingApproveOrDecline,
      isConfirmDeleteProductOpened,
      isConfirmDeleteLookOpened
    } = this.state;

    if (redirectTo) {
      if (redirectTo.indexOf('/details') !== -1) {
        return <Redirect to={{pathname: 'assets', state: { company: asset.company, redirectTo }}}
                         push={true} />
      } else {
        return <Redirect to={{pathname: redirectTo, state: { company: asset.company }}}
                         push={true} />
      }
    }

    const deleteModalConfirm = (
      <ModalConfirm text='Are you sure you want to delete product from this asset? This action can not be undone.'
                    title='Delete product from asset'
                    yesText='Yes, delete it'
                    noText={`No, keep it`}
                    onClickNo={this.handleClickNoConfirmDeleteProduct}
                    onClickYes={this.onDeleteProduct}
                    open={isConfirmDeleteProductOpened}/>
    );

    const deleteLookModalConfirm = (
      <ModalConfirm text='Are you sure you want to delete this look from asset? This action can not be undone.'
                    title='Delete look from asset'
                    yesText='Yes, delete it'
                    noText={`No, keep it`}
                    onClickNo={this.handleClickNoConfirmDeleteLook}
                    onClickYes={this.onDeleteLook}
                    open={isConfirmDeleteLookOpened}/>
    );

    return (
      <React.Fragment>
        { deleteModalConfirm }
        { deleteLookModalConfirm }
        {connection && openEmbedAsset && (
          <EmbedAsset
            open={openEmbedAsset}
            source={connection.source}
            hash={connection.hash}
            onClose={this.handleCloseEmbedAsset}
            type={asset.type.value}
            name={asset.name}
            company={asset.company}
          />
        )}
        <AssetDetail  asset={asset}
                      products={products}
                      isLoadingProducts={isLoadingProducts}
                      onClickCopyLink={this.handleClickCopyLink}
                      isLinkCopied={isLinkCopied}
                      afterCloseAssetEdit={this.afterCloseAssetEdit}
                      onLoadedMiniClip={this.handleLoadedMiniClip}
                      afterCloseAssetPreview={this.afterCloseAssetPreview}
                      onClickProductLink={this.handleClickProductLink}
                      onClickGetEmbbedCode={this.handleClickGetEmbbedCode}
                      assets={assets}
                      afterCloseAssetEditShoppableImage={this.afterCloseAssetEditShoppableImage}
                      previewUrl={previewUrl}
                      previewUrlDashboard={previewUrlDashboard}
                      onClickAssetDetails={this.handleClickAssetDetails}
                      companyDomain={companyDomain}
                      onClickTreeAssets={this.handleClickTreeAssets}
                      onAfterEditGeneralInformation={this.handleAfterEditGeneralInformation}
                      onClickGetLink={this.handleClickGetLink}
                      onClickApprove={this.handleClickApprove}
                      onClickDecline={this.handleClickDecline}
                      isLoadingApproveOrDecline={isLoadingApproveOrDecline}
                      afterPublishYoutubeVideo={this.handleAfterPublishYoutubeVideo}
                      onAfterChooseOrder={this.handleAfterChooseOrder}
                      onChangeJSONFile={this.handleChangeJSONFile}
                      onClickGenerateMiniClips={this.handleClickGenerateMiniClips}
                      onClickRemoveMiniclip={this.handleClickRemoveMiniClip}
                      afterPublishFacebookVideo={this.handleAfterPublishFacebookVideo}
                      onClickDeleteProduct={this.handleClickDeleteProduct}
                      onEditProduct={this.handleEditProductVideo}
                      onAddProduct={this.handleAddProduct}
                      onAddProducts={this.handleAddProducts}
                      onClickDeleteLook={this.handleClickDeleteLook}
                      onEditProducts={this.handleEditProducts}
                      lastProductTime={this.getLastProductTime()} />
      </React.Fragment>
    );
  }
}

const AssetDetailQuery = gql`
  query asset($name: String!, $company: String!) {
    asset(name: $name, company: $company) {
      name
      title
      company
      description
      thumbnail
      status
      type
      filePath
      location
      jsonLocation
      assets {
        set
        name
      }
      youtubeId
      videoSrc
      zoomIn
      closeLocation
      disablePlaylist
      autoreplay
      media {
        width
        framerate
        height
        ratio
      }
      frames
      items
      products {
        id
        title
        url
      }
      createdAt
      closeLocation
      videoCreator
      zoomIn
    }
  }
`;

const AssetsFromAssetQuery = gql`
  query assetsFromAsset($name: String!, $company: String!) {
    assetsFromAsset(name: $name, company: $company) {
      name
      title
      company
      description
      thumbnail
      status
      type
      filePath
      location
      jsonLocation
      autoreplay
      assets {
        set
        name
      }
      youtubeId
      videoSrc
      zoomIn
      closeLocation
      disablePlaylist
      frames
      items
      media {
        width
        framerate
        height
        ratio
      }
      products {
        id
        title
        url
      }
      createdAt
      videoCreator
      zoomIn
    }
  }
`;

const AssetConnectionMutation = gql`
  mutation assetConnection($connectionHash: String!, $company: String!, $name: String!, $webLocation: String!){
    assetConnection(connectionHash: $connectionHash, company: $company, name: $name, webLocation: $webLocation)  {
      connectionHash
      name
      company
    }
  }
`;

const ApproveAssetMutation = gql`
  mutation approveAsset($name: String!, $company: String!){
    approveAsset(name: $name, company: $company) {
      name
      title
      status
    }
  }
`;

const RejectAssetMutation = gql`
  mutation rejectAsset($name: String!, $company: String!, $reason: String){
    rejectAsset(name: $name, company: $company, reason: $reason) {
      name
      title
      status
    }
  }
`;

const CreateMiniClipMutation = gql`
  mutation createMiniclips($name: String!, $company: String!){
    createMiniclips(name: $name, company: $company) {
      name
    }
  }
`;

const RemoveMiniClipMutation = gql`
  mutation removeMiniClip($name: String!, $company: String!, $productId: String!){
    removeMiniClip(name: $name, company: $company, productId: $productId) {
      name
    }
  }
`;

const AssetMutation = gql`
  mutation asset($name: String, $title: String!, $company: String!, $frames: ObjectScalarType, $items: ObjectScalarType){
    asset(name: $name, title: $title, company: $company, frames: $frames, items: $items) {
      name
      title
      company
    }
  }
`;

const assetGqls = compose(
  graphql(AssetDetailQuery, {
    name: 'assetQuery',
    options: ({ match }) => ({
      variables: {
        company: isUserMaster() ? (match.params.company || '') : (localStorage.getItem('company') || ''),
        name: match.params.name
      },
      fetchPolicy: 'network-only'
    }),
    props: ({ assetQuery, ownProps }) => {
      const { asset, ..._assetQuery } = assetQuery;

      return {
        assetQuery: {
          data: {
            asset
          },
          ..._assetQuery
        },
        ...ownProps
      }
    }
  }),
  graphql(AssetConnectionMutation, {
    name: 'assetConnection'
  }),
  graphql(AssetsFromAssetQuery, {
    name: 'assetsFromAsset',
    options: ({ match }) => ({
      variables: {
        company: isUserMaster() ? (match.params.company || '') : (localStorage.getItem('company') || ''),
        name: match.params.name
      },
      fetchPolicy: 'network-only'
    }),
    props: (props) => {
      const { assetsFromAsset, ..._assetsFromAsset } = props.assetsFromAsset;

      return {
        assetsFromAsset: {
          data: {
            assetsFromAsset
          },
          ..._assetsFromAsset
        },
        ...props.ownProps
      }
    }
  }),
  graphql(ApproveAssetMutation, {
    name: 'approveAssetMutation'
  }),
  graphql(RejectAssetMutation, {
    name: 'rejectAssetMutation'
  }),
  graphql(CreateMiniClipMutation, {
    name: 'createMiniClip'
  }),
  graphql(RemoveMiniClipMutation, {
    name: 'removeMiniClip'
  }),
  graphql(AssetMutation, {
    name: 'assetMutation'
  }),
  // graphql(UploadJsonMutation, {
  //   name: 'uploadJson'
  // })
)

export default assetGqls(AssetDetailContainer);
