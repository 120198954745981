import './style.css';

import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { EnumAssetType } from '../../models/Asset';

export default class ModalCreateSlideshowPlaylist extends Component {
  render() {
    const { type, open, onClose } = this.props;

    const isSlideshow = type === EnumAssetType.SLIDESHOW;
    const isPlaylist = type === EnumAssetType.PLAYLIST;

    return (
      <Modal
        size='tiny'
          open={open}
          onClose={() => onClose()}
          closeIcon
          closeOnEscape={false}
          closeOnDimmerClick={false} >
        <Modal.Content>
          <div className='heading-3 cblack'>Create {isSlideshow ? 'Slideshow' : isPlaylist ? 'Playlist' : ''}</div>
          <div className='body cblack ModalCreateSlideshowPlaylist__Text'>Select more than one {isSlideshow ? 'IMAGE' : isPlaylist ? 'VIDEO' : ''} to create a {isSlideshow ? 'slideshow' : isPlaylist ? 'playlist' : ''}</div>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red'
                  onClick={() => onClose()} >
              <div className='body-bold'>Select</div>
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}