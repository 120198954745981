import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';

import CompanyService from '../../tools/company';
import CompanyLoadContainer from '../CompanyLoad';

export default class AuthedRoute extends Component {
  render() {
    const { ...rest } = this.props;
    const isLoggedIn = localStorage.getItem('token') && localStorage.getItem('company');

    if (!isLoggedIn) {
      return (
        <Redirect to={{pathname: '/login'}}/>
      );
    }

    const company = CompanyService.get();
    if (!company || !company.name) {
      return (
        <CompanyLoadContainer redirectTo={this.props.location ? this.props.location.pathname : '/'} />
      );
    }

    return (
      <Route  {...rest}
              company={company}/>
    );
  }
}