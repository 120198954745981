import './style.css';

import React, { Component, Fragment } from 'react';
import { Button, Form, Modal, Image } from 'semantic-ui-react';
import ImageMapper from '../ImageMapper';
import ProductSearchContainer from '../../containers/ProductSearch';
import { DeleteForever } from '@material-ui/icons';
import Icon from '@mdi/react';
import { mdiPencilOff, mdiPencil } from '@mdi/js';

export default class AssetImageEdit extends Component {
  state = {
    editIndex: null
  }

  handleEditProduct = (index) => {
    this.setState({
      editIndex: index
    });
  }

  handleCancelEditProduct = () => {
    this.setState({
      editIndex: null
    });
  }

  handleClickRemoveItemLink = (index) => {
    this.props.onClickRemoveItemLink(index);
    this.setState({
      editIndex: null
    });
  }

  handleChangeItemLink = (index, p) => {
    this.props.onChangeItemLink(index, p);
    this.setState({
      editIndex: null
    });
  }

  render() {
    const {
      open,
      asset,
      onClose,
      items,
      frames = [],
      onAddItem,
      onSave,
      isLoadingSave
    } = this.props;

    const { editIndex } = this.state;

    const productLinksEls = frames[0] ? frames[0].map((frame, index) => {
      const { productId } = frame;
      const product = items[productId];
      const image = product && product.images && product.images[0];

      const productEl = (
        <div  key={`${index}${product && product.productId}`}
              className='AssetUpload__Content__FormWrapper__Form__ProductLinks__ProductLink'>
          <div className='heading-4 cgreylight AssetUpload__Content__FormWrapper__Form__ProductLinks__ProductLink__Index'>
            {index + 1}
          </div>
          <div  className='AssetUpload__Content__FormWrapper__Form__ProductLinks__ProductLink__ProductBox'>
            {product && product.title ? (
              <Fragment>
                <div className='AssetUpload__Content__FormWrapper__Form__ProductLinks__ProductLink__ProductBox__ImageWrapper'>
                  <Image  src={image}
                          className='AssetUpload__Content__FormWrapper__Form__ProductLinks__ProductLink__ProductBox__ImageWrapper__Image' />
                </div>
                <div className='AssetUpload__Content__FormWrapper__Form__ProductLinks__ProductLink__ProductBox__Title caption'>{product.title}</div>
              </Fragment>
            ) : (
              <div  className='AssetUpload__Content__FormWrapper__Form__ProductLinks__ProductLink__ProductBox__EmptyText body'
                    onClick={() => this.handleEditProduct(index)}>CHOOSE PRODUCT</div>
            )}
            <div className='AssetUpload__Content__FormWrapper__Form__ProductLinks__ProductLink__ButtonWrapper'>
              { editIndex == undefined ? (
                <Button onClick={() => this.handleEditProduct(index)} >
                  <Icon path={mdiPencil}
                        size={1} />
                </Button>
              ) : (
                <Button onClick={() => this.handleCancelEditProduct(index)} >
                  <Icon path={mdiPencilOff}
                        size={1} />
                </Button>
              ) }
              <Button onClick={() => this.handleClickRemoveItemLink(index)}
                      className='AssetUpload__Content__FormWrapper__Form__ProductLinks__ProductLink__ButtonWrapper__Delete' >
                <DeleteForever fontSize='small' />
              </Button>
            </div>
          </div>
        </div>
      );

      return (
        <Fragment key={index}>
          { editIndex == undefined || editIndex === index ? productEl : null }
          { editIndex === index ? (
            <div  className='AssetUpload__Content__FormWrapper__Form__ProductLinks__ProductLink'>
              <ProductSearchContainer
                name={`productlink-${index + 1}`}
                product={product}
                onChange={(p) => this.handleChangeItemLink(index, p)}
                hideActiveProduct={true} />
            </div>
          ) : null }
        </Fragment>
      );
    }) : null;
    const allProductsHaveProductLinks = frames[0] ? !frames[0].find(({ productId }) => !productId) : false;

    return (
      <Modal  size='large'
              open={open}
              closeIcon
              className='AssetImageEdit'
              onClose={() => onClose()}
              closeOnEscape={false}
              closeOnDimmerClick={false} >
        <Modal.Content>
          { asset ? (
            <div className='AssetImageEdit__Content'>
              <div className='AssetImageEdit__Content__Left'>
                <div className='AssetImageEdit__Content__Left__Title'>
                  <div className='heading-3 cblack'>Click on the products in the image</div>
                </div>
                <div className='AssetImageEdit__Content__AssetImageWrapper'>
                  <ImageMapper
                    items={frames[0]}
                    onAddItem={onAddItem}
                    src={asset.thumbnail || asset.filePath || asset.location}
                    className='AssetImageEdit__Content__AssetImageWrapper__Image'
                  />
                </div>
              </div>
              <div className='AssetImageEdit__Content__Right'>
                { frames[0] && frames[0].length ? (
                  <Fragment>
                    <div className='AssetImageEdit__Content__Right__Title'>
                      <div className='heading-3 cblack'>Product list</div>
                    </div>
                    <div className='AssetImageEdit__Content__FormWrapper'>
                      <Form className='AssetImageEdit__Content__FormWrapper__Form'>
                        <div className='AssetUpload__Content__FormWrapper__Form__ProductLinks'>
                          { productLinksEls }
                        </div>
                      </Form>
                    </div>
                  </Fragment>
                ) : null }
              </div>
            </div>
          ) : null }
        </Modal.Content>
        <Modal.Actions>
          <Button color='red'
                  onClick={onSave}
                  disabled={!allProductsHaveProductLinks}
                  loading={isLoadingSave} >
            <div className='body-bold'>Save</div>
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}