import _get from 'lodash/get';
import React, { Component } from 'react';
import { mdiAnimationOutline, mdiFormatListCheckbox, mdiAccountDetails, mdiDna } from '@mdi/js'
import Icon from '@mdi/react'

import AppMenu from '../../components/AppMenu';
import CompanyService from '../../tools/company';

export default class AppMenuContainer extends Component {
  adsIcon = 'file video outline';
  themeIcon = 'paint brush';
  aiIcon = 'th';
  shopifyIcon = 'shopping bag';

  state = {
    modules: [
      {
        icon: () => (
          <Icon path={mdiAnimationOutline}
                size={1.5}
                className={'cgrey2'} />
        ),
        title: 'Verticals',
        link: '/verticals',
        show: true
      },
      {
        icon: () => (
          <Icon path={mdiAnimationOutline}
                size={1.5}
                className={'cgrey2'} />
        ),
        title: 'Assets',
        link: '/assets',
        show: true
      },
      {
        icon: (active) => (
          <Icon path={mdiFormatListCheckbox}
                size={1.5}
                className={'cgrey2'} />
        ),
        title: 'Products',
        link: '/products',
        show: true
      },
      {
        icon: (active) => (
          <Icon path={mdiAccountDetails}
                size={1.5}
                className={'cgrey2'} />
        ),
        title: 'Quotes',
        link: '/quotes',
        show: false
      },
      {
        icon: (active) => (
          <Icon path={mdiDna}
                size={1.5}
                className={'cgrey2'} />
        ),
        title: 'AI Dashboard',
        link: 'https://ai.acuity.video/',
        master: true,
        show: true
      }
    ],
    openIntegration: false,
    scriptIsCopied: false
  }

  onClickModule = (module) => {
    return () => {
      if (module.link && window.location.pathname !== module.link) {
        this.props.onRedirect(module.link);
      }
    }
  }

  closeGetCode = () => {
    this.setState({
      openIntegration: false
    });
  }

  handleLogout = () => {
    localStorage.clear();
    CompanyService.clear();
    this.props.onRedirect('/login');
  }

  handleClickIntegration = () => {
    this.setState({
      openIntegration: true
    });
  }

  handleClickSettings = () => {
    this.props.onRedirect('/settings');
  }

  shouldShowQuouteModule = (company) => {
    if (_get(company, 'store.type') === 'quote') {
      const quouteModule = this.state.modules.find(i => i.title === 'Quotes');

      if (quouteModule) {
        quouteModule.show = true;

        this.setState({
          modules: this.state.modules
        });
      }
    }
  }

  componentDidMount = () => {
    this.shouldShowQuouteModule(CompanyService.get());
  }

  handleRedirectToHome = () => this.props.onRedirect('/');

  render() {
    return (
      <AppMenu  modules={this.state.modules}
                activeModuleTitle={this.props.activeModuleTitle}
                onClickModule={this.onClickModule}
                onClickLogout={this.handleLogout}
                onClickIntegration={this.handleClickIntegration}
                onClickCloseGetCode={this.closeGetCode}
                onClickSettings={this.handleClickSettings}
                scriptIsCopied={this.state.scriptIsCopied}
                openIntegration={this.state.openIntegration}
                onClickRedirectToHome={this.handleRedirectToHome}/>
    );
  }
}
