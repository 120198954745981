import './style.css';

import _get from 'lodash/get';
import gql from 'graphql-tag';
import { debounce } from 'lodash';
import React, { Component, Fragment } from 'react';
import { Image, Dropdown, Input, Dimmer, Loader, Radio } from 'semantic-ui-react';
import { NotInterested } from '@material-ui/icons';
import client from '../../tools/graphql';
import ListSortableHeader from '../../components/List/sortable-header';

const ProductQuery = gql`
  query products($company: String!, $query: String){
    products(company: $company, query: $query) {
      id
      title
      productId
      description
      images
      price
      sku
    }
  }
`;

class ProductSearchContainer extends Component {
  state = {
    loading: false,
    touched: false,
    products: [],
    textSearch: '',
    orderBy: {
      fieldName: 'title',
      order: 'asc'
    },
  }

  handleChangeTextSearch = (event, { value }) => {
    this.setState({
      textSearch: value
    });

    this.searchProduct(value);
  }

  searchProduct = debounce((value) => this.doSearchProduct(value), 500);

  doSearchProduct = (value) => {
    this.setState({ loading: true, touched: true });

    return this.doProductQuery(value)
      .then((resp) => {
        this.setState({
          loading: false,
          products: resp.data.products || []
        });

        return resp;
      }).catch((e) => {
        this.setState({ loading: false });
      });
  }

  doProductQuery = (query) => client.query({
    query: ProductQuery,
    variables: {
      company: localStorage.getItem('company'),
      query
    }
  })

  handleChange = (p) => {
    this.setState({
      textSearch: ''
    });

    this.props.onChange(p);
  }

  componentWillMount() {
    this.doSearchProduct('')
      .then((resp) => {
        if (resp.data.products && resp.data.products.length > 5) {
          this.setState({
            showSearchInput: true
          });
        } else {
          this.setState({
            showSearchInput: false
          });
        }
      });
  }

  // mountProductItem = (p, isActive) => {
  //   const image = p.images && p.images[0];
  //   return (
  //     <Fragment key={p.productId}>
  //       <Dropdown.Item onClick={() => this.handleChange(p)}
  //                      className={`ProductTriggerMenu__ItemWrapper
  //                      ${isActive ? 'ProductTriggerMenu__ItemWrapper--active' : ''}`}>
  //         <div className='ProductTriggerMenu__Item'>
  //           { image ?
  //             <Image  src={image}
  //                     className='ProductTriggerMenu__Item__Image' />
  //           : null }
  //           <div className='body cgrey'>{p.title}</div>
  //         </div>
  //       </Dropdown.Item>
  //       <Dropdown.Divider />
  //     </Fragment>
  //   );
  // }

  mountProductItem = (p, isActive) => {
    const { hideActiveProduct } = this.props;
    const image = p.images && p.images[0];

    if (hideActiveProduct && isActive) return null;

    return (
      <div  key={p.productId}
            className={`ProductSearch__ProductsWrapper__List__Item
                        ${isActive ? 'ProductSearch__ProductsWrapper__List__Item--active' : ''}`}
            onClick={() => this.handleChange(p)}>
        <Radio checked={isActive ? true : false} />
        <div className='ProductSearch__ProductsWrapper__List__Item__ImageWrapper'>
          <Image  src={image}
                  className='ProductSearch__ProductsWrapper__List__Item__ImageWrapper__Image' />
        </div>
        <div className='ProductSearch__ProductsWrapper__List__Item__Title caption'>{p.title}</div>
      </div>
    );
  }

  mountLoading = (textSearch) => (
    <div className='ProductSearch__ProductsWrapper__List__Loading'>
      <div className='ProductSearch__ProductsWrapper__List__Loading__SpinnerWrapper'>
        <Dimmer active
                inverted >
          <Loader />
        </Dimmer>
      </div>
      <div className='body cgrey'>Loading products { textSearch ? `for your search "${textSearch}"` : '' }</div>
    </div>
  )

  // mountNotFound = (textSearch) => (
  //   <Fragment>
  //     <Dropdown.Item>
  //       <div className='ProductTriggerMenu__NotFound'>
  //         <div className='ProductTriggerMenu__NotFound__IconWrapper'>
  //           <NotInterested />
  //         </div>
  //         <div className='body cgrey'>Products not found for "{textSearch}"</div>
  //       </div>
  //     </Dropdown.Item>
  //     <Dropdown.Divider />
  //   </Fragment>
  // )

  mountNotFound = (textSearch) => (
    <div className='ProductSearch__ProductsWrapper__List__NotFound'>
      <div className='ProductSearch__ProductsWrapper__List__NotFound__IconWrapper'>
        <NotInterested />
      </div>
      <div className='body cgrey'>Products not found for "{textSearch}"</div>
    </div>
  )

  handleOrderBy = (fieldName) => {
    let orderBy = Object.assign({}, this.state.orderBy);
    if (this.state.orderBy.fieldName !== fieldName) {
      orderBy = {
        fieldName,
        order: 'asc'
      };
    } else {
      orderBy.order = orderBy.order === 'asc' ? 'desc' : 'asc';
    }

    this.state.products
      .sort((a, b) => {
        if (a[orderBy.fieldName] > b[orderBy.fieldName]) {
          return orderBy.order === 'asc' ? 1 : -1;
        } else if (_get(a, fieldName) < _get(b, fieldName)) {
          return orderBy.order === 'asc' ? -1 : 1;
        }

        return 0;
      });

    this.setState({ orderBy });
  }

  render () {
    const { product, className } = this.props;
    const { loading, touched, products, textSearch, showSearchInput } = this.state;

    const isProductEditSelected = product && product.id === product.productId;

    const isActive = ({ activeProduct, product }) => {
      return _get(activeProduct, 'id', null) === product.productId || _get(activeProduct, 'productId', null) === product.productId;
    }

    return (
      <div className='ProductSearch'>
        <Input  icon='search'
                iconPosition='left'
                placeholder='Search products'
                onChange={this.handleChangeTextSearch}
                value={textSearch} />
        <div className="ProductSearch__ProductsWrapper">
          <div className="ProductSearch__ProductsWrapper__Header overline-bold">
            <ListSortableHeader text='PRODUCT'
                                fieldName='title'
                                orderBy={this.handleOrderBy}
                                onChangeOrder={this.handleOrderBy}/>
          </div>
          <div className="ProductSearch__ProductsWrapper__List">
            { isProductEditSelected ? this.mountProductItem(product, true) : null }
            { loading ? this.mountLoading(textSearch) : null }
            { !loading && products ? products
                .filter(p => isProductEditSelected ? !isActive({ activeProduct: product, product: p }) : true)
                .map(p => this.mountProductItem(p, isActive({ activeProduct: product, product: p }))) : null }
            { !loading && textSearch && !products.length && touched ? this.mountNotFound(textSearch) : null }
          </div>
        </div>
      </div>
    )

    // return (
    //   <Dropdown className={className ? className : ' ProductSearchDropdown'}
    //             floating
    //             pointing='top left'
    //             direction='left'
    //             upward={true}
    //             trigger={
    //               <div className='body cgrey ProductTriggerMenu'>
    //                 { product ? product.title : 'Search for a product' }
    //               </div>
    //             }
    //             button >
    //     <Dropdown.Menu>
    //       <Dropdown.Menu scrolling>
    //         { loading && textSearch ? this.mountLoading(textSearch) : null }
    //         { !loading && textSearch && products.length ? products.map(p => this.mountProductItem(p, isActive({ activeProduct: product, product: p }))) : null }
    //         { !loading && !textSearch && products ? products.map(p => this.mountProductItem(p, isActive({ activeProduct: product, product: p }))) : null }
    //         { !loading && textSearch && !products.length && touched ? this.mountNotFound(textSearch) : null }
    //       </Dropdown.Menu>
    //       { showSearchInput ? (
    //         <Input icon='search'
    //               iconPosition='left'
    //               onChange={this.handleChangeTextSearch}
    //               value={textSearch}
    //               onClick={e => e.stopPropagation()} />
    //       ) : null }
    //     </Dropdown.Menu>
    //   </Dropdown>
    // )
  }
}

export default ProductSearchContainer;