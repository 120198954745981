import * as d3 from 'd3';
import _get from 'lodash/get';
import React, { Component } from 'react';
import CompanyService from '../../../tools/company';



export default class Funnel extends Component {
	state = {
		funnel: null
	}
	componentDidUpdate(prevProps) {
	  if (this.props.data !== prevProps.data) {
	  	const svg = this.state.funnel;
	  	while (svg.hasChildNodes()) {
		    svg.removeChild(svg.lastChild);
		}
    this.renderFunnel();
	  }
	}

	componentDidMount() {
		this.renderFunnel();
	}

	renderFunnel() {
		const svg = d3.select(this.funnel);
		let funnelData: any[] = this.props.data;
	  if (_get(CompanyService.get(), 'store.type') !== 'quote'){
			funnelData = funnelData.filter(({label})=> label !== "Quote Submitted")
		}
		const margin = {top: 20, right: 20, bottom: 60, left: 40};
   	const width = 1000 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;
		const cred = d3.rgb("#B7072E");


    const g = svg.append('g')
		.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

		const x = d3.scaleBand()
			.domain(funnelData.map(d => d.label))
			.rangeRound([0, width])
			.padding(0.1);

		const y = d3.scaleLinear()
			.domain([0, Math.ceil(funnelData[0].value/10)*10])
			.rangeRound([height, 0]);

		const make_x_gridlines = () => d3.axisBottom(x).ticks(5);
		const make_y_gridlines = () => d3.axisLeft(y).ticks(4);

		g.append("g")
	    .attr("class", "grid")
	    .attr("transform", "translate(0," + height + ")")
	    .call(make_x_gridlines()
	        .tickSize(-height)
	        .tickFormat("")
	  )

	  g.append("g")
	    .attr("class", "grid")
	    .call(make_y_gridlines()
	        .tickSize(-width)
	        .tickFormat("")
	  )

    g.append('g')
		.attr('transform', 'translate(0,' + height + ')')
		.call(d3.axisBottom(x));

		g.append('g')
			.call(d3.axisLeft(y))
			.append('text')
			.attr('fill', '#000')
			.attr('transform', 'rotate(-90)')
			.attr('y', -40)
			.attr('dy', "0.71em")
			.attr("text-anchor", "end")
			.text("Total value");

		g.selectAll(".bar")
			.data(funnelData)
			.enter().append("rect")
			.attr("class", "barRect")
			.attr("x", (d) => {
				return x(d.label) || null;
			})
			.attr("y", (d) => {
				return y(d.value);
			})
			.attr("width", x.bandwidth())
			.attr("height", (d) => {
				return height - y(d.value);
			})
			.attr("fill", cred);

		g.selectAll(".bar")
			.data(funnelData)
			.enter().append("text")
			.attr("class", "value")
			.attr("text-anchor", "middle")
			.attr("x", 	(d) => {
				const a = x(d.label) || null;
				if (a) {
					return ( a*2+x.bandwidth() )/2;
				}
				else {
					return null;
				}
			})
			.attr("y", (d) => {
				return y( d.value ) - 5;
			})
			.text((d) => {
				return d.value;
			});
	}

	render() {
		return (
			<svg
				ref={(ref: SVGSVGElement) => this.funnel = ref}
				width={ 1000 }
				height={ 400 }
			/>
		)
	}
}
