import React, { Component } from 'react';
import AssetVideoEditLook from '../../components/AssetVideoEditLook';
import { generateFileUrl } from '../../models/Asset';

export default class AssetVideoEditLookContainer extends Component {
  state = {}

  componentDidMount() {
    const { asset, product, initialTimeProduct } = this.props;

    const fileUrl = asset.filePath ? asset.filePath : generateFileUrl({
      company: asset.company,
      name: asset.name,
      type: asset.type.value
    });


    this.setState({
      fileUrl,
      products: product
    });

    if (product) {
      this.calculateProductTimeFromProduct(product);

      const mainProduct = product.find(({ main }) => Boolean(main));
      if (mainProduct) {
        this.setState({
          mainLookImage: mainProduct.mainLookImage
        });
      }

    } else {
      this.setState({
        productTime: initialTimeProduct || 0
      });
    }
  }

  getSecondsFromFrame = (frame) => {
    const { media } = this.props.assetJSON;
    return frame / media.framerate;
  }

  calculateProductTimeFromProduct = (product) => {
    const productTime = this.getSecondsFromFrame(product[0].frame);

    this.setState({
      productTime
    });
  }

  handleChangeProduct = (product, index, wasMain) => {
    const products = this.state.products.map((p, i) => {
      if (i === index) {
        return {
          main: wasMain || this.state.products.length === 1,
          ...product
        }
      }

      return p;
    })
    
    this.setState({
      products,
      editIndex: null
    });
  }

  hasMainProduct = () => Boolean(this.state.products && this.state.products.find(({ main }) => Boolean(main)))

  handleClickSave = () => {
    const { productTime, products, mainLookImage } = this.state;
    this.props.onClose({
      products,
      time: productTime,
      mainLookImage
    });
  }

  handleClickSaveAndAddNext = () => {
    const { productTime, products, mainLookImage } = this.state;
    this.props.onClose({
      products,
      time: productTime,
      addOther: true,
      mainLookImage
    });
  }

  handleChangeProductTime = (productTime) => {
    this.setState({
      productTime
    })
  }

  handleClickAddProduct = () => {
    const { products = [] } = this.state;

    const newProducts = [ ...products, {} ];

    this.setState({
      products: newProducts,
      editIndex: newProducts.length - 1
    });
  }

  handleClickEditProduct = (index) => {
    this.setState({
      editIndex: index
    })
  }

  handleClickCancelEditProduct = () => {
    this.setState({
      editIndex: null
    })
  }

  handleClickRemoveProduct = (index) => {
    const products = this.state.products.filter((p, i) => i !== index);
    this.setState({
      editIndex: null,
      products
    });
  }

  handleClickSetAsMain = (index) => {
    const products = this.state.products.map((p, i) => ({ ...p, main: (i === index) }));

    this.setState({
      editIndex: null,
      products
    })
  }

  handleSortEnd = ({ items }) => {
    const reducedProducts = this.state.products.reduce((prev, cur, i) => {
      prev[cur.productId] = cur;

      return prev;
    }, {});

    this.setState({
      products: items.map((productId) => reducedProducts[productId])
    })
  }

  render() {
    const { asset, open, onClose, product, initialTimeProduct } = this.props;
    const { fileUrl, productTime, selectedProduct, products, editIndex } = this.state;

    return <AssetVideoEditLook  product={product}
                                open={open}
                                fileUrl={fileUrl}
                                onClose={onClose}
                                productTime={productTime}
                                onChangeProductTime={this.handleChangeProductTime}
                                onChangeProduct={this.handleChangeProduct}
                                selectedProduct={selectedProduct}
                                onClickSave={this.handleClickSave}
                                youtubeId={asset.youtubeId}
                                onClickSaveAndAddNext={this.handleClickSaveAndAddNext}
                                products={products}
                                onClickAddProduct={this.handleClickAddProduct}
                                editIndex={editIndex}
                                onClickEditProduct={this.handleClickEditProduct}
                                onClickCancelEditProduct={this.handleClickCancelEditProduct}
                                onClickRemoveProduct={this.handleClickRemoveProduct}
                                onClickSetAsMain={this.handleClickSetAsMain}
                                isSaveDisabled={products && products.length > 1 && !this.hasMainProduct()}
                                hideSaveAndAddOther={initialTimeProduct == undefined}
                                onSortEnd={this.handleSortEnd} />;
  }
}
