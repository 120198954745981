import React, { Component, Fragment } from 'react';
import { Card, Form, Button, Message, Checkbox } from 'semantic-ui-react';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import ShopifySetup from './components/ShopifySetup';
import OthersSetup from './components/OthersSetup';
import { SETTINGS_TABS } from '../../containers/Settings';
import './style.css';

import { mdiTrashCan } from '@mdi/js'
import Icon from '@mdi/react'
import ButtonColorPicker from '../ButtonColorPicker';

const SettingsTab = ({
  company,
  onChange,
  platforms,
  shouldShowSelectPlatform,
  getEcommercePlatfromSetup
}) => (
  <div className='Settings'>
    <Form>
      <h1 className="SettingsTitle heading-2">
        General infos
      </h1>
      <Form.Field
        className='SettingsInput'>
        <label>Company name</label>
        <Form.Input
          fluid
          icon='building outline'
          iconPosition='left'
          placeholder='Company name'
          name='name'
          value={company.name || ''}
        />
      </Form.Field>
      <Form.Field
        className='SettingsInput'>
        <label>Domain</label>
        <Form.Input
          fluid 
          icon='linkify'
          iconPosition='left'
          placeholder='E-commerce web address'
          name='domain'
          value={company.domain || ''}
          onChange={onChange}
        />
      </Form.Field>
      <h1 className="SettingsTitle heading-2">
        E-commerce
      </h1>
      {shouldShowSelectPlatform(company) ? (
        <Form.Field
          className='SettingsInput'>
          <label>Ecommerce platform</label>
          <Form.Select
            options={platforms}
            fluid
            placeholder='Select you e-commerce vendor'
            name='ecommercePlatform'
            value={company.ecommercePlatform || 'quote'}
            onChange={onChange}
          />
        </Form.Field>
      ) : (
        !company.domain ? (
          <div
            className='SettingsInput'>
            <Message
              content='Please fill your domain to select an e-commerce platform.'
            />
          </div>
        ) : null
      )}
      {getEcommercePlatfromSetup(company)}
      <h1 className="SettingsTitle heading-2">
        Integrations
      </h1>
      <Form.Field
        className='SettingsInput'>
        <label>Bit.ly token</label>
        <Form.Input
          fluid 
          icon='key'
          iconPosition='left'
          placeholder='You bit.ly token'
          name='bitlyApiToken'
          value={company.bitlyApiToken || ''}
          onChange={onChange}
        />
      </Form.Field>
    </Form>
  </div>
);

class ThemeTab extends Component {
  state = {};

  componentWillMount () {
    const { company } = this.props
    if (company.logoBlack) {
      this.setState({
        logoBlack: {
          previewUrl: company.logoBlack
        }
      });
    }
    if (company.logoWhite) {
      this.setState({
        logoWhite: {
          previewUrl: company.logoWhite
        }
      });
    }
    if (company.theme) {
      this.setState({
        theme: company.theme
      })
    }
  }

  onSelectFile = (e) => {
    const target = e.target;
    const reader = new FileReader();

    reader.onload = () => {
      this.setState({
        [target.name]: {
          file: target.files[0],
          previewUrl: reader.result
        }
      }, () => {
        this.props.onChange(e, { name: target.name, value: target.files[0] });
      });
    };
    reader.readAsDataURL(target.files[0]);
  }

  removeLogo = (logo) => {
    this.setState({
      [logo]: null
    }, () => {
      this.props.onChange(null, { name: logo, value: null });
    })
  }

  render() {
    const {
      logoBlack,
      logoWhite,
      theme
    } = this.state;

    const { onChangeTheme = () => {}, onChangeLoader = () => {}, isDarkLoader, isLightColor } = this.props;

    console.log(isLightColor);

    return (
      <div className='Settings'>
        <Form>
          <h1 className="SettingsTitle heading-2">
            Logos
          </h1>
          <Form.Field
            className='SettingsInput'>
            <label>Logo for white background</label>
            <div
              className={'Settings__LogoPreview Settings__LogoPreview_Black'}
            >
              {logoBlack ? (
                <Fragment>
                  <div
                    className='Settings__LogoPreview__Update'>
                    <Button
                      onClick={() => this.logoBlackRef.click()}
                      content={'Update logo'}
                    />
                    <div
                      onClick={() => this.removeLogo('logoBlack')}
                      className='Settings__LogoPreview__RemoveBtn'>
                      <Icon
                        path={mdiTrashCan}
                        size={1}
                        horizontal
                        className='cgrey2'
                      />
                    </div>
                  </div>
                  <img className='Settings__LogoPreview__Image' alt={'Company logo in black background'} src={logoBlack.previewUrl} />
                </Fragment>
              ) : (
                <Button
                  onClick={(e) => this.logoBlackRef.click()}
                  content={'Select an image'}
                />
              )}
            </div>
            <span>This image should have 300px x 112px.</span>
            <input 
              type='file'
              name='logoBlack'
              style={{ display: 'none' }}
              onChange={this.onSelectFile}
              ref={(ref) => this.logoBlackRef = ref}
              accept={'image/x-png,image/gif,image/jpeg'}
            />
          </Form.Field>
          <Form.Field
            className='SettingsInput'>
            <label>Logo for black background</label>
            <div
              className={'Settings__LogoPreview Settings__LogoPreview_White'}
            >
              {logoWhite ? (
                <Fragment>
                  <div
                    className='Settings__LogoPreview__Update'>
                    <Button
                      onClick={() => this.logoWhiteRef.click()}
                      content={'Update logo'}
                    />
                    <div
                      onClick={() => this.removeLogo('logoWhite')}
                      className='Settings__LogoPreview__RemoveBtn'>
                      <Icon
                        path={mdiTrashCan}
                        size={1}
                        horizontal
                        className='cgrey2'
                      />
                    </div>
                  </div>
                  <img className='Settings__LogoPreview__Image' alt={'Company logo in white background'} src={logoWhite.previewUrl} />
                </Fragment>
              ) : (
                <Button
                  onClick={() => this.logoWhiteRef.click()}
                  content={'Select an image'}
                />
              )}
            </div>
            <span>This image should have 300px x 112px.</span>
            <input 
              type='file'
              name='logoWhite'
              style={{ display: 'none' }}
              onChange={this.onSelectFile}
              ref={(ref) => this.logoWhiteRef = ref}
              accept={'image/x-png'}
            />
          </Form.Field>
          <h1 className="SettingsTitle heading-2">
            Color
          </h1>
          <Form.Field
            className='SettingsColor'
          >
            <label>Base color</label>
          </Form.Field>
          <div className='SettingsColorPicker'>
            <ButtonColorPicker  color={theme['--main']}
                                onClosePicker={onChangeTheme}
                                disableAlpha={true}/>
            <div className={`SettingsColorPicker__CallToAction
                            ${isLightColor ? 'SettingsColorPicker__CallToAction--dark' : 'SettingsColorPicker__CallToAction--light'}`}>
              Click to change
            </div>
          </div>
          <h1 className="SettingsTitle heading-2">
            Loader
          </h1>
          <Form.Field
            className='SettingsLoader'
          >
            <Checkbox
              radio
              label='Use Light Loader'
              name='checkboxRadioGroup'
              checked={!isDarkLoader}
              onChange={() => onChangeLoader('light')}
            />
            <Checkbox
              radio
              label='Use Dark Loader'
              name='checkboxRadioGroup'
              className='SettingsLoader__DarkLoaderCheckbox'
              checked={isDarkLoader}
              onChange={() => onChangeLoader('dark')}
            />
          </Form.Field>
        </Form>
      </div>
    );
  }
}

class Settings extends Component {
  getEcommercePlatfromSetup = (company) => {
    const {
      onClickConnectShopify,
      onClickCopyConnectShopify,
      onClickDisconnect,
      onClickSave,
      onChange
    } = this.props;

    switch (company.ecommercePlatform) {
      case 'shopifyweb':
        return (
          <ShopifySetup
            onClickDisconnect={onClickDisconnect}
            onClickConnectShopify={onClickConnectShopify}
            onClickCopyConnectShopify={onClickCopyConnectShopify}
            company={company}
          />
        )
      case 'database':
        return (
          <OthersSetup
            onChange={onChange}
            onClickSave={onClickSave}
            onClickDisconnect={onClickDisconnect}
            company={company}
          />
        )
      default:
        return null;
    }
  }

  shouldShowSelectPlatform = (company) => {
    if (!company.domain) return false;
    if (_get(company, 'store.config.storefrontAccessToken') && company.ecommercePlatform === 'shopifyweb') return false;
    if (company.webpage) return false;
    return true;
  }

  renderActiveTab () {
    const {
      platforms,
      onChange,
      company,
      activeTab,
      onChangeTheme,
      onChangeLoader,
      isDarkLoader,
      isLightColor
    } = this.props;

    switch (activeTab) {
      case SETTINGS_TABS.GENERAL:
        return (
          <SettingsTab
            company={company}
            onChange={onChange}
            platforms={platforms}
            shouldShowSelectPlatform={this.shouldShowSelectPlatform}
            getEcommercePlatfromSetup={this.getEcommercePlatfromSetup}
          />
        );
      case SETTINGS_TABS.THEME:
        return (
          <ThemeTab
            company={company}
            onChange={onChange}
            onChangeTheme={onChangeTheme}
            onChangeLoader={onChangeLoader}
            isDarkLoader={isDarkLoader}
            isLightColor={isLightColor}
          />
        )
      default:
        return null;
    }
  }

  render() {
    const {
      loading,
      saved,
      onClickSave,
      onClickSetActiveTab,
      activeTab
    } = this.props;

    return (
      <Fragment>
        <div className='SettingsPage'>
          <div className="SettingsPage__Header">
            <div className="cred heading-3">
              Settings
            </div>
            <div className='AssetVideoEditPage__Header__Actions'>
              {saved ? (
                <Button color='green'>
                    <div className='body-bold'>Saved!</div>
                </Button>
              ) : (
                <Button color='red'
                      onClick={onClickSave}
                      loading={loading}
                      disabled={loading}>
                  <div className='body-bold'>Save Changes</div>
              </Button>
              )}
            </div>
          </div>
          <Card
            className="SettingsCard">
            <Card.Content>
              <div className='AssetListWrapper__Tabs'>
                <div  className={`AssetListWrapper__Tabs__Tab heading-4
                                ${activeTab === SETTINGS_TABS.GENERAL ? 'AssetListWrapper__Tabs__Tab--active' : ''}`}
                      onClick={() => onClickSetActiveTab(SETTINGS_TABS.GENERAL)}>
                  Settings
                </div>
                <div className={`AssetListWrapper__Tabs__Tab heading-4
                                ${activeTab === SETTINGS_TABS.THEME ? 'AssetListWrapper__Tabs__Tab--active' : ''}`}
                                onClick={() => onClickSetActiveTab(SETTINGS_TABS.THEME)}>
                  Theme
                </div>
              </div>
              {this.renderActiveTab()}
            </Card.Content>
          </Card>
        </div>
      </Fragment>
    )
  }
}

export default Settings;
