import './style.css';

import React, { Component } from 'react';
import { Button, Checkbox, Header, Icon, Modal } from 'semantic-ui-react';

import ButtonColorPicker from '../ButtonColorPicker';

export default class CompanyPlayer extends Component {
  fileHeaderRef;
  fileLogoRef;
  webVideoElement;
  $iframeWebVideo;
  webLocation;

  constructor(props) {
    super(props);

    this.fileHeaderRef = React.createRef();
    this.fileLogoRef = React.createRef();
    this.webVideoElement = React.createRef();
  }

  componentDidMount() {
    function getWebLocation() {
      const pathname = window.location.pathname[window.location.pathname - 1] === '/'
        ? window.location.pathname.substring(0, window.location.pathname.length - 1)
        : window.location.pathname;
      return `${window.location.host}${pathname}`;
    }

    this.webLocation = getWebLocation();
  }

  componentWillReceiveProps(props) {
    if (!this.props.appendWebVideoShoppable && props.appendWebVideoShoppable) {
      this.makeWebVideoShoppable();
    }

    if (this.props.appendWebVideoShoppable && !props.appendWebVideoShoppable) {
      this.removeWebVideoShoppable();
    }
  }

  componentWillUnmount() {
    this.removeWebVideoShoppable();
  }

  makeWebVideoShoppable = () => {
    const checkCurrentSrc = () => {
      if (!this.webVideoElement.current || !this.webVideoElement.current.currentSrc || !window.acuityai) {
        console.log('try again bro');
        setTimeout(() => checkCurrentSrc(), 250);
        return;
      }

      window.acuityai.makeVideoShoppable({
        $element: this.webVideoElement.current,
        company: localStorage.getItem('company'),
        assetName: this.props.previewWebVideoName
      }).then((iframe) => {
        if (!iframe) {
          return;
        }

        if (this.props.hasSavedTestTheme) {
          iframe.src = iframe.src + '&theme=test';
        }
      });
    }

    checkCurrentSrc();
  }

  removeWebVideoShoppable = () => {
    if (this.webVideoElement.current) {
      window.acuityai.removeElementShoppable({ $element: this.webVideoElement.current });
    }
  }

  onChangeLogoFile = ({ target: { files } }) => {
    const { onChangeLogoFile } = this.props;
    if (!files) { return; }
    onChangeLogoFile(files[0]);
  }

  onChangeHeaderFile = ({ target: { files } }) => {
    const { onChangeHeaderFile } = this.props;
    if (!files) { return; }
    onChangeHeaderFile(files[0]);
  }

  render() {
    const {
      onSave,
      main,
      hasSavedTestTheme,
      onChangeTheme,
      previewAdUrl,
      previewWebVideoUrl,
      isLoadingSave,
      onClickAlertOk,
      isSuccessModalOpened,
      onClickUndoChanges,
      isDarkLoader,
      onCheckLightLoader,
      onCheckDarkLoader
    } = this.props;

    const hasVideoToPreview = Boolean(previewAdUrl || previewWebVideoUrl);

    const successAlert = (
      <Modal  size='tiny'
              closeOnEscape={true}
              closeOnDimmerClick={true}
              className='Alert'
              open={isSuccessModalOpened}>
        <Modal.Content>
          <p className='Alert__Title'>
            Success
          </p>
          <p className='Alert__Text'>
            We've updated your theme.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button color='blue'
                  inverted
                  onClick={onClickAlertOk}>
            <Icon name='check' />
            Got it
          </Button>
        </Modal.Actions>
      </Modal>
    );
  
    return (
      <div className='CompanyPlayerPage'>
        { successAlert }
        { hasVideoToPreview
          ? (<div className='Theme__Content'>
              <div className='Theme__Content__Actions'>
                <div className='Theme__Content__Actions__Loader'>
                  <Checkbox
                    radio
                    label='Use Light Loader'
                    name='checkboxRadioGroup'
                    checked={!isDarkLoader}
                    onChange={onCheckLightLoader}
                  />
                  <Checkbox
                    radio
                    label='Use Dark Loader'
                    name='checkboxRadioGroup'
                    checked={isDarkLoader}
                    onChange={onCheckDarkLoader}
                  />
                </div>
                { hasSavedTestTheme
                  ? <div className='Theme__Content__Actions__Buttons'>
                      <Button color='orange'
                              size='small'
                              content='Undo Changes'
                              onClick={onClickUndoChanges}
                              disabled={!hasSavedTestTheme}/>
                      <Button color='green'
                              size='small'
                              disabled={!hasSavedTestTheme}
                              content='Apply Changes'
                              onClick={onSave}
                              loading={isLoadingSave}/>
                    </div>
                  : null
                }
              </div>
              { <div className='Theme__Content__PlayerWrapper'>
                  { previewAdUrl
                    ? <div className='Theme__Content__PlayerWrapper__IframeWrapper'>
                        <div className='Theme__Content__PlayerWrapper__IframeWrapper__CustomizeLogoWrapper'>
                          <div className='CustomizeBox Theme__Content__PlayerWrapper__IframeWrapper__CustomizeLogoWrapper__LogoBox'>
                            <div className='cgrey Theme__Content__PlayerWrapper__IframeWrapper__CustomizeLogoWrapper__LogoBox__OverlayText'>
                              <input  type='file'
                                      name='headerFile'
                                      onChange={this.onChangeHeaderFile}
                                      className='Theme__Content__PlayerWrapper__IframeWrapper__CustomizeLogoWrapper__LogoBox__OverlayText__FileInput'
                                      ref={this.fileHeaderRef}/>
                              <Icon className='Theme__Content__PlayerWrapper__IframeWrapper__CustomizeLogoWrapper__LogoBox__OverlayText__UploadIcon'
                                    name='upload'
                                    size='large'/>
                              <span className='Theme__Content__PlayerWrapper__IframeWrapper__CustomizeLogoWrapper__LogoBox__OverlayText__UploadText'>Light logo here</span>
                            </div>
                            <Icon name='edit'
                                  color='blue'/>
                          </div>
                          <div className='CustomizeBox Theme__Content__PlayerWrapper__IframeWrapper__CustomizeLogoWrapper__BaseColorBox'>
                            <div className='cgrey Theme__Content__PlayerWrapper__IframeWrapper__CustomizeLogoWrapper__BaseColorBox__OverlayText'>
                              <span className='Theme__Content__PlayerWrapper__IframeWrapper__CustomizeLogoWrapper__BaseColorBox__OverlayText__Label'>Base Color</span>
                              <ButtonColorPicker  color={main}
                                                  onClosePicker={onChangeTheme}
                                                  disableAlpha={true}/>
                            </div>
                            <Icon name='edit'
                                  color='blue'/>
                          </div>
                        </div>
                        <iframe src={previewAdUrl}
                                frameBorder='0'
                                className='Theme__Content__PlayerWrapper__IframeWrapper__Iframe'/>
                      </div>
                    : null
                  }
                  { previewWebVideoUrl
                    ? <div className='Theme__Content__PlayerWrapper__VideoWrapper'>
                        <video  className='Theme__Content__PlayerWrapper__VideoWrapper__Video'
                                controls={true}
                                src={previewWebVideoUrl}
                                ref={this.webVideoElement}/>
                        <div className='CustomizeBox Theme__Content__PlayerWrapper__VideoWrapper__CustomizeLogoWrapper__LogoBox'>
                          <div className='cgrey Theme__Content__PlayerWrapper__VideoWrapper__CustomizeLogoWrapper__LogoBox__OverlayText'>
                            <input  type='file'
                                    name='file'
                                    onChange={this.onChangeLogoFile}
                                    className='Theme__Content__PlayerWrapper__VideoWrapper__CustomizeLogoWrapper__LogoBox__OverlayText__FileInput'
                                    ref={this.fileLogoRef}/>
                            <Icon className='Theme__Content__PlayerWrapper__VideoWrapper__CustomizeLogoWrapper__LogoBox__OverlayText__UploadIcon'
                                  name='upload'
                                  size='large'/>
                            <span className='Theme__Content__PlayerWrapper__VideoWrapper__CustomizeLogoWrapper__LogoBox__OverlayText__UploadText'>Dark logo here</span>
                          </div>
                          <Icon name='edit'
                                color='blue'/>
                        </div>
                      </div>
                    : null
                  }
                </div>
              }
            </div> )
          : <span className='Theme__Empty cgrey'>You need to have a shoppable asset to customize your theme.</span>
        }
      </div>
    );
  }
}